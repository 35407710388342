export function getLengthCategories() {
    return ["< 0.5 km", "0.5 - 1 km", "1 - 2 km", "2 - 4 km", "4 - 6 km", "6 - 8 km", "8 - 10 km", "> 10 km"];
}

export function getLengthCategory(length) {
    length = length / 1000;

    if (length < 0.5) {
        return "< 0.5 km";
    } else if (length >= 0.5 && length < 1) {
        return "0.5 - 1 km";
    } else if (length >= 1 && length < 2) {
        return "1 - 2 km";
    } else if (length >= 2 && length < 4) {
        return "2 - 4 km";
    } else if (length >= 4 && length < 6) {
        return "4 - 6 km";
    } else if (length >= 6 && length < 8) {
        return "6 - 8 km";
    } else if (length >= 8 && length < 10) {
        return "8 - 10 km";
    } else {
        return "> 10 km";
    }
}

export function getSpeedCategories() {
    return ["< 10 km/h", "10 - 12 km/h", "12 - 14 km/h", "14 - 16 km/h", "16 - 18 km/h", "18 - 20 km/h", "20 - 22 km/h", "> 22 km/h"];
}

export function getSpeedCategory(speed) {
    if (speed < 10) {
        return "< 10 km/h";
    } else if (speed >= 10 && speed < 12) {
        return "10 - 12 km/h";
    } else if (speed >= 12 && speed < 14) {
        return "12 - 14 km/h";
    } else if (speed >= 14 && speed < 16) {
        return "14 - 16 km/h";
    } else if (speed >= 16 && speed < 18) {
        return "16 - 18 km/h";
    } else if (speed >= 18 && speed < 20) {
        return "18 - 20 km/h";
    } else if (speed >= 20 && speed < 22) {
        return "20 - 22 km/h";
    } else {
        return "> 22 km/h";
    }
}

export function getTemperatureCategory(temperature) {
    if (temperature <= -10) {
        return "UnderMinusTen";
    } else if (temperature > -10 && temperature <= 0) {
        return "MinusTenToZero";
    } else if (temperature > 0 && temperature <= 10) {
        return "ZeroToTen";
    } else if (temperature > 10 && temperature <= 20) {
        return "TenTo20";
    } else if (temperature > 20 && temperature <= 30) {
        return "20To30";
    } else {
        return "Over30";
    }
}