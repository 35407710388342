import React, { useState, useEffect } from "react";
import { Offcanvas, Table, Button } from "react-bootstrap";

const TabellenComponent = ({
  showOffcanvas,
  toggleOffcanvas,
  entries,
  setEntries,
  setSelectedSegmentId,
  setSegmentName,
  setFuhrungsformValue,
  setOberflaecheValue,
  setTempolimitValue,
  setModalOpen,
  setSelectedSegments
}) => {
  const handleDeleteEntry = (segmentId) => {
    setEntries(entries.filter(entry => entry.segment_id !== segmentId));
  };

  const handleEditEntry = (entry) => {
    setSelectedSegmentId(entry.segment_id);
    setSegmentName(entry.name);
    setFuhrungsformValue(entry.fuhrungsform);
    setOberflaecheValue(entry.oberflaeche);
    setTempolimitValue(entry.tempolimit);
    setModalOpen(true);
  };

  useEffect(() => {
    if (showOffcanvas) {
      console.log("Offcanvas geöffnet:", entries);
    }
  }, [showOffcanvas, entries]);

  return (
    <Offcanvas
      show={showOffcanvas}
      onHide={toggleOffcanvas}
      placement="top"
      className="custom-offcanvas"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Einträge</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Segment ID</th>
              <th>Straße</th>
              <th>Führungsform</th>
              <th>Oberfläche</th>
              <th>Tempolimit</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{entry.segment_id}</td>
                <td>{entry.name}</td>
                <td>{entry.fuhrungsform}</td>
                <td>{entry.oberflaeche}</td>
                <td>{entry.tempolimit}</td>
                <td>
                  <Button
                    variant="secondary"
                    size="s"
                    onClick={() => handleEditEntry(entry)}
                    style={{ marginRight: '5px' }}
                  >
                    Editieren
                  </Button>
                  <Button
                    variant="danger"
                    size="s"
                    onClick={() => handleDeleteEntry(entry.segment_id)}
                  >
                    Löschen
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TabellenComponent;