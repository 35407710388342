import React, { useState, useEffect, useRef } from "react";
import { TileLayer, MapContainer, LayersControl, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../../../styles/App.css';
import "react-datepicker/dist/react-datepicker.css";
import RoadScoreLayer from "../map/layers/RoadScoreLayer";
import AccumulationLayer from "../map/layers/AccumulationLayer";
import InfrastructureLayer from "../map/layers/InfrastructureLayer";
import NodesLayer from "../map/layers/NodesLayer";
import RoadsLayer from "../map/layers/RoadsLayer";
import HeatmapsLayer from "../map/layers/HeatmapsLayer";
import BusLinesLayer from "../map/layers/BusLinesLayer";
import BicycleCountingLayer from "../map/layers/BicycleCountingLayer";
import CarCountingLayer from "../map/layers/CarCountingLayer";
import AccidentSeverityLayer from "../map/layers/AccidentSeverityLayer";
import AccidentPerpetratorLayer from "../map/layers/AccidentPerpetratorLayer";
import ConcernsLayer from "../map/layers/ConcernsLayer";
import DamageReportsLayer from "../map/layers/DamageReportsLayer";
import BusStopsLayer from "../map/layers/BusStopsLayer";
import AccordionGeneral from "../map/filter/AccordionGeneral";
import AccordionDamageAmount from "../map/filter/AccordionDamageAmount";
import AccordionPerpetrator from "../map/filter/AccordionPerpetrator";
import AccordionSurface from "../map/filter/AccordionSurface";
import AccordionAccidentType from "../map/filter/AccordionAccidentType";
import FilterAccumulation from "../map/filter/FilterAccumulation";
import FilterInfrastructure from "../map/filter/FilterInfrastructure";
import FilterRoadScore from "../map/filter/FilterRoadScore";
import FilterNodeState from "../map/filter/FilterNodeState";
import FilterRoadSegmentScore from "../map/filter/FilterRoadSegmentScore";
import FilterHeatmaps from "../map/filter/FilterHeatmaps";
import AccordionWeather from "../map/filter/AccordionWeather";
import FilterConcerns from "../map/filter/FilterConcerns";
import StepTimeSpan from "../api/modal/StepTimeSpan";
import dayjs from "dayjs";
import MapFilter from "../map/modals/MapFilter";
import MapLayer from "../map/modals/MapLayer";
import CityModal from "../map/modals/CityModal";
import LoadingView from "../map/LoadingView";
import ColorLegend from "../functions/ColorLegend";
import AddressSearchBar from "../../report/AddressSearchBar";
import ExportLayerData from "../functions/ExportLayerData";



const LocationStation = () => {
    const mapRef = useRef(null);
    const [center, setCenter] = useState([53.1435, 8.2146]);
    const [zoom, setZoom] = useState(15);
    const [mapKey, setMapKey] = useState(0);
    const [town, setTown] = useState("Oldenburg");
    const [mapView, setMapView] = useState('standard');

    const getInitialBounds = () => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                return map.getBounds();
            }
        }
    }

    let initialBounds = getInitialBounds();

    // Define mapBounds state variable
    const [mapBounds, setMapBounds] = useState(initialBounds);
    const updateMapBounds = (bounds) => {
        setMapBounds(bounds);
    }

    const MapEvents = () => {
        useMapEvents({
            dragend: () => {
                runMapEvent();
            },
            zoomend: () => {
                runMapEvent();
            },
            resize: () => {
                runMapEvent();
            }
        });
        return null;
    };

    const runMapEvent = () => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                const bounds = map.getBounds();
                updateMapBounds(bounds);

                const checked = roadScoreChecked || nodeChecked || roadSegmentsChecked || heatmapsChecked || busChecked
                    || busStopChecked || bikeChecked || carChecked || accidentChecked || perpetratorChecked
                    || concernsChecked || reportChecked || infrastructureChecked || accumulationChecked;

                if (checked) {
                    updateLoadingSpin(true);
                }
            }
        }
    }

    // Layer checked-------------------------------------------------------------

    const [concernsChecked, setConcernsChecked] = useState(false);
    const updateConcernsChecked = (checked) => {
        setConcernsChecked(checked);
    }

    const [accidentChecked, setAccidentChecked] = useState(false);
    const updateAccidentChecked = (checked) => {
        setAccidentChecked(checked);
    }

    const [perpetratorChecked, setPerpetratorChecked] = useState(false);
    const updatePerpetratorChecked = (checked) => {
        setPerpetratorChecked(checked);
    }

    const [busChecked, setBusChecked] = useState(false);
    const updateBusChecked = (checked) => {
        setBusChecked(checked);
    }

    const [busStopChecked, setBusStopChecked] = useState(false);
    const updateBusStopChecked = (checked) => {
        setBusStopChecked(checked);
    }

    const [reportChecked, setReportChecked] = useState(false);
    const updateReportChecked = (checked) => {
        setReportChecked(checked);
    }

    const [carChecked, setCarChecked] = useState(false);
    const updateCarChecked = (checked) => {
        setCarChecked(checked);
    }

    const [bikeChecked, setBikeChecked] = useState(false);
    const updateBikeChecked = (checked) => {
        setBikeChecked(checked);
    }

    const [roadSegmentsChecked, setRoadSegmentsChecked] = useState(false);
    const updateRoadSegmentsChecked = (checked) => {
        setRoadSegmentsChecked(checked);
    }

    const [roadScoreChecked, setRoadScoreChecked] = useState(false);
    const updateRoadScoreChecked = (checked) => {
        setRoadScoreChecked(checked);
    }

    const [accumulationChecked, setAccumulationChecked] = useState(false);
    const updateAccumulationChecked = (checked) => {
        setAccumulationChecked(checked);
    }

    const [infrastructureChecked, setInfrastructureChecked] = useState(false);
    const updateInfrastructureChecked = (checked) => {
        setInfrastructureChecked(checked);
    }

    const [heatmapsChecked, setHeatmapsChecked] = useState(false);
    const updateHeatmapsChecked = (checked) => {
        setHeatmapsChecked(checked);
    }

    const [nodeChecked, setNodeChecked] = useState(false);
    const updateNodeChecked = (checked) => {
        setNodeChecked(checked);
    }

    // Layer Daten States-------------------------------------------------------------

    const [roadSegmentState, setRoadSegmentState] = useState("AnomalieSeg");
    const updateRoadSegmentState = (newState) => {
        setRoadSegmentState(newState)
    };

    const [scoreState, setScoreState] = useState("Score");
    const updateScoreState = (newState) => {
        setScoreState(newState)
    };

    const [accumulationState, setAccumulationState] = useState("Unfallrate");
    const updateAccumulationState = (newState) => {
        setAccumulationState(newState)
    };

    const [infrastructureState, setInfrastructureState] = useState("Slope");
    const updateInfrastructureState = (newState) => {
        setInfrastructureState(newState)
    };

    const [nodeState, setNodeState] = useState("V1");
    const updateNodeState = (newState) => {
        setNodeState(newState)
    };

    const [roadState, setRoadState] = useState("V1Road");
    const updateRoadState = (newState) => {
        setRoadState(newState)
    };

    // Layer Daten -------------------------------------------------------------

    const [heatmapData, setHeatmapData] = useState([]);
    const updateHeatmapData = (newData) => {
        setHeatmapData(newData);
    }

    const [roadScoreData, setRoadScoreData] = useState([]);
    const updateRoadScoreData = (newData) => {
        setRoadScoreData(newData);
    }

    const [roadsData, setRoadsData] = useState([]);
    const updateRoadsData = (newData) => {
        setRoadsData(newData);
    }

    const [nodesData, setNodesData] = useState([]);
    const updateNodesData = (newData) => {
        setNodesData(newData);
    }

    const [busData, setBusData] = useState([]);
    const updateBusData = (newData) => {
        setBusData(newData);
    }

    const [groupedData, setGroupedData] = useState({});
    const updateGroupedData = (newData) => {
        setGroupedData(newData);
    }

    const [busStops, setBusStops] = useState({});
    const updateBusStops = (newData) => {
        setBusStops(newData);
    }

    const [groupedAccData, setGroupedAccData] = useState({});
    const updateGroupedAccData = (newData) => {
        setGroupedAccData(newData);
    }

    const [infrastructureData, setInfrastructureData] = useState({});
    const updateInfrastructureData = (newData) => {
        setInfrastructureData(newData);
    }

    const [accumulationData, setAccumulationData] = useState([]);
    const updateAccumulationData = (newData) => {
        setAccumulationData(newData);
    }

    const [groupedConcernsData, setGroupedConcernsData] = useState({});
    const updateGroupedConcernsData = (newData) => {
        setGroupedConcernsData(newData);
    }

    const [carStations, setCarStations] = useState({});
    const updateCarStations = (newData) => {
        setCarStations(newData);
    }

    const [reportedImg, setReportedImg] = useState({});
    const updateReportedImg = (newImg) => {
        setReportedImg(newImg);
    }

    const [filterCondition, setFilterCondition] = useState([]);
    const [filterConcerns, setFilterConcerns] = useState([]);
    const [concernActive, setConcernActive] = useState(["Alle"]);
    const [filterWeather, setFilterWeather] = useState([]);
    const [perpChoice, setPerpChoice] = useState([]);
    const [roadCond, setRoadCond] = useState([]);
    const [accType, setAccType] = useState([]);

    const [moneyDamageFrom, setMoneyDamageFrom] = useState(0);
    const updateMoneyDamageFrom = (newMoney) => {
        setMoneyDamageFrom(newMoney);
    }

    const [moneyDamageTo, setMoneyDamageTo] = useState(0);
    const updateMoneyDamageTo = (newMoney) => {
        setMoneyDamageTo(newMoney);
    }

    const [injured, setInjured] = useState(1);
    const updateInjured = (newInjured) => {
        setInjured(newInjured);
    }

    // Modals und Ladesoinner-------------------------------------------------------------

    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTownPicker, setShowTownPicker] = useState(false);
    const [showLayerChoice, setShowLayerChoice] = useState(false);
    const [loadingSpin, setLoadingSpin] = useState(false);

    const updateShowDatePicker = (show) => {
        setShowDatePicker(show);
    }

    const updateShowTownPicker = (show) => {
        setShowTownPicker(show);
    }

    const updateShowLayerChoice = (show) => {
        setShowLayerChoice(show);
    }

    const updateLoadingSpin = (show) => {
        setLoadingSpin(show);
    }

    // Zeitformat-------------------------------------------------------------

    const [startDate, setStartDate] = useState(dayjs("2021-01-01 00:00:00"));
    const [endDate, setEndDate] = useState(dayjs("2023-12-31 23:59:00"));

    const sD = startDate.format("YYYY-MM-DD HH:mm:ss");
    const eD = endDate.format("YYYY-MM-DD HH:mm:ss");

    const sDQuarter = startDate.format("HH:mm:ss");
    const eDQuarter = endDate.format("HH:mm:ss");

    const updateStartDate = (date) => {
        if (date) {
            setStartDate(date);
        }
    };

    const updateEndDate = (date) => {
        if (date) {
            setEndDate(date);
        }
    };

    // Stadtwechsel-------------------------------------------------------------

    const handleSwitchChange = (choice) => {
        if (choice === "Osnabrück") {
            // Wechsel zu Osnabrück
            setTown("Osnabrueck");
            setCenter([52.2767, 8.0436]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }
        else if (choice === "Oldenburg") {
            // Wechsel zu Oldenburg
            setTown("Oldenburg");
            setCenter([53.1435, 8.2146]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }
        else if (choice === "Köln") {
            // Wechsel zu Köln
            setTown("Köln");
            setCenter([50.935173, 6.953101]);
            setZoom(14);
            setMapKey(prevKey => prevKey + 1);
        }

    };

    // Layerwechsel-------------------------------------------------------------

    const overlayEventSwitch = (name) => {
        switch (name) {
            case "1: Straßenscore":
                updateRoadScoreChecked(!roadScoreChecked);
                break;
            case "2: Knotenwerte":
                updateNodeChecked(!nodeChecked);
                break;
            case "3: Straßeneinfärbungen":
                updateRoadSegmentsChecked(!roadSegmentsChecked);
                break;
            case "4: Heatmaps":
                updateHeatmapsChecked(!heatmapsChecked);
                break;
            case "5: Buslinien":
                updateBusChecked(!busChecked);
                break;
            case "6: Bushaltestellen":
                updateBusStopChecked(!busStopChecked);
                break;
            case "7: Rad Zählstellen":
                updateBikeChecked(!bikeChecked);
                break;
            case "8: Auto Zählstellen":
                updateCarChecked(!carChecked);
                break;
            case "9: Unfallschwere":
                updateAccidentChecked(!accidentChecked);
                break;
            case "10: Unfallverursacher":
                updatePerpetratorChecked(!perpetratorChecked);
                break;
            case "11: Meldungen":
                updateConcernsChecked(!concernsChecked);
                break;
            case "12: RDD(Schadensmeldungen)":
                updateReportChecked(!reportChecked);
                break;
            case "13: Radweg Eigenschaften":
                updateInfrastructureChecked(!infrastructureChecked);
                break;
            case "14: Kumulierte Segmentwerte":
                updateAccumulationChecked(!accumulationChecked);
                break;
            default:
                return;
        }
    }

    // Map Funktionen-------------------------------------------------------------
    useEffect(() => {
        if (mapRef !== null) {
            const map = mapRef.current;
            if (map) {
                map.invalidateSize();
            }
        }
    });

    //Kopfleiste minimieren mobil - Funktion
    function minimize(className) {
        let elements = document.querySelectorAll("." + className);

        elements.forEach(function (element) {
            // Element versteckt
            if (element.style.display === "none") {
                // Wechsel zu flex display
                element.style.display = "block";
            } else {
                // wenn sichtbar, verstecken
                element.style.display = "none";
            }
        });
    }

    // FilterFunktionen-------------------------------------------------------------

    const filterFunction = (value) => {
        if (filterCondition.includes(value)) {
            removeFilter(value);
        }

        if (!filterCondition.includes(value)) {
            setFilterCondition([...filterCondition, value]);
        }
    }

    const removeFilter = (value) => {
        if (filterCondition.includes(value)) {
            setFilterCondition(filterCondition.filter(item => item !== value));
        }

        switch (value) {
            case "oberflaeche":
                setRoadCond([]);
                break;
            case "verursacher":
                setPerpChoice([]);
                break;
            case "unfalltyp":
                setAccType([]);
                break;
            case "wetter":
                setFilterWeather([]);
                break;
            case "node":
                setNodeState("");
                break;
            case "concern":
                setConcernActive(["Alle"]);
                break;
            default:
                break;
        }
    }

    const filterFunctionExtra = (value, extra) => {
        // Überprüfen, welcher Wert übergeben wurde
        if (value === "verursacher") {
            // Spezielle Aktionen für "verursacher" Wert
            if (roadCond.length === 0) {
                // Wenn keine Straßenbedingungen ausgewählt sind, entferne "oberflaeche" aus den Filterbedingungen
                setFilterCondition(filterCondition.filter(item => item !== "oberflaeche"));
            }
            if (perpChoice.includes(extra)) {
                // Wenn der Zusatzwert bereits in der Verursacherauswahl enthalten ist, entferne ihn
                setPerpChoice(perpChoice.filter(item => item !== extra));
            } else {
                // Füge den Zusatzwert zur Verursacherauswahl hinzu
                setPerpChoice([...perpChoice, extra]);
            }
        }

        if (value === "oberflaeche") {
            if (perpChoice.length === 0) {
                setFilterCondition(filterCondition.filter(item => item !== "verursacher"));
                console.log("weg" + filterCondition)
            }
            if (roadCond.includes(extra)) {
                setRoadCond(roadCond.filter(item => item !== extra));
            } else {
                setRoadCond([...roadCond, extra]);
            }
        }

        if (value === "unfalltyp") {
            console.log(extra)
            if (accType.includes(extra)) {
                setAccType(accType.filter(item => item !== extra));
            } else {
                setAccType([...accType, extra]);
            }
        }

        if (value === "wetter") {
            if (filterWeather.includes(extra)) {
                setFilterWeather(filterWeather.filter(item => item !== extra));
            } else {
                setFilterWeather([...filterWeather, extra]);
            }
        }

        if (!filterCondition.includes(value)) {
            setFilterCondition([...filterCondition, value]);
        }
    };

    const filterSetConcerns = (values, keyword) => {
        let updatedConcernActive;
        if (concernActive.includes(keyword)) {
            updatedConcernActive = concernActive.filter((item) => item !== keyword);
        } else {
            updatedConcernActive = [...concernActive, keyword];
        }
        setConcernActive(updatedConcernActive);

        let updatedFilterConcerns = filterConcerns.slice(); // Create a copy of the array
        values.forEach((value) => {
            if (updatedFilterConcerns.includes(value)) {
                updatedFilterConcerns = updatedFilterConcerns.filter((item) => item !== value);
            } else {
                updatedFilterConcerns.push(value);
            }
        });
        setFilterConcerns(updatedFilterConcerns);
    };

    const [tab, setTab] = useState('1');
    const [tabLabel, setTabLabel] = useState('Zeitfilter');

    const updateTab = (newTab) => {
        setTab(newTab);
    };

    const updateTabLabel = (newTabLabel) => {
        setTabLabel(newTabLabel);
    };

    const timeDimension = bikeChecked ? "quarter" : "all";

    const tabs = [
        <div className="m-2">
            <StepTimeSpan timeDimension={timeDimension} start={startDate} end={endDate}
                updateStart={updateStartDate} updateEnd={updateEndDate} nextStep={() => { }} />
        </div>,
        <div className="m-4" onClick={() => setLoadingSpin(true)}>
            <FilterRoadScore scoreState={scoreState} updateScoreState={updateScoreState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterNodeState nodeState={nodeState} updateNodeState={updateNodeState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterRoadSegmentScore roadSegmentState={roadSegmentState} updateRoadSegmentState={updateRoadSegmentState}
                updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterHeatmaps roadState={roadState} updateRoadState={updateRoadState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <AccordionGeneral filterCondition={filterCondition} injured={injured} filterFunction={filterFunction} updateInjured={updateInjured} />
            <AccordionDamageAmount moneyDamageFrom={moneyDamageFrom} moneyDamageTo={moneyDamageTo} filterCondition={filterCondition}
                filterFunction={filterFunction} updateMoneyDamageFrom={updateMoneyDamageFrom} updateMoneyDamageTo={updateMoneyDamageTo} />
            <AccordionPerpetrator perpChoice={perpChoice} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionSurface roadCond={roadCond} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionAccidentType accType={accType} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
            <AccordionWeather filterWeather={filterWeather} removeFilter={removeFilter} filterFunctionExtra={filterFunctionExtra} />
        </div>,
        <div className="m-4">
            <FilterConcerns concernActive={concernActive} removeFilter={removeFilter} filterSetConcerns={filterSetConcerns} />
        </div>,
        <div className="m-4">
            <FilterInfrastructure infrastructureState={infrastructureState} updateInfrastructureState={updateInfrastructureState} updateLoadingSpin={updateLoadingSpin} />
        </div>,
        <div className="m-4">
            <FilterAccumulation accumulationState={accumulationState} updateAccumulationState={updateAccumulationState} updateLoadingSpin={updateLoadingSpin} />
        </div>
    ];

    //Adress Search
    const handleAddressSelected = (address) => {
        const { lat, lon } = address;
        console.log('Neue Adresse ausgewählt:', address);
        console.log('Center auf Lat:', lat, 'Lng:', lon, 'angepasst');
        setCenter(
            [lat, lon]
        );
        setMapKey((prevKey) => prevKey + 1); // Aktualisiere den key
    };

    const handleZoomToAddress = (coordinates, zoomLevel) => {
        setZoom(zoomLevel);
    };


    // Return-------------------------------------------------------------

    return (
        <div id="map"  className="map-container">
            <MapContainer ref={mapRef} style={{ height: "91%", width: "99%" }} key={mapKey} center={center} zoom={zoom}
                scrollWheelZoom={false}>
                {mapView === 'standard' && (
                    <TileLayer
                        attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors"
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                        subdomains={['a', 'b', 'c', 'd']}
                        crossOrigin="anonymous"
                    />
                )}
                {mapView === 'satellite' && (
                    <TileLayer
                        attribution="&copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                        crossOrigin="anonymous"
                    />
                )}

                <MapEvents />

                {/* Container für die Kopfreihe*/}
                {!loadingSpin && (
                    <div className="mapkopfleiste w-100 row exportExclude">
                        <AddressSearchBar className="w-100 adressMap" onAddressSelected={handleAddressSelected}
                                          onZoomToAddress={handleZoomToAddress}/>
                    </div>
                )}

                {(roadScoreChecked || nodeChecked || roadSegmentsChecked || heatmapsChecked || infrastructureChecked || accumulationChecked) && (
                    <ColorLegend roadScoreChecked={roadScoreChecked} nodeChecked={nodeChecked} roadSegmentsChecked={roadSegmentsChecked}
                                 heatmapsChecked={heatmapsChecked} infrastructureChecked={infrastructureChecked} accumulationChecked={accumulationChecked}
                                 scoreState={scoreState} nodeState={nodeState} roadSegmentState={roadSegmentState} roadState={roadState}
                                 infrastructureState={infrastructureState} accumulationState={accumulationState} accumulationData={accumulationData}/>
                )}


                <div className="maptext exportExclude">
                    <div className="otherIconWrapper">
                        <div className="otherIcon" data-tooltip="Mapsicht ändern" onClick={() => minimize("einblenden")}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
                                <path d="M384 476.1L192 421.2l0-385.3L384 90.8l0 385.3zm32-1.2l0-386.5L543.1 37.5c15.8-6.3 32.9 5.3 32.9 22.3l0 334.8c0 9.8-6 18.6-15.1 22.3L416 474.8zM15.1 95.1L160 37.2l0 386.5L32.9 474.5C17.1 480.8 0 469.2 0 452.2L0 117.4c0-9.8 6-18.6 15.1-22.3z" />
                            </svg>

                            {/* Kartenstil wechseln */}
                            <div className="sichtwechsel einblenden col">
                                <button
                                    className={mapView === 'standard' ? 'active' : ''}
                                    onClick={() => setMapView('standard')}
                                >
                                    Standardmap
                                </button>
                                <button
                                    className={mapView === 'satellite' ? 'active' : ''}
                                    onClick={() => setMapView('satellite')}
                                >
                                    Satellitenmap
                                </button>
                            </div>
                        </div>
                        <div onClick={() => minimize("zeitEinblenden")} className="otherIcon" data-tooltip="Zeitraum betrachten/ändern">

                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                <style><svg>fill:grey</svg></style>
                                <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                            </svg>

                            <div className="sichtwechselZeit zeitEinblenden col" onClick={() => setShowDatePicker(true)} >
                                <button><strong>{dayjs(startDate).format("DD.MM.YY")}</strong> - <strong>{dayjs(endDate).format("DD.MM.YY")}</strong></button>
                            </div>
                        </div>

                        <div className="otherIcon" data-tooltip="Daten filtern" onClick={() => setShowDatePicker(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
                                <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32l432 0c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9 320 448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6l0-79.1L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
                            </svg>
                        </div>
                        <div className="otherIcon" data-tooltip="Datenlayer auswählen" onClick={() => setShowLayerChoice(true)} >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512">
                                <path d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                            </svg>
                        </div>
                        <div className="otherIcon" data-tooltip="Stadt wechseln" onClick={() => setShowTownPicker(true)} >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 640 512">
                                <path d="M480 48c0-26.5-21.5-48-48-48L336 0c-26.5 0-48 21.5-48 48l0 48-64 0 0-72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 72-64 0 0-72c0-13.3-10.7-24-24-24S64 10.7 64 24l0 72L48 96C21.5 96 0 117.5 0 144l0 96L0 464c0 26.5 21.5 48 48 48l256 0 32 0 96 0 160 0c26.5 0 48-21.5 48-48l0-224c0-26.5-21.5-48-48-48l-112 0 0-144zm96 320l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16zM240 416l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16zM128 400c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32zM560 256c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0zM256 176l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16zM112 160c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0zM256 304c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32zM112 320l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16zm304-48l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16zM400 64c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0zm16 112l0 32c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16z" />
                            </svg>
                        </div>
                        <ExportLayerData roadScoreData={roadScoreData} nodesData={nodesData} roadsData={roadsData}
                                         heatmapData={heatmapData} busData={busData} busStops={busStops} groupedData={groupedData}
                                         carStations={carStations} groupedAccData={groupedAccData} groupedConcernsData={groupedConcernsData}
                                         reportedImg={reportedImg} infrastructureData={infrastructureData} accumulationData={accumulationData}
                                         loadingSpin={loadingSpin}/>
                    </div>
                </div>

                <LoadingView loadingSpin={loadingSpin} />
                <MapFilter tab={tab} tabs={tabs} tabContents={tabs} tabLabel={tabLabel} roadScoreChecked={roadScoreChecked}
                    nodeChecked={nodeChecked} roadSegmentsChecked={roadSegmentsChecked} heatmapsChecked={heatmapsChecked}
                    accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked} concernsChecked={concernsChecked} roadGeometryChecked={infrastructureChecked}
                    infrastructureChecked={accumulationChecked} showDatePicker={showDatePicker} updateShowDatePicker={updateShowDatePicker}
                    updateTab={updateTab} updateTabLabel={updateTabLabel} updateLoadingSpin={updateLoadingSpin} />

                <CityModal showTownPicker={showTownPicker} town={town} updateShowTownPicker={updateShowTownPicker}
                    handleSwitchChange={handleSwitchChange} />

                <MapLayer showLayerChoice={showLayerChoice} roadScoreChecked={roadScoreChecked} nodeChecked={nodeChecked}
                    roadSegmentsChecked={roadSegmentsChecked} heatmapsChecked={heatmapsChecked} busChecked={busChecked}
                    busStopChecked={busStopChecked} bikeChecked={bikeChecked} carChecked={carChecked}
                    accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked} concernsChecked={concernsChecked}
                    reportChecked={reportChecked} roadGeometryChecked={infrastructureChecked} infrastructureChecked={accumulationChecked} overlayEventSwitch={overlayEventSwitch}
                    updateShowLayerChoice={updateShowLayerChoice} updateLoadingSpin={updateLoadingSpin} />

                <LayersControl position="bottomright">

                    <RoadScoreLayer scoreState={scoreState} roadScoreChecked={roadScoreChecked}
                        roadScoreData={roadScoreData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        updateRoadScoreData={updateRoadScoreData} updateLoadingSpin={updateLoadingSpin} />

                    <NodesLayer nodeState={nodeState} nodeChecked={nodeChecked} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        nodesData={nodesData} updateNodesData={updateNodesData} updateLoadingSpin={updateLoadingSpin} />

                    <RoadsLayer roadSegmentState={roadSegmentState} roadSegmentsChecked={roadSegmentsChecked}
                        roadsData={roadsData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        updateRoadsData={updateRoadsData} updateLoadingSpin={updateLoadingSpin} />

                    <HeatmapsLayer roadState={roadState} heatmapsChecked={heatmapsChecked}
                        heatmapData={heatmapData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        updateHeatmapData={updateHeatmapData} updateLoadingSpin={updateLoadingSpin} />

                    <BusLinesLayer busChecked={busChecked} busData={busData} mapBounds={mapBounds}
                        loadingSpin={loadingSpin} updateBusData={updateBusData}
                        updateLoadingSpin={updateLoadingSpin} />

                    <BusStopsLayer busStopChecked={busStopChecked} busStops={busStops} mapBounds={mapBounds}
                        loadingSpin={loadingSpin} updateBusStops={updateBusStops}
                        updateLoadingSpin={updateLoadingSpin} />

                    <BicycleCountingLayer bikeChecked={bikeChecked} groupedData={groupedData} loadingSpin={loadingSpin}
                                          mapBounds={mapBounds} sDQuarter={sDQuarter} eDQuarter={eDQuarter} sD={sD} eD={eD}
                                          updateGroupedData={updateGroupedData} updateLoadingSpin={updateLoadingSpin} />

                    <CarCountingLayer carChecked={carChecked} carStations={carStations} sD={sD} eD={eD}
                        mapBounds={mapBounds} loadingSpin={loadingSpin} updateMapBounds={updateMapBounds}
                        updateCarStations={updateCarStations} updateLoadingSpin={updateLoadingSpin} />

                    <AccidentSeverityLayer accidentChecked={accidentChecked} perpetratorChecked={perpetratorChecked}
                        groupedAccData={groupedAccData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        filterCondition={filterCondition} moneyDamageFrom={moneyDamageFrom}
                        moneyDamageTo={moneyDamageTo} perpChoice={perpChoice} roadCond={roadCond} accType={accType}
                        filterWeather={filterWeather} sD={sD} eD={eD} sDQuarter={sDQuarter} eDQuarter={eDQuarter}
                        updateGroupedAccData={updateGroupedAccData} updateLoadingSpin={updateLoadingSpin} />

                    <AccidentPerpetratorLayer perpetratorChecked={perpetratorChecked} groupedAccData={groupedAccData}
                        loadingSpin={loadingSpin} filterCondition={filterCondition}
                        moneyDamageFrom={moneyDamageFrom} moneyDamageTo={moneyDamageTo}
                        perpChoice={perpChoice} roadCond={roadCond} accType={accType} />

                    <ConcernsLayer concernsChecked={concernsChecked} groupedConcernsData={groupedConcernsData}
                        concernActive={concernActive} filterConcerns={filterConcerns} sD={sD} eD={eD}
                        mapBounds={mapBounds} updateGroupedConcernsData={updateGroupedConcernsData}
                        updateLoadingSpin={updateLoadingSpin} />

                    <DamageReportsLayer reportChecked={reportChecked} reportedImg={reportedImg} startDate={startDate}
                        endDate={endDate} mapBounds={mapBounds} loadingSpin={loadingSpin}
                        updateReportedImg={updateReportedImg} updateLoadingSpin={updateLoadingSpin} />

                    <InfrastructureLayer infrastructureState={infrastructureState} infrastructureChecked={infrastructureChecked}
                                         infrastructureData={infrastructureData} mapBounds={mapBounds} loadingSpin={loadingSpin}
                                         updateInfrastructureData={updateInfrastructureData} updateLoadingSpin={updateLoadingSpin} />

                    <AccumulationLayer accumulationState={accumulationState} accumulationChecked={accumulationChecked}
                                       mapBounds={mapBounds} accumulationData={accumulationData}
                                       updateAccumulationData={updateAccumulationData} updateLoadingSpin={updateLoadingSpin}
                                       sD={sD} eD={eD} town={town} loadingSpin={loadingSpin} />

                </LayersControl>
            </MapContainer>
        </div>
    );
}

export default LocationStation;