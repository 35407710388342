export function getBusLineColors() {
    const busColors = {};

    //Oldenburger Linien
    busColors["280"] = "#0090c3";
    busColors["301"] = "#bbb6d4";
    busColors["302"] = "#ff2e17";
    busColors["304"] = "#fd93c5";
    busColors["306"] = "#ffd639";
    busColors["308"] = "#35ccf6";
    busColors["309"] = "#fb3e9f";
    busColors["310"] = "#c52b1e";
    busColors["311"] = "#7a441c";
    busColors["313"] = "#38647e";
    busColors["314"] = "#b0d243";
    busColors["315"] = "#0060a0";
    busColors["318"] = "#4b9445";
    busColors["320"] = "#7c7f32";
    busColors["321"] = "#ffbc87";
    busColors["322"] = "#00b2b4";
    busColors["323"] = "#47d0f6";
    busColors["324"] = "#ff9027";
    busColors["325"] = "#797ca2";
    busColors["329"] = "#42155b";
    busColors["330"] = "#44b94a";
    busColors["340"] = "#6e4391";
    busColors["350"] = "#116081";

    //Osnabrücker Linien
    busColors["10"] = "#945ea4";
    busColors["12"] = "#2e2382";
    busColors["13"] = "#009ee3";
    busColors["14"] = "#ec6500";
    busColors["15"] = "#f7a500";
    busColors["16"] = "#007a3b";
    busColors["17"] = "#00a75c";
    busColors["17 (zeitweise Bedienung)"] = "#00a75c";
    busColors["18"] = "#1082c7";
    busColors["19"] = "#004e9e";
    busColors["20"] = "#945ea4";
    busColors["151"] = "#f7a500";
    busColors["152"] = "#f7a500";
    busColors["160"] = "#9c9c9d";
    busColors["381"] = "#009fe2";
    busColors["392"] = "#004e9e";
    busColors["454"] = "#d51518";
    busColors["462"] = "#9c9b9a";
    busColors["491"] = "#9c9c9d";
    busColors["533"] = "#312783";
    busColors["581"] = "#009fe2";
    busColors["584"] = "#d61418";
    busColors["M1"] = "#01924e";
    busColors["M2"] = "#2e2382";
    busColors["M3"] = "#94c11a";
    busColors["M4"] = "#d40a11";
    busColors["M5"] = "#e24f97";
    busColors["X52"] = "#9c9c9d";
    busColors["X273"] = "#d51518";
    busColors["X460"] = "#009fe2";
    busColors["X492"] = "#01924f";
    busColors["X610"] = "#004f9e";
    busColors["R15"] = "#f7a703";
    busColors["R16"] = "#007b3d";
    busColors["S10"] = "hsl(88, 50%, 41%)";
    busColors["Anrufbus 113"] = "hsl(28, 80%, 52%)";

    return busColors;
}

export function getColorClass(score) {

    if (score >= (-0.5) && score <= 0.0) {
        return "#437c17"; // Green (0% - 30%)
    } else if (score > 0.0 && score <= 0.3) {
        return "#a4c02c"; // Light Grey (31% - 50%)
    } else if (score > 0.3 && score <= 0.5) {
        return "#dce738";  // Yellow (51% - 70%)
    } else if (score > 0.5 && score <= 0.80) {
        return "#f8e53a";// Orange (71% - 85%)
    } else if (score > 0.80 && score < 0.92) {
        return "#eeac2e"; // Red Orange (86% - 91%)
    } else if (score > 0.92) {
        return "#db4918"; // Red (92% - 100%)
    }
    else { return "transparent" }
}

export function getLegendColor(score, maxValue) {
    const perc10 = (maxValue * 0.10).toFixed(2);
    const perc40 = (maxValue * 0.40).toFixed(2);
    const perc60 = (maxValue * 0.60).toFixed(2);
    const perc85 = (maxValue * 0.85).toFixed(2);
    const perc95 = (maxValue * 0.95).toFixed(2);

    if (score >= -1.00 && score < perc10) {
        return "#437c17"; // Dunkelgrün
    } else if (score >= perc10 && score < perc40) {
        return "#a4c02c"; // Hellgrün
    } else if (score >= perc40 && score < perc60) {
        return "#dce738"; // Gelbgrün
    } else if (score >= perc60 && score < perc85) {
        return "#f8e53a"; // Gelb
    } else if (score >= perc85 && score < perc95) {
        return "#eeac2e"; // Orange
    } else if (score >= perc95) {
        return "#db4918"; // Rot
    } else {
        return "transparent"; // Transparent
    }
}

export function getColorClassAccidents(score) {

    if (score === 0) {
        return "#437c17"; // Green (0% - 30%)
    } else if (score > 0 && score <= 1) {
        return "#a4c02c"; // Light Grey (31% - 50%)
    } else if (score > 1 && score <= 3) {
        return "#dce738"; // Yellow (51% - 70%)
    } else if (score > 3 && score <= 5) {
        return "#f8e53a"; // Orange (71% - 85%)
    } else if (score > 5 && score < 15) {
        return "#eeac2e"; // Red Orange (86% - 91%)
    } else if (score > 15) {
        return "#db4918"; // Red (92% - 100%)
    }
    else { return "transparent" }
}


export function getColorClassSegmentAccidents(score) {

    if (score === 0.00 && score <= 0.001) {
        return "rgba(67, 124, 23, 0.5)"; // Transparent
    } else if (score > 0.001 && score <= 1.00) {
        return "#a4c02c"; // Yellow (51% - 70%)
    } else if (score > 1.00 && score <= 3.00) {
        return "#dce738"; // Orange (71% - 85%)
    } else if (score > 3.00 && score < 5.00) {
        return "#eeac2e"; // Red Orange (86% - 91%)
    } else if (score > 5.00) {
        return "#db4918"; // Red (92% - 100%)
    }
    else { return "transparent" }
}

export function getColorClassSegmentTraffic(score) {

    if (score === 0) {
        return "transparent"; // Transparent
    }
    else if (score > 0 && score <= 500) {
        return "#437c17";
    } else if (score > 500 && score <= 1000) {
        return "#a4c02c";
    } else if (score > 500 && score <= 2000) {
        return "#dce738";
    }
    else if (score > 2000 && score <= 4000) {
        return "#f8e53a";
    } else if (score > 4000 && score < 6000) {
        return "#eeac2e";
    } else if (score > 6000) {
        return "#db4918";
    }
    else { return "transparent" }
}


export function getColorClassSegmentSpeed(score) {

    if (score === 0) {
        return "transparent"; // Transparent
    }
    else if (score > 0 && score <= 30) {
        return "#437c17";
    } else if (score > 30 && score <= 33) {
        return "#a4c02c";
    } else if (score > 33 && score <= 36) {
        return "#dce738";
    }
    else if (score > 36 && score <= 39) {
        return "#f8e53a";
    } else if (score > 39 && score < 42) {
        return "#eeac2e";
    } else if (score > 42) {
        return "#db4918";
    }
    else { return "transparent" }
}

export function getColorClassElevation(score) {

    if (score >= 0.00 && score <= 0.30) {
        return "#437c17";
    } else if (score > 0.30 && score <= 2.00) {
        return "#a4c02c";
    } else if (score > 2.00 && score <= 4.00) {
        return "#dce738";
    } else if (score > 4.00 && score <= 6.00) {
        return "#f8e53a";
    } else if (score > 6.00 && score <= 8.00) {
        return "#eeac2e";
    } else if (score > 8.00) {
        return "#db4918";
    }
    else { return "transparent" }
}

export function getPathTypeColorClass(pathType, path) {
    if (pathType === null) {
        pathType = path
    }

    switch (pathType) {
        case "Busspur":
            return "#FFEE00";
        case "Fahrradstraße": case "Führung in Fahrradstraße":
            return "#0000FF";
        case "Geh-/Radweg": case "Geh-/Radweg gemeinsam": case "Geh-/Radweg getrennt": case "Gehweg (Rad frei)":
            return "#EEAA00";
        case "Radfahrspur": case "Radfahrstreifen": case "Schutzstreifen":
            return "#9933FF";
        case "Radweg":
            return "#FF0000";
        case "Führung in T30-Zone": case "Führung in Verkehrsberuhigter Bereich":
            case "Radverkehrsführung im Mischverkehr + Geh/Radweg getrennt": case "Radverkehrsführung im Mischverkehr":
                case "Fahrrad auf Fahrbahn (30 bis 100km/h)": case "Einbahnstraße (für Rad frei)":
            return "#008B00";
        case "Forstwirtschaftlicher Weg": case "Landwirtschaftlicher Weg (straßenbegleitend)":
            case "Landwirtschaftlicher Weg (selbstständig)": case "Anwohner frei":
                case "Weg mit Zusatzzeichen \"Anlieger frei\"":
            return "#FF00FF";
        case "Radfahren verboten": case "Einbahnstraße (für Rad nicht frei)":
            return "#202020";
        default:
            return "#D3D3D3";
    }
}

export function getSurfaceTypeColorClass(surfaceType) {

    switch (surfaceType) {
        case "Spurbahn":
            return "#FFEE00";
        case "Wassergebundene Decke": case "wassergebundener Belag mit Unterbau (ganzjährig zu":
            case "wassergebundener Belag mit Grasnarbe mittig": case "wassergebundener Belag ohne Unterbau (Erdgrasweg)":
            return "#0000FF";
        case "Natursteinpflaster":
            return "#EEAA00";
        case "Beton": case "Ortbeton": case "Betonsteinpflaster mit Fase": case "Pflasterbeton":
            case "Betonsteinpflaster / Plattenbelag":
            return "#9933FF";
        case "ungebundene Decke (Kies/Split/Sand/Erde/Gras)":
            return "#FF0000";
        case "Asphalt":
            return "#008B00";
        case "sonstiges":
            return "#FF00FF";
        default:
            return "#D3D3D3";
    }
}

export function getWidthColorClass(width) {

    switch (width) {
        case "1,25 m": case "< 1,30 m": case "1,30 m bis < 1,55 m": case "1,55 m oder größer": case "< 1,60 m":
            return "#db4918";
        case "1,60 m bis < 1,85 m": case "1,60 m bis < 2,00 m": case "< 2,00 m":
            return "#eeac2e";
        case "2,00 m bis < 2,50 m": case "2,00 m oder größer": case "< 2,50 m":
            return "#f8e53a";
        case "2,5 m": case "2,50 m bis < 3,00 m": case "2,8 m": case "2,9 m":
            return "#dce738";
        case "3,00 m bis < 4,00 m": case "3,00 m oder größer": case "3,3 m": case "3,6 m": case "3,7 m": case "3,9 m":
            return "#a4c02c";
        case "4,00 m oder größer": case "4,00m oder größer": case "4 m": case "4,2 m": case "4,5 m": case "4,7 m":
            case "4,9 m": case "5 m": case "5,5 m": case "5,7 m": case "5,8 m": case "6,7 m": case "6,8 m":
                case "7,2 m": case "7,7 m":
            return "#437c17";
        default:
            return "#D3D3D3";
    }
}