import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomTooltip from "./CustomTooltip";
import React from "react";
import {chartTranslation, getDomainMax} from "../functions/Translations";

function Bar_Chart_Several({data, dataX, dataY, colors}) {
    const translation = chartTranslation();

    const domainMax = getDomainMax(dataY[0]);

    return (
        <ResponsiveContainer width='99%' height='88%'>
            <BarChart data={data} layout={"vertical"} margin={{ top: 5, right: 5, left: 10, bottom: 5 }}>
                <XAxis key="default" xAxisId="default" type="number" domain={[0, domainMax]} allowDataOverflow/>
                <YAxis fontSize={12} type="category" scaleToFit="true" textAnchor="end" textWrap={true}
                       verticalAnchor="start" interval={0} angle="0" dataKey={dataX}/>
                <Tooltip content={<CustomTooltip/>}/>
                {dataY.map((dataKey, index) => {
                    let timeAxis = false;
                    if ((dataKey === "time" || dataKey === "count_days") && index !== 0) {
                        timeAxis = true;
                    }
                    return (
                        <>
                            {timeAxis && <XAxis key={dataKey} xAxisId={dataKey} type="number" domain={[0, getDomainMax(dataKey)]}
                                                allowDataOverflow hide={true} orientation={"top"}/>}
                            <Bar key={dataKey} dataKey={dataKey} fill={colors[index]}
                                 name={translation[dataKey]} xAxisId={timeAxis ? dataKey : "default"}/>
                        </>
                    );
                })}
                <Legend/>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default Bar_Chart_Several;