import React, { useEffect } from "react"; // Importieren von React und useEffect-Hook
import { Polyline, Tooltip } from "react-leaflet"; // Importieren von Polyline und Tooltip von React-Leaflet
import { translationOsmValues } from "../dashboard/functions/Translations"; // Importieren der Übersetzungsfunktion

// OSM-Segment-Komponente
const OsmSegment = ({
  segment, // Segmente, die angezeigt werden sollen
  entries, // Einträge, die angezeigt werden sollen
  filters, // Filter für die Segmente
  mapBounds, // Kartenbegrenzungen
  setSegments, // Funktion zum Setzen der Segmente
  selectedSegments, // Ausgewählte Segmente
  multiSelectMode, // Zustand für den Mehrfachauswahlmodus
  setSelectedSegmentId, // Funktion zum Setzen der ausgewählten Segment-ID
  setModalOpen, // Funktion zum Öffnen des Modals
  setSelectedSegments // Funktion zum Setzen der ausgewählten Segmente
}) => {

  // Übersetzungsfunktionsreferenz
  const translations = translationOsmValues();

  // Funktion zur Übersetzung von Werten
  const translateValue = (category, value) => {
    if (!value || value === '-') return '-'; // Rückgabe von '-' für leere Werte
    return translations[category] && translations[category][value] ? translations[category][value] : value; // Rückgabe der Übersetzung oder des ursprünglichen Wertes
  };

  // Effekt, der ausgeführt wird, wenn sich die Kartenbegrenzungen ändern
  useEffect(() => {
    if (mapBounds) { // Überprüfen, ob Kartenbegrenzungen vorhanden sind
      const latMax = mapBounds.getNorthEast().lat; // Maximale Breite
      const lngMax = mapBounds.getNorthEast().lng; // Maximale Länge
      const latMin = mapBounds.getSouthWest().lat; // Minimale Breite
      const lngMin = mapBounds.getSouthWest().lng; // Minimale Länge

      // Overpass API Abfrage für Fahrradwege und Radwege
      const overpassQuery = `
        [out:json][timeout:25];
        (
        way["bicycle"](${latMin},${lngMin},${latMax},${lngMax});
        way["cycleway"](${latMin},${lngMin},${latMax},${lngMax});
        );
        out tags geom;
      `;

      const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`; // URL für die API-Anfrage

      // Abrufen der Daten von der Overpass API
      fetch(overpassUrl)
        .then(response => response.json()) // Umwandeln der Antwort in JSON
        .then(data => {
          // Extrahieren der Segmente aus den API-Daten
          const segments = data.elements.map(element => ({
            segment_id: element.id, // Segment-ID
            name: element.tags.name || 'Name fehlt', // Name des Segments
            geometry: element.geometry.map(coordinates => [coordinates.lat, coordinates.lon]), // Geometrie des Segments
            highway: element.tags.highway || '-', // Wegtyp
            cycleway: element.tags.cycleway || '-', // Führungsform
            bicycle: element.tags.bicycle || '-', // Radnutzung
            cycleway_surface: element.tags['cycleway:surface'] || '-', // Oberfläche des Radwegs
            cycleway_width: element.tags['cycleway:width'] || '-', // Breite des Radwegs
            foot: element.tags.foot || '-', // Fußgänger
            crossing: element.tags.crossing || '-', // Kreuzung
            lanes: element.tags.lanes || '-', // Spuren
            lit: element.tags.lit || '-', // Beleuchtung
            maxspeed: element.tags.maxspeed || '-', // Tempolimit
            parking_lane_left: element.tags['parking:lane:left'] || '-', // Parkspur links
            parking_lane_left_parallel: element.tags['parking:lane:left:parallel'] || '-', // Parallelparkspur links
            parking_lane_right: element.tags['parking:lane:right'] || '-', // Parkspur rechts
            sidewalk: element.tags.sidewalk || '-', // Gehweg
            sidewalk_both_surface: element.tags['sidewalk:both:surface'] || '-', // Oberfläche des Gehwegs
            source_maxspeed: element.tags['source:maxspeed'] || '-', // Quelle des Tempolimits
            surface: element.tags.surface || '-', // Oberfläche
            traffic_sign: element.tags.traffic_sign || '-', // Verkehrszeichen
            width: element.tags.width || '-', // Breite
            oneway: element.tags.oneway || '-', // Einbahnstraße
            rcn: element.tags.rcn || '-', // Regionale Radroute
          }));
          console.log(data); // Ausgabe der API-Daten für Debugging
          setSegments(segments); // Setzen der Segmente im Zustand
        })
        .catch(error => {
          console.error("Fehler beim Laden der Segmente:", error); // Fehlerausgabe
        });
    }
  }, [mapBounds, setSegments]); // Abhängigkeiten: mapBounds und setSegments

  // Funktion zur Überprüfung, ob ein Eintrag den Filtern entspricht
  const matchesFilter = (entry) => {
    for (const category in filters) {
      if (filters[category]) {
        const filterValues = filters[category].split(', ').map(value => value.trim()); // Aufteilen der Filterwerte
        console.log(`Checking category: ${category}, Entry value: ${entry[category]}, Filter values: ${filterValues}`); // Debugging-Ausgabe
        if (!filterValues.includes(entry[category])) {
          return false; // Rückgabe false, wenn der Eintrag nicht übereinstimmt
        }
      }
    }
    return true; // Rückgabe true, wenn alle Filter übereinstimmen
  };

  // Funktion zur Handhabung des Klicks auf die Polyline
  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) { // Überprüfen, ob der Mehrfachauswahlmodus aktiv ist
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected); // Erstellen eines neuen Sets für die ausgewählten Segmente
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId); // Segment entfernen, wenn es bereits ausgewählt ist
        } else {
          newSelected.add(segmentId); // Segment hinzufügen, wenn es nicht ausgewählt ist
        }
        return newSelected; // Rückgabe der neuen Auswahl
      });
    } else {
      setSelectedSegmentId(segmentId); // Setzen der ausgewählten Segment-ID
      setModalOpen(true); // Öffnen des Modals
    }
    console.log(entries); // Ausgabe der Einträge für Debugging
  };

  return (
    <>
      {segment.map((road) => { // Iteration über die Segmente
        if (road.geometry === undefined) {
          return null; // Rückgabe null, wenn die Geometrie nicht definiert ist
        }
        const positions = road.geometry; // Geometrie des Segments
        const isFiltered = matchesFilter(road); // Überprüfen, ob das Segment den Filtern entspricht
        const isSelected = selectedSegments.has(road.segment_id); // Überprüfen, ob das Segment ausgewählt ist
        return (
          <React.Fragment key={road.segment_id}>
            {/* Weiße Polyline für den Gap-Effekt */}
            <Polyline
              key={`${road.segment_id}-white`}
              positions={positions}
              color="white"
              weight={8}
              fill={false}
              opacity={1}
            />
         
            <Polyline
              key={`${road.segment_id}-${isFiltered}-${isSelected}`}
              positions={positions}
              className="hoversegment"
              color={isSelected ? "orange" : "darkgrey"} // Farbe basierend auf dem Auswahlstatus
              weight={6}
              fill={false}
              opacity={isFiltered ? 0.8 : 0.1} // Transparenz basierend auf dem Filterstatus
              eventHandlers={{
                click: () => handlePolylineClick(road.segment_id), // Klick-Handler
                mouseover: (e) => {
                  e.target.setStyle({ color: '#6c9c34', opacity: 1.0 }); // Stiländerung bei Mouseover
                },
                mouseout: (e) => {
                  e.target.setStyle({ color: isSelected ? "orange" : "darkgrey", opacity: isSelected ? 1.0 : (isFiltered ? 0.8 : 0.1) }); // Stiländerung bei Mouseout
                }
              }}
            >
              <Tooltip
                sticky={false}
                direction="auto"
                offset={[0, -5]}
              >
                <div className="table-container">
                  <div className="table-wrapper">
                    <h4>OSM-Daten</h4>
                    <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {road.segment_id && (
                          <tr>
                            <td><strong>ID</strong></td>
                            <td>{road.segment_id}</td>
                          </tr>
                        )}
                        {road.name && (
                          <tr>
                            <td><strong>Name</strong></td>
                            <td>{road.name}</td>
                          </tr>
                        )}
                        {road.lanes && (
                          <tr>
                            <td><strong>Spuren</strong></td>
                            <td>{road.lanes}</td>
                          </tr>
                        )}
                        {road.highway && (
                          <tr>
                            <td><strong>Wegtyp</strong></td>
                            <td>{translateValue('highway', road.highway)}</td>
                          </tr>
                        )}
                        {road.cycleway && (
                          <tr>
                            <td><strong>Führungsform</strong></td>
                            <td>{translateValue('cycleway', road.cycleway)}</td>
                          </tr>
                        )}
                        {road.crossing && (
                          <tr>
                            <td><strong>Kreuzung</strong></td>
                            <td>{road.crossing}</td>
                          </tr>
                        )}
                        {road.bicycle && (
                          <tr>
                            <td><strong>Radnutzung</strong></td>
                            <td>{translateValue('bicycle', road.bicycle)}</td>
                          </tr>
                        )}
                        {road.foot && (
                          <tr>
                            <td><strong>Fußgänger</strong></td>
                            <td>{road.foot}</td>
                          </tr>
                        )}
                        {(road.surface || road.cycleway_surface) && (
                          <tr>
                            <td><strong>Oberfläche</strong></td>
                            <td>{road.surface ? translateValue('surface', road.surface) : road.cycleway_surface}</td>
                          </tr>
                        )}
                        {(road.width || road.cycleway_width) && (
                          <tr>
                            <td><strong>Wegebreite</strong></td>
                            <td>{road.width ? road.width : road.cycleway_width}</td>
                          </tr>
                        )}
                        {road.lit && (
                          <tr>
                            <td><strong>Beleuchtung</strong></td>
                            <td>{translateValue('lit', road.lit)}</td>
                          </tr>
                        )}
                        {road.traffic_sign && (
                          <tr>
                            <td><strong>Verkehrszeichen</strong></td>
                            <td>{road.traffic_sign}</td>
                          </tr>
                        )}
                        {road.maxspeed && (
                          <tr>
                            <td><strong>Tempolimit</strong></td>
                            <td>{road.maxspeed}</td>
                          </tr>
                        )}
                        {road.oneway && (
                          <tr>
                            <td><strong>Einbahnstraße</strong></td>
                            <td>{translateValue('oneway', road.oneway)}</td>
                          </tr>
                        )}
                        {road.rcn && (
                          <tr>
                            <td><strong>regionale Fahrradroute</strong></td>
                            <td>{translateValue('oneway', road.rcn)}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Tooltip>
            </Polyline>
          </React.Fragment>
        );
      })}
      {/* Tabellen-Komponente für Einträge */}
      {entries.map((entry) => {
        if (!entry.geometry) {
          return null; // Rückgabe null, wenn die Geometrie nicht definiert ist
        }

        const isFiltered = matchesFilter(entry); // Überprüfen, ob der Eintrag den Filtern entspricht
        const isSelected = selectedSegments.has(entry.segment_id); // Überprüfen, ob der Eintrag ausgewählt ist
        return (
          <Polyline
            key={`entry-${entry.segment_id}-${isFiltered}`}
            positions={entry.geometry} // Geometrie des Eintrags
            color={isSelected ? "orange" : (isFiltered ? "#6c9c34" : "darkgrey")} // Farbe basierend auf dem Auswahlstatus
            weight={6}
            fill={false}
            opacity={isFiltered ? 0.8 : 0.1} // Transparenz basierend auf dem Filterstatus
            eventHandlers={{
              click: () => toggleOffcanvas(), // Klick-Handler
            }}
          >
            <Tooltip
              sticky={true}
              direction="auto"
              offset={[0, -5]}
            >
              <div className="table-container">
                <div className="table-wrapper">
                  <h4>OSM-Daten</h4>
                  <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.segment_id && (
                        <tr>
                          <td><strong>ID</strong></td>
                          <td>{entry.segment_id}</td>
                        </tr>
                      )}
                      {entry.name && (
                        <tr>
                          <td><strong>Name</strong></td>
                          <td>{entry.name}</td>
                        </tr>
                      )}
                      {entry.highway && (
                        <tr>
                          <td><strong>Wegtyp</strong></td>
                          <td>{translateValue('highway', entry.highway)}</td>
                        </tr>
                      )}
                      {entry.cycleway && (
                        <tr>
                          <td><strong>Führungsform</strong></td>
                          <td>{entry.fuhrungsform ? translateValue('cycleway', entry.fuhrungsform) : translateValue('cycleway', entry.cycleway)}</td>
                        </tr>
                      )}
                      {entry.bicycle && (
                        <tr>
                          <td><strong>Für Rad</strong></td>
                          <td>{translateValue('bicycle', entry.bicycle)}</td>
                        </tr>
                      )}
                      {entry.foot && (
                        <tr>
                          <td><strong>Für Fußgänger</strong></td>
                          <td>{entry.foot}</td>
                        </tr>
                      )}
                      {entry.surface && (
                        <tr>
                          <td><strong>Oberfläche</strong></td>
                          <td>{entry.oberflaeche ? translateValue('surface', entry.oberflaeche) : translateValue('surface', entry.surface)}</td>
                        </tr>
                      )}
                      {entry.lit && (
                        <tr>
                          <td><strong>Beleuchtung</strong></td>
                          <td>{translateValue('lit', entry.lit)}</td>
                        </tr>
                      )}
                      {entry.traffic_sign && (
                        <tr>
                          <td><strong>Verkehrszeichen</strong></td>
                          <td>{entry.traffic_sign}</td>
                        </tr>
                      )}
                      {entry.maxspeed && (
                        <tr>
                          <td><strong>Tempolimit</strong></td>
                          <td>{entry.tempolimit ? entry.tempolimit : entry.maxspeed}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="table-wrapper">
                  <h4>Ergänzte Daten</h4>
                  <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entry.fuhrungsform && (
                        <tr>
                          <td><strong>Führungsform</strong></td>
                          <td>{entry.fuhrungsform}</td>
                        </tr>
                      )}
                      {entry.oberflaeche && (
                        <tr>
                          <td><strong>Oberfläche</strong></td>
                          <td>{entry.oberflaeche}</td>
                        </tr>
                      )}
                      {entry.tempolimit && (
                        <tr>
                          <td><strong>Tempolimit</strong></td>
                          <td>{entry.tempolimit}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tooltip>
          </Polyline>
        );
      })}
    </>
  );
};

export default OsmSegment; // Export der OsmSegment-Komponente