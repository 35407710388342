import React from 'react';
import {chartTranslation} from "../functions/Translations";
import {secondsToTime} from "../functions/Time";

const CustomTooltip = ({ active, payload, label }) => {
  const translation = chartTranslation();

  if (active && payload && payload.length) {
    return (
      <div className='customTooltip'>
        <p >{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {entry.name === 'count_'
                ? `${entry.value} `
                : entry.name === translation['length'] ? `${entry.value} km`
                : entry.name === translation['speed'] ? `${entry.value} km/h`
                : entry.name === translation['time'] ? `${secondsToTime(entry.value)} h`
                : entry.name === translation['count_rides'] ? `${entry.value} ${translation["rides"]}`
                : entry.name === translation['normalized_rides'] ? `${entry.value} ${translation["rides"]}`
                : entry.name === translation['count_days'] ? `${entry.value} ${translation["days"]}`
                : entry.name === translation['accidents'] ? `${entry.value} ${translation["accidents"]}`
                : entry.name === translation['accident-probability'] ? `${entry.value} %`
                : entry.name === 'avg_cognos_monetary_damage'
              ? `${parseFloat(entry.value).toFixed(2)} €`
              : entry.name === 'index' || entry.name === 'unfallrate'
              ? `${parseFloat(entry.value).toFixed(2)}`
              : `${(entry.value).toFixed(0)}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;
