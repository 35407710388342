export const convert = (key, data) => {
    switch (key) {
        case "roadScore":
            return convertRoadScore(data);
        case "nodes":
            return convertNodes(data);
        case "roadSegments":
            return convertRoadSegments(data);
        case "heatmaps":
            return convertHeatmaps(data);
        case "busLines":
            return convertBusLines(data);
        case "busStops":
            return convertBusStops(data);
        case "bikeStations":
            return convertBikeStations(data);
        case "carStations":
            return convertCarStations(data);
        case "accidents":
            return convertAccidents(data);
        case "concerns":
            return convertConcerns(data);
        case "reports":
            return convertReports(data);
        case "infrastructure":
            return convertInfrastructure(data);
        case "accumulations":
            return convertAccumulations(data);
    }
}

const convertRoadScore = (data) => {
    data = data.map((element) => {
        element.geometry.coordinates  = element.geometry.coordinates.map(value => [value[1], value[0]]);
        return element;
    });

    return {
        type: 'FeatureCollection',
        features: data.map(item => ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {
                full_percentile_score: item.full_percentile_score,
                full_percentile_accidents_per_m: item.full_percentile_accidents_per_m,
                full_percentile_concerns_per_m: item.full_percentile_concerns_per_m,
                full_percentile_daily_traffic: item.full_percentile_daily_traffic,
                full_percentile_time_loss: item.full_percentile_time_loss,
                full_percentile_anomaly: item.full_percentile_anomaly,
                full_percentile_v_score_v2: item.full_percentile_v_score_v2
            }
        })),
    };
}

const convertNodes = (data) => {
    data = data.map((element) => {
        const coordinates = element.geometry.coordinates[0].map(value => [value[1], value[0]]);
        element.geometry.coordinates = [coordinates];
        return element;
    });

    return {
        type: 'FeatureCollection',
        features: data.map(item => ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {
                v_score_v1: item.v_score_v1,
                v_score_v2: item.v_score_v2,
                anomaly: item.anomaly,
                time_loss: item.time_loss
            }
        })),
    };
}

const convertRoadSegments = (data) => {
    data = data.map((element) => {
        element.geometry.coordinates = element.geometry.coordinates.map(value => [value[1], value[0]]);
        return element;
    });

    return {
        type: 'FeatureCollection',
        features: data.map(item => ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {
                v_score_v1: item.v_score_v1,
                v_score_v2: item.v_score_v2,
                anomaly: item.anomaly,
                time_loss: item.time_loss
            }
        })),
    };
}

const convertHeatmaps = (data) => {
    data = data.map((element) => {
        const coordinates = element.geometry.coordinates[0].map((value) => [value[1], value[0]]);
        element.geometry.coordinates = [coordinates];
        return element;
    });

    return {
        type: 'FeatureCollection',
        features: data.map(item => ({
            type: 'Feature',
            geometry: item.geometry,
            properties: {
                anomaly_v1: item.anomaly_v1,
                anomaly_v2: item.anomaly_v2,
                frequency: item.frequency,
                v_score_v1: item.v_score_v1,
                v_score_v2: item.v_score_v2,
                waiting_time: item.waiting_time,
                normalized_speed: item.normalized_speed,
                absolute_speed: item.absolute_speed,
                full_brakings: item.full_brakings
            }
        })),
    };
}

const convertBusLines = (data) => {
    const features = [];

    Object.keys(data).map((busLineName) => {
        const busLineData = data[busLineName];

        busLineData.forEach((item) => (
            features.push({
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: item.coordinates.map((coord) => [
                        coord[1],
                        coord[0],
                    ])
                },
                properties: {
                    bus_line_id: item.busLineId,
                    bus_line_name: busLineName
                }
            })))});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertBusStops = (data) => {
    const features = [];

    Object.keys(data).map((busStopId) => {
        const busStop = data[busStopId];

        features.push({
            type: 'Feature',
            geometry: busStop.geometry,
            properties: {
                busStopId: busStopId,
                bus_stop_name: busStop.bus_stop_name
            }
        })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertBikeStations = (data) => {
    const features = [];

    Object.keys(data).map((stationId) => {
        const station = data[stationId];

        features.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [station.longitude, station.latitude]
            },
            properties: {
                stationId: stationId,
                road_name: station.road_name,
                city: station.city,
                avg_sum_bothdirections: station.avg_sum_bothdirections,
                count_: station.count_,
                day_avg_bothdirections: station.day_avg_bothdirections,
                day_avg_countfrom: station.day_avg_countfrom,
                day_avg_countto: station.day_avg_countto,
                hour_avg_bothdirections: station.hour_avg_bothdirections,
                hour_avg_countfrom: station.hour_avg_countfrom,
                hour_avg_countto: station.hour_avg_countto,
                max_bothdirections: station.max_bothdirections,
                max_bothdirections_range: station.max_bothdirections_range,
                max_day_peak_bothdirections_time: station.max_day_peak_bothdirections_time,
                max_day_peak_countfrom_time: station.max_day_peak_countfrom_time,
                max_day_peak_countto_time: station.max_day_peak_countto_time,
                max_dow_peak_bothdirections_time: station.max_dow_peak_bothdirections_time,
                sum_bothdirections: station.sum_bothdirections,
                sum_bothdirections_range: station.sum_bothdirections_range,
                sum_countfrom: station.sum_countfrom,
                sum_countfrom_range: station.sum_countfrom_range,
                sum_countto: station.sum_countto,
                sum_countto_range: station.sum_countto_range
            }
        })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertCarStations = (data) => {
    const features = [];

    Object.keys(data).map((stationId) => {
        const station = data[stationId];

        features.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [station.longitude, station.latitude]
            },
            properties: {
                stationId: stationId,
                avg_speed: station.avg_speed,
                day_avg_traffic_volume: station.day_avg_traffic_volume,
                hour_avg_traffic_volume: station.hour_avg_traffic_volume,
                max_speed: station.max_speed,
                max_traffic_volume: station.max_traffic_volume,
                sum_traffic_volume: station.sum_traffic_volume
            }
        })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertAccidents = (data) => {
    const features = [];

    Object.keys(data).map(year =>
        Object.keys(data[year]).map(month =>
            Object.keys(data[year][month]).map(primaryKey => {
                const accident = data[year][month][primaryKey];

                features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [accident.euska_longitude, accident.euska_latitude]
                    },
                    properties: {
                        cloudcover: accident.cloudcover,
                        cognos_accident_type: accident.cognos_accident_type,
                        cognos_alcohol: accident.cognos_alcohol,
                        cognos_drugs: accident.cognos_drugs,
                        cognos_monetary_damage: accident.cognos_monetary_damage,
                        condition: accident.condition,
                        datetime: accident.datetime,
                        day: accident.day,
                        dew: accident.dew,
                        euska_accident_color: accident.euska_accident_color,
                        euska_accident_type: accident.cognos_accident_type,
                        euska_accident_type_long: accident.euska_accident_type_long,
                        euska_accident_type_simplified: accident.euska_accident_type_simplified,
                        euska_participant_german: accident.euska_participant_german,
                        euska_road_condition: accident.euska_road_condition,
                        formattedDateTime: accident.formattedDateTime,
                        heatindex: accident.heatindex,
                        hours: accident.hours,
                        humidity: accident.humidity,
                        injured: accident.injured,
                        killed: accident.killed,
                        minutes: accident.minutes,
                        month: month,
                        number_participants: accident.number_participants,
                        perpetrator_german: accident.perpetrator_german,
                        road_name: accident.road_name,
                        serious_injured: accident.serious_injured,
                        slightly_injured: accident.slightly_injured,
                        snow: accident.snow,
                        snowdepth: accident.snowdepth,
                        solarenergy: accident.solarenergy,
                        temp: accident.temp,
                        year: year
                    }
                })})));

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertConcerns = (data) => {
    const features = [];

    Object.keys(data).map(year =>
        Object.keys(data[year]).map(month =>
            Object.keys(data[year][month]).map(primaryKey => {
                const concern = data[year][month][primaryKey];

                features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [concern.longitude, concern.latitude]
                    },
                    properties: {
                        category_text: concern.category_text,
                        cloudcover: concern.cloudcover,
                        datetime: concern.datetime,
                        day: concern.day,
                        description: concern.description,
                        formattedDateTime: concern.formattedDateTime,
                        heatindex: concern.heatindex,
                        mint: concern.mint,
                        month: month,
                        snow: concern.snow,
                        status_text: concern.status_text,
                        topic_text: concern.topic_text,
                        year: year
                    }
                })})));

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertReports = (data) => {
    const features = [];

    Object.keys(data).map(annotation_id => {
        const image = data[annotation_id];

                features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [image.longitude, image.latitude]
                    },
                    properties: {
                        annotation_id: annotation_id,
                        classification: image.classification,
                        confidence: image.confidence,
                        create_date: image.create_date
                    }
                })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertInfrastructure = (data) => {
    const features = [];

    Object.keys(data).map((i) => {
        const road = data[i];

        features.push({
            type: 'Feature',
            geometry: {
                type: 'Linestring',
                coordinates: road.geometry.coordinates.map((coord) => [coord[1], coord[0]])
            },
            properties: {
                geometry_id: road.geometry_id,
                length: road.length,
                path: road.path,
                path_class: road.path_class,
                path_type: road.path_type,
                road_id: road.road_id,
                road_name: road.road_name,
                slope: road.slope,
                surface_type: road.surface_type,
                width: road.width
            }
        })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

const convertAccumulations = (data) => {
    const features = [];

    Object.keys(data).map((i) => {
        const accumulation = data[i];

        let geometry;
        if (accumulation.geometry.type === 'LineString') {
            geometry = accumulation.geometry;
        } else if (accumulation.geometry.type === 'Polygon') {
            geometry = {
                type: 'Polygon',
                coordinates: [accumulation.geometry.coordinates.map((coord) => [coord[1], coord[0]])]
            };
        } else {
            geometry = {
                type: 'LineString',
                coordinates: accumulation.geometry
            };
        }

        const counts = {};
        if (accumulation.accident_type_counts) {
            const accident_type_counts = accumulation.accident_type_counts;
            for (const accident in accident_type_counts) {
                counts[accident] = accident_type_counts[accident];
            }
        }

        if (accumulation.concern_type_counts) {
            const concern_type_counts = accumulation.concern_type_counts;
            for (const concern in concern_type_counts) {
                counts[concern] = concern_type_counts[concern];
            }
        }

        features.push({
            type: 'Feature',
            geometry: geometry,
            properties: {
                accident_count: accumulation.accidentsCount ? accumulation.accidentsCount : accumulation.accident_count,
                length: accumulation.length,
                max: accumulation.max,
                station_name: accumulation.stationname,
                accident_rate: accumulation.unfallrate,
                segment_id: accumulation.segment_id,
                concern_count: accumulation.concern_count,
                damage_count: accumulation.damage_count,
                stations: accumulation.stations,
                sum_traffic_count: accumulation.sum_traffic_count,
                day_avg_traffic_count: accumulation.day_avg_traffic_count,
                hour_avg_traffic_count: accumulation.hour_avg_traffic_count,
                avg_speed_count: accumulation.avg_speed_count,
                node_id: accumulation.node_id,
                counts: Object.entries(counts).length > 0 ? counts : undefined
            }
        })});

    return {
        type: 'FeatureCollection',
        features: features
    };
}

