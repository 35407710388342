import React, { useState } from "react"; // Importieren von React und useState-Hook
import { Modal, Button } from "react-bootstrap"; // Importieren von Modal und Button von React-Bootstrap
import * as XLSX from 'xlsx'; // Importieren der XLSX-Bibliothek für Excel-Export

// Exportkomponente
const ExportComponent = ({ 
  exportModalOpen, // Zustand für die Sichtbarkeit des Exportmodals
  setExportModalOpen, // Funktion zum Schließen des Exportmodals
  entries, // Einträge, die exportiert werden sollen
  segment // Segmente, die exportiert werden sollen
}) => {
  
  // Funktion zum Konvertieren von Daten in CSV-Format (alte Version)
  const convertToCSVold = (data) => {
    if (data.length === 0) return ''; // Rückgabe eines leeren Strings, wenn keine Daten vorhanden sind

    const headers = Object.keys(data[0]); // Extrahieren der Header aus den Daten
    const csvRows = [];

    // Header hinzufügen
    csvRows.push(headers.join(',')); // Header als erste Zeile hinzufügen

    // Zeilen hinzufügen
    data.forEach(row => {
      const values = headers.map(header => {
        let value = row[header]; // Wert für die aktuelle Zeile und den Header
        if (typeof value === 'object') {
          value = JSON.stringify(value); // Umwandeln von Objekten in JSON-Strings
        }
        return `${value}`; // Rückgabe des Wertes
      });
      csvRows.push(values.join(',')); // Werte als neue Zeile hinzufügen
    });

    return csvRows.join('\n'); // Rückgabe der CSV-Zeilen als String
  };

  // Funktion zum Konvertieren von Daten in CSV-Format (aktuelle Version)
  const convertToCSV = (data) => {
    if (data.length === 0) return ''; // Rückgabe eines leeren Strings, wenn keine Daten vorhanden sind

    const headers = Object.keys(data[0]); // Extrahieren der Header aus den Daten
    const csvRows = [];

    // Header hinzufügen
    csvRows.push(headers.join(',')); // Header als erste Zeile hinzufügen

    // Zeilen hinzufügen
    data.forEach(row => {
        const values = headers.map(header => {
            let value = row[header]; // Wert für die aktuelle Zeile und den Header
            if (typeof value === 'object') {
                value = JSON.stringify(value); // Umwandeln von Objekten in JSON-Strings
            }
            return `${value}`; // Rückgabe des Wertes
        });
        csvRows.push(values.join(',')); // Werte als neue Zeile hinzufügen
    });

    return csvRows.join('\n'); // Rückgabe der CSV-Zeilen als String
  };

  // Funktion zum Herunterladen der CSV-Datei
  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' }); // Erstellen eines Blob-Objekts für die CSV-Daten
    const link = document.createElement("a"); // Erstellen eines Link-Elements
    const url = URL.createObjectURL(blob); // Erstellen einer URL für den Blob
    link.setAttribute("href", url); // Setzen des href-Attributs des Links
    link.setAttribute("download", filename); // Setzen des download-Attributs für den Dateinamen
    link.style.visibility = 'hidden'; // Link unsichtbar machen
    document.body.appendChild(link); // Link zum DOM hinzufügen
    link.click(); // Link klicken, um den Download zu starten
    document.body.removeChild(link); // Link aus dem DOM entfernen
  };

  // Funktion zum Exportieren neuer Einträge
  const exportNewEntries = () => {
    if (entries.length === 0) {
      console.warn("Keine neuen Einträge."); // Warnung, wenn keine neuen Einträge vorhanden sind
      return;
    }
    const formattedEntries = entries.map(entry => ({
      ...entry,
      geometry: JSON.stringify(entry.geometry) // Geometrie in JSON-String umwandeln
    }));
    console.log("Exportierte neue Einträge:", formattedEntries); // Ausgabe der exportierten Einträge
    const worksheet = XLSX.utils.json_to_sheet(formattedEntries); // Erstellen eines Arbeitsblatts aus den Einträgen
    const workbook = XLSX.utils.book_new(); // Erstellen eines neuen Arbeitsbuchs
    XLSX.utils.book_append_sheet(workbook, worksheet, "Neue Einträge"); // Hinzufügen des Arbeitsblatts zum Arbeitsbuch
    XLSX.writeFile(workbook, "neue_eintraege.xlsx"); // Speichern des Arbeitsbuchs als Excel-Datei
  };

  // Funktion zum Exportieren aller Segmente im CSV-Format
  const exportAllSegmentsCsv = () => {
    if (segment.length === 0) {
        console.warn("Keine Segmente."); // Warnung, wenn keine Segmente vorhanden sind
        return;
    }
    const formattedSegments = segment.map(seg => ({
        ...seg,
        geometry: `"LINESTRING(${seg.geometry.map(coord => coord.join(' ')).join(',')})"` // Umwandeln in LINESTRING-Format
    }));
    const csv = convertToCSV(formattedSegments); // Konvertieren der Segmente in CSV
    downloadCSV(csv, "alle_segmente.csv"); // Herunterladen der CSV-Datei
  };

  // Funktion zum Exportieren aller Segmente im XLSX-Format
  const exportAllSegmentsXlsx = () => {
    if (segment.length === 0) {
      console.warn("Keine Segmente."); // Warnung, wenn keine Segmente vorhanden sind
      return;
    }

    const formattedSegments = segment.map(seg => ({
      ...seg,
      geometry: `LINESTRING(${seg.geometry.map(coord => `${coord[1]} ${coord[0]}`).join(',')})` // Tausch von lat und lng für PostGIS
    }));

    const workbook = XLSX.utils.book_new(); // Erstellen eines neuen Arbeitsbuchs
    const worksheet = XLSX.utils.json_to_sheet(formattedSegments); // Erstellen eines Arbeitsblatts aus den Segmenten
    XLSX.utils.book_append_sheet(workbook, worksheet, "Segmente"); // Hinzufügen des Arbeitsblatts zum Arbeitsbuch
    const fileName = "alle_segmente.xlsx"; // Dateiname für die Excel-Datei
    XLSX.writeFile(workbook, fileName); // Speichern des Arbeitsbuchs als Excel-Datei
  };

  // Funktion zum Exportieren gefilterter Segmente
  const exportFilteredSegments = () => {
    const filteredSegments = segment.filter(matchesFilter); // Filtern der Segmente
    if (filteredSegments.length === 0) {
      console.warn("Keine gefilterten Segmente."); // Warnung, wenn keine gefilterten Segmente vorhanden sind
      return;
    }
    const formattedFilteredSegments = filteredSegments.map(seg => ({
      ...seg,
      geometry: JSON.stringify(seg.geometry) // Geometrie in JSON-String umwandeln
    }));
    console.log("Exportierte gefilterte Segmente:", formattedFilteredSegments); // Ausgabe der exportierten gefilterten Segmente
    const worksheet = XLSX.utils.json_to_sheet(formattedFilteredSegments); // Erstellen eines Arbeitsblatts aus den gefilterten Segmenten
    const workbook = XLSX.utils.book_new(); // Erstellen eines neuen Arbeitsbuchs
    XLSX.utils.book_append_sheet(workbook, worksheet, "Gefilterte Segmente"); // Hinzufügen des Arbeitsblatts zum Arbeitsbuch
    XLSX.writeFile(workbook, "gefilterte_segmente.xlsx"); // Speichern des Arbeitsbuchs als Excel-Datei
  };

  return (
    <Modal
      show={exportModalOpen} // Sichtbarkeit des Modals
      onHide={() => setExportModalOpen(false)} // Funktion zum Schließen des Modals
      centered // Zentrieren des Modals
      className="modal-export" // CSS-Klasse für das Modal
    >
      <Modal.Header closeButton>
        <Modal.Title>Export Optionen</Modal.Title> {/* Titel des Modals */}
      </Modal.Header>
      <Modal.Body>
        <Button variant="primary" className="w-100 mb-2" onClick={exportNewEntries}>
          Neue Einträge exportieren {/* Button zum Exportieren neuer Einträge */}
        </Button>
        <Button variant="primary" className="w-100 mb-2" onClick={exportAllSegmentsCsv}>
          Alle Segmente exportieren (.csv) {/* Button zum Exportieren aller Segmente im CSV-Format */}
        </Button>
        <Button variant="primary" className="w-100 mb-2" onClick={exportAllSegmentsXlsx}>
          Alle Segmente exportieren (.xlsx) {/* Button zum Exportieren aller Segmente im XLSX-Format */}
        </Button>
        <Button variant="primary" className="w-100" onClick={exportFilteredSegments}>
          Gefilterte Segmente exportieren {/* Button zum Exportieren gefilterter Segmente */}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ExportComponent; // Export der Exportkomponente