import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {getBusLineColors} from "../../functions/Colors";
import {fetchBusLinesData} from "../../functions/MapFunctions";

function BusLinesLayer({busChecked, busData, mapBounds, loadingSpin, updateBusData, updateLoadingSpin}) {

    const useBusLinesData = useCallback(() => {
        fetchBusLinesData(mapBounds, updateBusData, updateLoadingSpin);
    }, [mapBounds]);

    useEffect(() => {
        if (busChecked) {
            useBusLinesData();
        } else {
            updateBusData({});
        }
    }, [mapBounds, busChecked]);

    const busColors = getBusLineColors();

    return (
        <LayersControl.Overlay name="5: Buslinien" checked={busChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(busData).map((busLineName, index) => {
                    const busLineData = busData[busLineName];

                    if (busLineData.length > 0) {
                        return (
                            <LayerGroup key={index}>
                                {busLineData.map((geometry, innerIndex) => (
                                    // Linienelemente
                                    <Polyline key={`${index}-${innerIndex}`} positions={geometry.coordinates}
                                              color={busColors[busLineName]} opacity={1}>
                                        {/* Linie bei Mouse Over zeigen*/}
                                        <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                            {busLineName}
                                        </Tooltip>
                                    </Polyline>
                                ))}
                            </LayerGroup>
                        );
                    } else {
                        return null;
                    }
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default BusLinesLayer;