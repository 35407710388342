import React, { useState } from "react"; // Importieren von React und useState-Hook
import { Offcanvas, Button, ButtonGroup } from "react-bootstrap"; // Importieren von Offcanvas und Button-Komponenten von React-Bootstrap
import DatenAuswahlModal from "./DatenAuswahlModal"; // Importieren der DatenAuswahlModal-Komponente

// Navigationskomponente
const NavComponent = ({
  handleLoadSegments, // Funktion zum Laden von Segmenten
  setFilterModalOpen, // Funktion zum Öffnen des Filtermodals
  toggleMultiSelectMode, // Funktion zum Umschalten des Mehrfachauswahlmodus
  toggleOffcanvas, // Funktion zum Umschalten der Offcanvas-Anzeige
  setExportModalOpen, // Funktion zum Öffnen des Exportmodals
  setImportModalOpen, // Funktion zum Öffnen des Importmodals
  multiSelectMode, // Zustand für den Mehrfachauswahlmodus
  selectedSegments, // Ausgewählte Segmente
  navShow, // Zustand für die Sichtbarkeit der Navigation
  handleNavShow, // Funktion zum Umschalten der Navigation
  setModalOpen, // Funktion zum Öffnen des Modals
  dataSource, // Aktuelle Datenquelle
  setDataSource, // Funktion zum Setzen der Datenquelle
  selectedRSCategories, // Ausgewählte Kategorien
  setselectedRSCategories, // Funktion zum Setzen der ausgewählten Kategorien
  selectedCombinations, // Ausgewählte Kombinationen
  setSelectedCombinations // Funktion zum Setzen der ausgewählten Kombinationen
}) => {
  const [dataSelectionModalOpen, setDataSelectionModalOpen] = useState(false); // Zustand für die Sichtbarkeit des Datenauswahlmodals

  return (
    <>
      <Offcanvas show={navShow} onHide={handleNavShow} placement="start" className="greyOC"> {/* Offcanvas für die Navigation */}
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Optionen</Offcanvas.Title> {/* Titel der Offcanvas */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="switch-container">
            <button className="test-button" onClick={() => setDataSelectionModalOpen(true)}> {/* Button zum Öffnen des Datenauswahlmodals */}
              Datenquelle wählen/ Rangfilter einstellen
            </button>
          </div>
          <hr></hr> {/* Trennlinie */}

          {dataSource === 'osm' && ( // Wenn die Datenquelle 'osm' ist
            <>
              <button className="test-button" onClick={() => setFilterModalOpen(true)}> {/* Button zum Öffnen des Filtermodals */}
                Segmente filtern
              </button>

              <button
                className="test-button"
                onClick={toggleMultiSelectMode} // Button zum Umschalten des Mehrfachauswahlmodus
              >
                {multiSelectMode ? "Multi-Auswahl beenden" : "Multi-Auswahl starten"} {/* Text basierend auf dem Zustand */}
              </button>

              <button
                className="test-button"
                onClick={toggleOffcanvas} // Button zum Umschalten der Offcanvas-Anzeige
              >
                Einträge anzeigen/ändern
              </button>

              <button
                className="test-button"
                onClick={() => setExportModalOpen(true)} // Button zum Öffnen des Exportmodals
              >
                Exportieren
              </button>
            </>
          )}

          <button
            className="test-button"
            onClick={() => setImportModalOpen(true)} // Button zum Öffnen des Importmodals
          >
            GeoJson importieren
          </button>

          {multiSelectMode && selectedSegments.size > 0 && ( // Wenn der Mehrfachauswahlmodus aktiv ist und Segmente ausgewählt sind
            <button
              className="test-button"
              onClick={() => setModalOpen(true)} // Button zum Öffnen des Modals für die ausgewählten Einträge
            >
              Eintrag für Auswahl
            </button>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <DatenAuswahlModal
        show={dataSelectionModalOpen} // Sichtbarkeit des Datenauswahlmodals
        onHide={() => setDataSelectionModalOpen(false)} // Funktion zum Schließen des Modals
        dataSource={dataSource} // Aktuelle Datenquelle
        setDataSource={setDataSource} // Funktion zum Setzen der Datenquelle
        selectedRSCategories={selectedRSCategories} // Ausgewählte Kategorien
        setselectedRSCategories={setselectedRSCategories} // Funktion zum Setzen der ausgewählten Kategorien
        selectedCombinations={selectedCombinations} // Ausgewählte Kombinationen
        setSelectedCombinations={setSelectedCombinations} // Funktion zum Setzen der ausgewählten Kombinationen
      />
    </>
  );
};

export default NavComponent; // Export der Navigationskomponente