import {errorHandling} from "./Errors";
import {ApiConnect} from "../Dashboard";
import {
    accidentCountPeopleTime,
    accidentCountPeopleType,
    accidentGround,
    accidentPerpetrator,
    accidentTemperature,
    accidentTime,
    accidentTypes,
    accidentWeather,
    bikeTraffic,
    bikeTrafficAllStreets,
    concernsDuration,
    concernsTime,
    concernsType,
    concernsTypeProblem,
    moneyDamagePerAccidentPerpetrator,
    avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount,
    moneyDamagePerAccidentPerpetratorWithPeopleDamage,
    AvgMoneyDamagePerAccidentPerpetratorWithPeopleDamage,
    ridesCount, ridesCondition,
    streetCountAccidentsTop10,
    streetCountAccidentsTop10LengthSensitive,
    biketrafficAccidents,
    cartrafficAccidents
} from "./ApiFunctions";
import {getStandard} from "../templates/Standard";
import {getConcerns} from "../templates/Concerns";
import {getAccidents} from "../templates/Accidents";
import {getBicycleVolume} from "../templates/BicycleVolume";
import {getRides} from "../templates/Rides";

export async function handleSubmit(event, city, overallTopic, topic, road, timeDimension, start, end, boxIndex,
                                   updateBoxInBoxes) {
    event.preventDefault();

    if ((overallTopic === "" || topic === "" || timeDimension === "" || start === "" || end === "" || city === "") ||
        (topic === "bicycle-volume" && road === "")) {
        alert("Bitte fehlende Angaben ergänzen!");
        return;
    }

    switch (topic) {
        case "bicycle-volume":
            ApiConnect.fetchBikeTraffic(timeDimension, road, [start, end])
                .then((data) => {
                    const newBox = bikeTraffic(data, city, start, end, timeDimension, topic, overallTopic, road);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "bicycle-volume-all-streets":
            ApiConnect.fetchBikeTrafficAllStreets(city, [start, end])
                .then((data) => {
                    const newBox = bikeTrafficAllStreets(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-type":
            ApiConnect.fetchAccidentTypes(city, [start, end])
                .then((data) => {
                    const newBox = accidentTypes(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-time":
            ApiConnect.fetchAccidentTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = accidentTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-people-type":
            ApiConnect.fetchAccidentCountPeopleType(city, [start, end])
                .then((data) => {
                    const newBox = accidentCountPeopleType(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-people-time":
            ApiConnect.fetchAccidentCountPeopleTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = accidentCountPeopleTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                })
                .catch((error) => {
                    errorHandling(error);
                });
            break;


        case "accidents-perpetrator":
            ApiConnect.fetchAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = accidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-road-condition":
            ApiConnect.fetchAccidentGround(city, [start, end])
                .then((data) => {
                    const newBox = accidentGround(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-weather":
            ApiConnect.fetchAccidentWeatherMonth(city, [start, end])
                .then((data) => {
                    const newBox = accidentWeather(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-temperature":
            Promise.all([
                ApiConnect.fetchAccidentTempLowerMinus10(city, [start, end]),
                ApiConnect.fetchAccidentTempMinus10to0(city, [start, end]),
                ApiConnect.fetchAccidentTemp0to10(city, [start, end]),
                ApiConnect.fetchAccidentTemp10to20(city, [start, end]),
                ApiConnect.fetchAccidentTemp20to30(city, [start, end]),
                ApiConnect.fetchAccidentTempHigher30(city, [start, end])
            ])
                .then(([dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30]) => {
                    const newBox = accidentTemperature(dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30,
                        city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-monetary-damage":
            ApiConnect.fetchMoneyDamagePerAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = moneyDamagePerAccidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "avg-accidents-monetary-damage":
            ApiConnect.fetchAvgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(city, [start, end])
                .then((data) => {
                    const newBox = avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-monetary-damage-people":
            ApiConnect.fetchDamagePerAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = moneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "avg-accidents-monetary-damage-people":
            ApiConnect.fetchDamagePerAccidentPerpetrator(city, [start, end])
                .then((data) => {
                    const newBox = AvgMoneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-count-per-street":
            ApiConnect.fetchAccidentsCountStreet(city, start, end)
                .then((data) => {
                    const newBox = streetCountAccidentsTop10(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;


        case "accidents-to-biketraffic-count":
            Promise.all([
                ApiConnect.fetchAccidentsCountStreet(city, start, end),
                ApiConnect.fetchBikeTrafficAllStreets(city, [start, end]),
                ApiConnect.fetchRoaddata()
            ])
                .then(([acc, bikestations, roaddata]) => {
                    // Umwandeln der bikestations-Daten in ein Objekt zur einfachen Suche
                    const bikeData = bikestations.reduce((acc, station) => {
                        acc[station.road_name] = station;
                        return acc;
                    }, {});

                    // Umwandeln der roaddata-Daten in ein Objekt zur einfachen Suche
                    const roadData = roaddata.reduce((acc, road) => {
                        acc[road.road_name] = road;
                        return acc;
                    }, {});


                    // Berechnung der Anzahl der Tage zwischen Start und Ende
                    const startDate = new Date(start);
                    const endDate = new Date(end);
                    const timeDiff = Math.abs(endDate - startDate);
                    const numDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // +1 um den letzten Tag einzuschließen

                    const mergedData = acc
                        .filter(accItem => bikeData[accItem.road_name])  // Nur Straßen berücksichtigen, die in beiden Fetches vorkommen
                        .map(accItem => {
                            const bikeStation = bikeData[accItem.road_name];

                            const roadInfo = roadData[accItem.road_name];

                            if (!roadInfo) {
                                return null; // Überspringen des Eintrags, wenn roadInfo undefined ist
                            }

                            let totalTraffic;

                            if (bikeStation.sum_countto === 0 && bikeStation.sum_countfrom === 0) {
                                totalTraffic = bikeStation.sum_bothdirections / numDays;
                            } else {
                                totalTraffic = (bikeStation.sum_countto + (bikeStation.sum_countfrom === 0 ? bikeStation.sum_countto : bikeStation.sum_countfrom)) / numDays;
                            }

                            let roadLength = roadInfo.length; // Länge der Straße
                            let accidents;
                            accidents = accItem.count_ * 1000000;
                            const multiplier = Math.ceil(numDays / 365);
                            totalTraffic = (totalTraffic / numDays) * roadLength * 365 * multiplier;

                            // Wenn totalTraffic 0 ist, überspringen wir den Eintrag
                            if (totalTraffic === 0) {
                                return null; // Überspringen des Eintrags
                            }
                            return {
                                road_name: accItem.road_name,
                                unfallrate: (accidents / totalTraffic),
                            };
                        })
                        .filter(item => item !== null); // Filtern der übersprungenen Einträge

                    console.log(mergedData);
                    const newBox = biketrafficAccidents(mergedData, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                })
                .catch((error) => {
                    errorHandling(error);
                });
            break;

        case "accidents-to-cartraffic-count":
            if (city === "oldenburg") {
                Promise.all([
                    ApiConnect.fetchAccidentsCountStreet(city, start, end),
                    ApiConnect.fetchCarStationsOldenburgNoGeo(start, end),
                    ApiConnect.fetchRoaddata()
                ])
                    .then(([acc, carstations, roaddata]) => {
                        // Umwandeln der carstations-Daten in ein Objekt zur einfachen Suche
                        const carData = carstations.reduce((acc, station) => {
                            acc[station.road_name] = station;
                            return acc;
                        }, {});

                        // Umwandeln der roaddata-Daten in ein Objekt zur einfachen Suche
                        const roadData = roaddata.reduce((acc, road) => {
                            acc[road.road_name] = road;
                            return acc;
                        }, {});

                        // Berechnung der Anzahl der Tage zwischen Start und Ende
                        const startDate = new Date(start);
                        const endDate = new Date(end);
                        const timeDiff = Math.abs(endDate - startDate);
                        const numDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // +1 um den letzten Tag einzuschließen

                        const mergedData = acc
                            .filter(accItem => carData[accItem.road_name])  // Nur Straßen berücksichtigen, die in beiden Fetches vorkommen
                            .map(accItem => {
                                const carStation = carData[accItem.road_name];
                                const roadInfo = roadData[accItem.road_name];

                                if (!roadInfo) {
                                    return null; // Überspringen des Eintrags, wenn roadInfo undefined ist
                                }
                                let totalTraffic;
                                let roadLength = roadInfo.length; // Länge der Straße
                                let accidents;
                                accidents = accItem.count_ * 1000000;
                                const multiplier = Math.ceil(numDays / 365);
                                totalTraffic = (carStation.sum_traffic_volume / numDays) * roadLength * 365 * multiplier;

                                // Wenn totalTraffic 0 ist, überspringen wir den Eintrag
                                if (totalTraffic === 0 || roadLength === 0) {
                                    return null; // Überspringen des Eintrags
                                }
                                return {
                                    road_name: accItem.road_name,
                                    unfallrate: (accidents / totalTraffic),
                                };
                            })
                            .filter(item => item !== null); // Filtern der übersprungenen Einträge
                        console.log(mergedData);
                        const newBox = cartrafficAccidents(mergedData, city, start, end, timeDimension, topic, overallTopic);
                        updateBoxInBoxes(newBox, boxIndex);
                    })
                    .catch((error) => {
                        errorHandling(error);
                    });
            } else if (city === "osnabrueck") {
                Promise.all([
                    ApiConnect.fetchAccidentsCountStreet(city, start, end),
                    ApiConnect.fetchCarStationsOsnaNoGeo(start, end),
                    ApiConnect.fetchRoaddata()
                ])
                    .then(([acc, carstations, roaddata]) => {
                        // Umwandeln der carstations-Daten in ein Objekt zur einfachen Suche
                        const carData = carstations.reduce((acc, station) => {
                            acc[station.location] = station;
                            return acc;
                        }, {});

                        // Umwandeln der roaddata-Daten in ein Objekt zur einfachen Suche
                        const roadData = roaddata.reduce((acc, road) => {
                            acc[road.road_name] = road;
                            return acc;
                        }, {});

                        // Berechnung der Anzahl der Tage zwischen Start und Ende
                        const startDate = new Date(start);
                        const endDate = new Date(end);
                        const timeDiff = Math.abs(endDate - startDate);
                        const numDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // +1 um den letzten Tag einzuschließen

                        const mergedData = acc
                            .filter(accItem => carData[accItem.road_name])  // Nur Straßen berücksichtigen, die in beiden Fetches vorkommen
                            .map(accItem => {
                                const carStation = carData[accItem.road_name];
                                const roadInfo = roadData[accItem.road_name];

                                if (!roadInfo) {
                                    return null; // Überspringen des Eintrags, wenn roadInfo undefined ist
                                }
                                let totalTraffic;
                                let roadLength = roadInfo.length; // Länge der Straße
                                let accidents;
                                accidents = accItem.count_ * 1000000;
                                const multiplier = Math.ceil(numDays / 365);
                                totalTraffic = (carStation.sum_traffic_volume / numDays) * roadLength * 365 * multiplier;

                                // Wenn totalTraffic 0 ist, überspringen wir den Eintrag
                                if (totalTraffic === 0 || roadLength === 0) {
                                    return null; // Überspringen des Eintrags
                                }
                                return {
                                    road_name: accItem.road_name,
                                    unfallrate: (accidents / totalTraffic),
                                };
                            })
                            .filter(item => item !== null); // Filtern der übersprungenen Einträge
                        console.log(mergedData);
                        const newBox = cartrafficAccidents(mergedData, city, start, end, timeDimension, topic, overallTopic);
                        updateBoxInBoxes(newBox, boxIndex);
                    })
                    .catch((error) => {
                        errorHandling(error);
                    });
            }
            break;

        case "accidents-count-per-street-length":
            Promise.all([
                ApiConnect.fetchAccidentsCountStreet(city, start, end),
                ApiConnect.fetchRoaddata()
            ])
                .then(([acc, road]) => {
                    const mergedData = acc.map(accItem => {
                        const roadItem = road.find(r => r.road_name === accItem.road_name);
                        return {
                            road_name: accItem.road_name,
                            count_: accItem.count_,
                            length: roadItem ? roadItem.length : null // or any default value if roadItem is not found
                        };
                    });

                    const newBox = streetCountAccidentsTop10LengthSensitive(mergedData, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                })
                .catch((error) => {
                    errorHandling(error);
                });
            break;

        case "concerns-time":
            ApiConnect.fetchConcernsTime(city, timeDimension, [start, end])
                .then((data) => {
                    const newBox = concernsTime(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "concerns-duration":
            ApiConnect.fetchConcernsDuration(city, [start, end])
                .then((data) => {
                    const newBox = concernsDuration(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "concerns-type":
            ApiConnect.fetchConcernsType(city, [start, end])
                .then((data) => {
                    const newBox = concernsType(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "concerns-type-problem":
            ApiConnect.fetchConcernsTypeProblem(city, [start, end])
                .then((data) => {
                    const newBox = concernsTypeProblem(data, city, start, end, timeDimension, topic, overallTopic);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                    errorHandling(error);
                });
            break;

        case "rides-weather":
            Promise.all([
                ApiConnect.fetchWeatherCondition(city, [start, end]),
                ApiConnect.fetchRidesConditions(city, [start, end])
            ])
                .then(([data1, data2]) => {
                    const newBox = ridesCount(data1, data2, city, start, end, timeDimension, topic, overallTopic, "weather");
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "rides-temperature":
            Promise.all([
                ApiConnect.fetchTemperatureCondition(city, [start, end]),
                ApiConnect.fetchRidesTemperatures(city, [start, end])
            ])
                .then(([data1, data2]) => {
                    const newBox = ridesCount(data1, data2, city, start, end, timeDimension, topic, overallTopic, "temperature");
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "rides-weather-length":
        case "rides-weather-speed":
            ApiConnect.fetchRidesConditions(city, [start, end])
                .then((data) => {
                    const newBox = ridesCondition(data, city, start, end, timeDimension, topic, overallTopic, topic.split('-')[2], topic.split('-')[1]);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        case "rides-temperature-length":
        case "rides-temperature-speed":
            ApiConnect.fetchRidesTemperatures(city, [start, end])
                .then((data) => {
                    const newBox = ridesCondition(data, city, start, end, timeDimension, topic, overallTopic, topic.split('-')[2], topic.split('-')[1]);
                    updateBoxInBoxes(newBox, boxIndex);
                }).catch((error) => {
                errorHandling(error);
            });
            break;

        default:
            break;
    }
}

export async function handleTemplateSubmit(event, city, overallTopic, start, end, roads, addBoxes) {
    event.preventDefault();

    if (overallTopic === "" || start === "" || end === "" || city === "") {
        alert("Bitte fehlende Angaben ergänzen!");
        return;
    }

    switch (overallTopic) {
        case ("standard"):
            await getStandard(city, start, end, addBoxes);
            break;
        case ("bicycle-volume"):
            await getBicycleVolume(city, start, end, roads, addBoxes);
            break;
        case ("accidents"):
            await getAccidents(city, start, end, addBoxes);
            break;
        case ("concerns"):
            await getConcerns(city, start, end, addBoxes);
            break;
        case ("rides"):
            await getRides(city, start, end, addBoxes);
            break;
        default:
            alert("Ausgabe nicht möglich");
            break;
    }
}