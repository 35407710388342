import React, { useEffect } from 'react';
import { Modal, Form, Row, Col, Button, Tabs, Tab, Accordion } from 'react-bootstrap';

// Komponente für das Auswahlmodal der Daten
const DatenAuswahlModal = ({
  show, // Steuert die Sichtbarkeit des Modals
  onHide, // Funktion zum Schließen des Modals
  handleExport, // Funktion zum Exportieren der Daten
  dataSource, // Aktuelle Datenquelle bzw. sichtbares Layer
  setDataSource, // Funktion zum Setzen der Datenquelle
  selectedRSCategories, // Ausgewählte Kategorien
  setselectedRSCategories, // Funktion zum Setzen der ausgewählten Kategorien
  selectedCombinations, // Ausgewählte Kombinationen von Kategorien und Rängen
  setSelectedCombinations // Funktion zum Setzen der ausgewählten Kombinationen
}) => {

  // Definieren der verfügbaren Kategorien
  const categories = [
    "absolute_speed",
    "anomaly",
    "bremsrate",
    "durchfahrten",
    "erschuetterung_v1",
    "erschuetterung_v2",
    "full_brakings",
    "normalized_speed",
    "time_loss",
    "waiting_time"
  ];

  // Definieren der Ränge
  const ranks = ["A", "B", "C", "D", "E", "F"];

  // Initialisieren mit allen Kombinationen ausgewählt
  const initialSelectedCombinations = categories.reduce((acc, category) => {
    acc[category] = [...ranks]; // Alle Ränge standardmäßig ausgewählt
    return acc;
  }, {});

  // Effekt, um die ausgewählten Kombinationen beim ersten Rendern zu setzen
  useEffect(() => {
    setSelectedCombinations(initialSelectedCombinations);
  }, []);

  // Definieren der Qualitätsmetriken
  const qualityMetrics = [
    "daily_traffic",
    "disruptions",
    "time_loss",
    "v_score_v1",
    "v_score_v2",
    "accidents",
    "absolute_speed",
    "normalized_speed",
    "full_brakings",
    "waiting_time",
    "frequency"
  ];

  // Definieren der infrastrukturellen Metriken
  const infrastructureMetrics = [
    "slope",
    "path_type",
    "surface",
    "width"
  ];

  // Kategorisierung nach Datenquelle
  const categoriesByDataSource = {
    connected: ["Alles"],
    road: ["daily_traffic", "disruptions", "time_loss", "v_score_v1", "v_score_v2", "accidents"],
    heatmap: ["absolute_speed", "normalized_speed", "full_brakings", "waiting_time", "frequency"],
    fastapi: ["slope", "path_type"],
    osm: ["Alles"],
    imported: ["Alles"]
  };

  // Übersetzungen der Datenquellen
  const dataSourceTranslations = {
    road: "Verknüpfte Segmente",
    heatmap: "Qualitätsdaten Heatmap",
    fastapi: "Infrastruktur",
    osm: "OSM",
    imported: "Importiert"
  };

  // Übersetzungen der Kategorien
  const categoryTranslations = {
    absolute_speed: "Geschwindigkeit (absolut)",
    normalized_speed: "Geschwindigkeit (normalisiert)",
    anomaly: "Schwere Erschütterungen",
    bremsrate: "Bremsrate",
    full_brakings: "Vollbremsungen (absolut)",
    durchfahrten: "Durchfahrten",
    time_loss: "Zeitverlust",
    waiting_time: "Wartezeit",
    erschuetterung_v1: "Erschütterung V1",
    erschuetterung_v2: "Erschütterung V2"
  };

  // Funktion zur Handhabung der Änderung der Datenquelle
  const handleDataSourceChange = (event) => {
    const newDataSource = event.target.value;
    setDataSource(newDataSource);
    // Zurücksetzen der ausgewählten Kategorien, wenn sich die Datenquelle ändert
    setselectedRSCategories([]);
  };

  // Funktion zur Handhabung der Änderung der Kategoriewahl
  const handleCategorySelectionChange = (category) => {
    console.log(selectedRSCategories);
    setselectedRSCategories(prevCategories => {
      if (prevCategories.includes(category)) {
        return prevCategories.filter(cat => cat !== category); // Kategorie abwählen
      } else {
        return [...prevCategories, category]; // Kategorie auswählen
      }
    });
    console.log(selectedRSCategories);
  };

  // Funktion zur Handhabung der Änderung der Rangwahl
  const handleRankChange = (category, rank) => {
    setSelectedCombinations(prevCombinations => {
      const categoryRanks = prevCombinations[category] || [];
      if (categoryRanks.includes(rank)) {
        return {
          ...prevCombinations,
          [category]: categoryRanks.filter(r => r !== rank) // Rang abwählen
        };
      } else {
        return {
          ...prevCombinations,
          [category]: [...categoryRanks, rank] // Rang auswählen
        };
      }
    });
  };

  // Funktion zum Abwählen aller Ränge
  const handleUncheckAll = () => {
    setSelectedCombinations({});
  };

  // Funktion zum Auswählen aller Ränge für eine bestimmte Kategorie
  const handleSelectAll = (category) => {
    setSelectedCombinations(prev => ({
      ...prev,
      [category]: ranks // Alle Ränge für diese Kategorie auswählen
    }));
  };

  return (
    <Modal
      show={show} // Sichtbarkeit des Modals
      onHide={onHide} // Funktion zum Schließen des Modals
      size="xl" // Größe des Modals
      scrollable // Scrollbar aktivieren
      animation // Animation aktivieren
      autoFocus // Autofokus aktivieren
      className="modal-report-form" // CSS-Klasse für das Modal
    >
      <Modal.Header closeButton>
        <Modal.Title>Darstellung wählen</Modal.Title> {/* Titel des Modals */}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="dataSourceSelect">
            <Form.Label className="filtertitleElement">Datenquelle wählen:</Form.Label>
            <Form.Control as="select" value={dataSource} onChange={handleDataSourceChange}>
              {Object.keys(dataSourceTranslations).map(source => (
                <option key={source} value={source}>{dataSourceTranslations[source]}</option>
              ))}
            </Form.Control>
          </Form.Group>
          {dataSource === "road" && (
            <Accordion defaultActiveKey="0" className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Wählen Sie die Kategorie-Rang-Kombinationen </Accordion.Header>
                <Accordion.Body>
                  <Button onClick={handleUncheckAll} className="w-100">
                    Alle Abwählen
                  </Button>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Kategorie</th>
                        <th>Ganze Reihe</th>
                        {ranks.map(rank => (
                          <th key={rank}>Rang {rank}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map(category => (
                        <tr key={category}>
                          <td>
                            <strong>{categoryTranslations[category]}</strong>
                          </td>
                          <td className='center'>
                            <Button
                              variant="primary"
                              onClick={() => handleSelectAll(category)}
                            >
                              Alle
                            </Button>
                          </td>
                          {ranks.map(rank => (
                            <td key={`${category}-${rank}`} className='center'>
                              <Form.Check
                                type="checkbox"
                                className="segmentfiltercheck"
                                label=""
                                checked={selectedCombinations[category]?.includes(rank) || false}
                                onChange={() => handleRankChange(category, rank)}
                              />
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          {dataSource === "connected" && (
            <>
              <Accordion defaultActiveKey="0" className="mt-3">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Wählen Sie die Qualitätsmetriken</Accordion.Header>
                  <Accordion.Body>
                    {qualityMetrics.map(metric => (
                      <Form.Check
                        key={metric}
                        type="checkbox"
                        className="segmentfiltercheck"
                        label={metric}
                        checked={selectedRSCategories.includes(metric)}
                        onChange={() => handleCategorySelectionChange(metric)}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Wählen Sie die Infrastrukturellen Metriken</Accordion.Header>
                  <Accordion.Body>
                    {infrastructureMetrics.map(metric => (
                      <Form.Check
                        key={metric}
                        type="checkbox"
                        className="segmentfiltercheck"
                        label={metric}
                        checked={selectedRSCategories.includes(metric)}
                        onChange={() => handleCategorySelectionChange(metric)}
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )}

          {dataSource !== "connected" || dataSource !== "road" && (
            <Accordion defaultActiveKey="0" className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Wählen Sie die Kategorien</Accordion.Header>
                <Accordion.Body>
                  {categoriesByDataSource[dataSource].map(category => (
                    <Form.Check
                      key={category}
                      type="checkbox"
                      className="segmentfiltercheck"
                      label={category}
                      checked={selectedRSCategories.includes(category)}
                      onChange={() => handleCategorySelectionChange(category)}
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100">
          <Col lg={6} className="d-flex justify-content-center">
            <Button className="filterSegBtn" onClick={onHide}>Abbrechen</Button> {/* Abbrechen-Button */}
          </Col>
          <Col lg={6} className="d-flex justify-content-center">
            <Button className="filterSegBtn" onClick={onHide}>Daten laden</Button> {/* Daten laden-Button */}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DatenAuswahlModal; // Export der Komponente