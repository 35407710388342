export const filterOptions = [
    { label: "Alkoholeinfluss", value: "alcohol" },
    { label: "Drogeneinfluss", value: "drugs" },
    { label: "Frost", value: "frost" }
];

export const filterMoneyDamage = [
    { label: "Kein Schaden (0€)", valueFrom: 0, valueTo: 0 },
    { label: "Kleiner Schaden (1-100€)", valueFrom: 1, valueTo: 100 },
    { label: "Mittlerer Schaden (101-500€)", valueFrom: 101, valueTo: 500 },
    { label: "Großer Schaden (501-5000€)", valueFrom: 501, valueTo: 5000 },
    { label: "Sehr großer Schaden (>= 5001€)", valueFrom: 5001, valueTo: 5000000 }
];

export const nodeConditionsOptions = ["V1", "V2", "TimeLoss", "Anomaly"];

export const nodeConditionsTranslations = {
    V1: "Erschütterungen",
    V2: "Erschütterungen unter Berücksichtigung der Geschwindigkeit",
    TimeLoss: "Zeitverlust",
    Anomaly: "Anomalien"
};

export const roadColorConditionsOptions = ["AnomalieV1", "AnomalieV2", "FullBrakings", "AbsoluteSpeedRoad",
    "NormSpeedRoad", "V1Road", "V2Road", "FrequencyRoad", "WaitingTimeRoad"];

export const roadColorConditionsTranslations = {
    V1Road: "Erschütterungen",
    V2Road: "Erschütterungen unter Berücksichtigung der Geschwindigkeit",
    AnomalieV1: "Anomalien",
    AnomalieV2: "Anomalien unter Berücksichtigung der Geschwindigkeit",
    FullBrakings: "Vollbremsungen",
    AbsoluteSpeedRoad: "Absolute Geschwindigkeit",
    NormSpeedRoad: "Normalisierte Geschwindigkeit",
    FrequencyRoad: "Verkehrsstärke",
    WaitingTimeRoad: "Wartezeiten"
};

export const roadSegmentsConditionsOptions = ["V1Seg", "V2Seg", "TimeLossSeg", "AnomalieSeg"];

export const roadSegmentsConditionsTranslations = {
    V1Seg: "Erschütterungen",
    V2Seg: "Erschütterungen unter Berücksichtigung der Geschwindigkeit",
    TimeLossSeg: "Zeitverlust",
    AnomalieSeg: "Anomalien",
};

export const scoreOptions = ["Score", "Unfälle", "Meldungen", "Verkehrsaufkommen", "Zeitverlust", "Erschütterungen",
    "Anomalie"];

export const accumulationOptions = ["Unfälle","Unfallrate","Meldungen","Schadensmeldungen","Verkehrsaufkommen","Geschwindigkeit","Unfälle per Knoten" ];

export const perpetratorOptions = ["Auto", "Bus", "E-Bike", "Fußgänger", "Fahrrad", "Motorrad", "LKW", "Sonstiges"];

export const accTypeOptions = ["Unfall im Längsverkehr", "Sonstiger Unfall", "Unfall durch ruhenden Verkehr",
    "Einbiegen/Kreuzen-Unfall", "Fahrunfall", "Abbiege-Unfall", "Überschreiten-Unfall"];

export const roadConditionsOptions = ["wet", "dry", "icy", "slick"];

export const roadConditionsTranslations = {
    wet: "nass",
    dry: "trocken",
    icy: "vereist",
    slick: "glatt"
};


export const filterWeatherOptions = ["Partially cloudy", "Overcast", "Snow", "Clear", "Rain"];

export const weatherConditionsTranslations = {
    "Partially cloudy": "Leicht bewölkt",
    Overcast: "Wolkig",
    Snow: "Schnee",
    Clear: "Klarer Himmel",
    Rain: "Regen"
};

export const filterConcernsOptions = [
    { keyword: "Alle", values: ["Alle"] },
    { keyword: "Strassen", values: ["Straßen", "Straßen/Gehwege"] },
    { keyword: "Müll", values: ["Wilde Müllkippe", "Wilde Müllablagerung", "Müllkippe", "Müllabfuhr", "Papierkörbe"] },
    { keyword: "Danke", values: ["Danke sagen"] },
    { keyword: "Verkehrszeichen", values: ["Verkehrszeichen", "Beschilderung"] },
    { keyword: "Eichenprozessionsspinner", values: ["Eichenprozessionsspinner"] },
    { keyword: "Strassenbeleuchtung", values: ["Straßenbeleuchtung"] },
    { keyword: "Spielplaetze", values: ["Spielplätze", "Spiel- und Bolzplätze"] },
    { keyword: "Radwege", values: ["Radwege"] },
    { keyword: "Ampel", values: ["Ampel", "Ampeln"] },
    { keyword: "Fahrbahnmarkierungen", values: ["Fahrbahnmarkierungen/Verkehrsschilder"] },
    { keyword: "Kanalisation", values: ["Kanalisation"] },
    { keyword: "Hunde", values: ["Hunde"] },
    { keyword: "Bäume", values: ["Bäume", "Grün an Straßen", "Grünflächen", "Grün- und Parkanlagen"] }
];

export const filterInfrastructureOptions = [
    { keyword: "Slope", value: "Steigung" },
    { keyword: "PathType", value: "Führungsform" },
    { keyword: "SurfaceType", value: "Öberflächenart" },
    { keyword: "Width", value: "Radwegbreite" }
];
