import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Polyline, Tooltip, useMapEvents } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { Container, Modal, Button, Form, Offcanvas, Table, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";
import proj4 from 'proj4';

import AddressSearchBar from "./AddressSearchBar";
import ApiConnection from "../dashboard/api/ApiConnection";
import "../../styles/Infrastructure.css"
import { translationOsmValues } from "../dashboard/functions/Translations";
import * as XLSX from 'xlsx';

export const ApiConnect = new ApiConnection();

const Testpage = () => {
  const [segment, setSegments] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [mapView, setMapView] = useState('standard');
  const [center, setCenter] = useState([53.1435, 8.2146]);
  const mapRef = useRef(null);
  const [mapBounds, setMapBounds] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);


  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const [fuhrungsformValue, setFuhrungsformValue] = useState("");
  const [oberflaecheValue, setOberflaecheValue] = useState("");
  const [tempolimitValue, setTempolimitValue] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [entries, setEntries] = useState([]);

  const [multiSelectMode, setMultiSelectMode] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState(new Set());

  //Main
  // Segmente wechseln 
  const [dataSource, setDataSource] = useState('overpass');

  const handleLoadSegments = (source) => {
    setDataSource(source);
    if (source === 'overpass') {
      fetchSegmentsFromOverpass();
    } else if (source === 'fastapi') {
      fetchSegmentsFromFastAPI();
    } else if (source === 'imported') {
      if (!geojsonData) {
        alert("GeoJson-File fehlt!")
      } else {
        console.log("Import geladen");
      }

    }
  };

 //integriert in jede Komponente, di es benötigt
  // Übersetzungsfunktionsreferenz
  const translations = translationOsmValues();

  const translateValue = (category, value) => {
    if (!value || value === 'keine Angabe') return 'keine Angabe';
    return translations[category] && translations[category][value] ? translations[category][value] : value;
  };


   //integriert in jede Komponente, di es benötigt
  // Funktion, um die aktuellen Grenzen der Karte zu ermitteln
  const updateMapBounds = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      console.log("Aktuelle Karten-Grenzen:", bounds);
    }
  };

  //Main
  // Kartenereignisse, um die Grenzen bei Bewegung oder Zoom zu aktualisieren
  const MapEvents = () => {
    useMapEvents({
      moveend: updateMapBounds,
      zoomend: updateMapBounds,
    });
    return null;
  };

  //Main
  // Funktion, die aufgerufen wird, wenn die Karte bereit ist
  const handleMapReady = (event) => {
    const mapInstance = event.target;
    mapRef.current = mapInstance;
    updateMapBounds(); // Initiale Grenzen ermitteln
  };

  //Main
  // AddressSearchBar Funktionen
  const [key, setKey] = useState(0); // Zustand für den key

  const handleAddressSelected = (address) => {
    const { lat, lon } = address;
    console.log('Neue Adresse ausgewählt:', address);
    console.log('Center auf Lat:', lat, 'Lng:', lon, 'angepasst');
    setCenter(
      [lat, lon]
    );
    setKey((prevKey) => prevKey + 1); // Aktualisiere den key 
  };

  const handleZoomToAddress = (coordinates, zoomLevel) => {
    setZoom(zoomLevel);
  };

  //Main
  // Mapwechsel
  const handleMapTypeChange = (newMapView) => {
    const newZoom = newMapView === 'standard' ? 13 : 10;

    setMapView(newMapView);
    setZoom(newZoom);
  };

  //in Komponente FastApiSegment.js auslagern
  //get segment data from fast api--------------------------------------------------------------------------------------
  const [fastApi, setFastAPi] = useState([]);

  const fetchSegmentsFromFastAPI = () => {
    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;
      ApiConnect.fetchGeometryRoad(latMax, lngMax, latMin, lngMin)
      .then((data) => {
          const newData = {};

          if (data !== null) {
              data.forEach(item => {
                  const { geometry_id, slope, path_type, geometry, ...rest } = item;

                  if (slope !== null || path_type !== null) {

                      let coordinates = geometry.coordinates;
                      coordinates.forEach(function (value, i) {
                          coordinates[i] = [value[1], value[0]];
                      });
                      geometry.coordinates = coordinates;

                      newData[geometry_id] = { ...(newData[geometry_id] || {}), slope, path_type, geometry, ...rest }
                  }
              });
          }
          setFastAPi(newData);
          console.log("Segmente geladen:", fastApi);
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Segmente:", error);
        });
    } else {
      console.error("Kartengrenzen sind nicht verfügbar.");
    }
  };
  
  //in Komponente OsmSegment.js auslagern
  // Overpass query--------------------------------------------------------------------------------------
  const fetchSegmentsFromOverpass = () => {
    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;

      const overpassQuery = `
        [out:json][timeout:25];
        (
          way["highway"](${latMin},${lngMin},${latMax},${lngMax});
        );
        out tags geom;
      `;

      const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(overpassQuery)}`;

      fetch(overpassUrl)
        .then(response => response.json())
        .then(data => {
          const segments = data.elements.map(element => ({
            segment_id: element.id,
            name: element.tags.name || 'Name fehlt',
            geometry: element.geometry.map(coordinates => [coordinates.lat, coordinates.lon]),
            highway: element.tags.highway || 'keine Angabe',
            lit: element.tags.lit || 'keine Angabe',
            maxspeed: element.tags.maxspeed || 'keine Angabe',
            parking_lane_left: element.tags['parking:lane:left'] || 'keine Angabe',
            parking_lane_left_parallel: element.tags['parking:lane:left:parallel'] || 'keine Angabe',
            parking_lane_right: element.tags['parking:lane:right'] || 'keine Angabe',
            sidewalk: element.tags.sidewalk || 'keine Angabe',
            sidewalk_both_surface: element.tags['sidewalk:both:surface'] || 'keine Angabe',
            source_maxspeed: element.tags['source:maxspeed'] || 'keine Angabe',
            surface: element.tags.surface || 'keine Angabe',
            cycleway_surface: element.tags['cycleway:surface'] || 'keine Angabe',
            cycleway_width: element.tags['cycleway:width'] || 'keine Angabe',
            traffic_sign: element.tags.traffic_sign || 'keine Angabe',
            width: element.tags.width || 'keine Angabe',
            bicycle_road: element.tags.bicycle_road || 'keine Angabe',
            oneway: element.tags.oneway || 'keine Angabe',
            rcn: element.tags.rcn || 'keine Angabe'
          }));

          setSegments(segments);
          console.log("Segmente geladen:", segments);
        })
        .catch(error => {
          console.error("Fehler beim Laden der Segmente:", error);
        });
    } else {
      console.error("Kartengrenzen sind nicht verfügbar.");
    }
  };


//in Komponente ImportSegment.js auslagern
  //geojson import--------------------------------------------------------------------------------------
  const [geojsonData, setGeojsonData] = useState([]);
  const [importModalOpen, setImportModalOpen] = useState(false);

  proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");
  proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        try {
          const parsedData = JSON.parse(content);
          if (parsedData.type === "FeatureCollection" && Array.isArray(parsedData.features)) {
            setGeojsonData(parsedData.features);
          } else {
            console.error("Invalid GeoJSON format");
          }
        } catch (error) {
          console.error("Error parsing GeoJSON:", error);
        }
      };
      reader.readAsText(file);
    } else {
      console.error("No file selected.");
    }
  };

  const inputProjection = 'EPSG:25832';
  const outputProjection = 'EPSG:4326';

  const convertCoordinates = (coordinates) => {
    return coordinates.map(coord => {
      if (typeof coord[0] !== 'number' || typeof coord[1] !== 'number') {
        console.error("Invalid coordinate:", coord);
        return null;
      }
      const [lng, lat] = proj4(inputProjection, outputProjection, [coord[0], coord[1]]);
      return [lat, lng];
    });
  };



  useEffect(() => {
    console.log("GeoJSON data updated:", geojsonData);
    console.log("Current dataSource:", dataSource);
    console.log("GeoJSON data length:", geojsonData.length);
  }, [geojsonData]);


  
   //integriert in jede Komponente, di es benötigt
  //polyline event handling--------------------------------------------------------------------------------------
  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };


//Nav-Kompenente
  const toggleMultiSelectMode = () => {
    setMultiSelectMode((prevMode) => {
      if (prevMode) {
        setSelectedSegments(new Set());
      }
      return !prevMode;
    });
  };


  //Main
  //offcanvas Nav+entries--------------------------------------------------------------------------------------
  const [navShow, setNavShow] = useState(false);
  const handleNavClose = () => setNavShow(false);
  const handleNavShow = () => setNavShow(true);




//Tabellen-Komponente
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const handleDeleteEntry = (segmentId) => {
    setEntries(entries.filter(entry => entry.segment_id !== segmentId));
  };

  const handleEditEntry = (entry) => {
    setSelectedSegmentId(entry.segment_id);
    setSegmentName(entry.name);
    setFuhrungsformValue(entry.fuhrungsform);
    setOberflaecheValue(entry.oberflaeche);
    setTempolimitValue(entry.tempolimit);
    setModalOpen(true);
  };

  //Tabellen-Komponente
  // Update entries --------------------------------------------------------------------------------------
  useEffect(() => {
    if (showOffcanvas) {
      console.log("Offcanvas geöffnet:", entries);
    }
  }, [showOffcanvas, entries]);


  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedSegmentId(null);
    setSegmentName("");
    setFuhrungsformValue("");
    setOberflaecheValue("");
    setTempolimitValue("");
    setSelectedSegments(new Set());
  };

  //Filter-Komponente
  //Filterfunktionen---------------------------------------------------------------------

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterRules, setFilterRules] = useState([]);
  const [activeRuleIndex, setActiveRuleIndex] = useState(null);
  const [filters, setFilters] = useState({
    highway: '',
    bicycle: '',
    foot: '',
    surface: '',
    lit: '',
    traffic_sign: '',
    maxspeed: '',
    fuhrungsform: '',
    oberflaeche: '',
    tempolimit: '',
  });

  const translateCategory = (category) => {
    const categoryTranslations = {
      highway: "Wegtyp",
      surface: "Oberfläche",
      cycleway: "Führungsform",
    };
    return categoryTranslations[category] || category;
  };

  const addFilterRule = () => {
    setFilterRules([...filterRules, { category: '', selectedEntries: [] }]);
    setActiveRuleIndex(filterRules.length);
  };

  const handleCategoryChange = (index, value) => {
    const newRules = [...filterRules];
    newRules[index].category = value;
    newRules[index].selectedEntries = [];
    setFilterRules(newRules);
  };

  const handleEntryChange = (index, entry) => {
    const newRules = [...filterRules];
    const selectedEntries = newRules[index].selectedEntries;

    if (selectedEntries.includes(entry)) {
      newRules[index].selectedEntries = selectedEntries.filter(e => e !== entry);
    } else {
      newRules[index].selectedEntries.push(entry);
    }

    setFilterRules(newRules);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };

  const applyFilters = () => {
    const newFilters = { ...filters };
    filterRules.forEach(rule => {
      if (rule.category) {
        newFilters[rule.category] = rule.selectedEntries.join(', ');
      }
    });
    setFilters(newFilters);
    console.log('Applied Filters:', newFilters);
    handleFilterModalClose();
  };


  const matchesFilter = (entry) => {
    for (const category in filters) {
      if (filters[category]) {
        const filterValues = filters[category].split(', ').map(value => value.trim());
        console.log(`Checking category: ${category}, Entry value: ${entry[category]}, Filter values: ${filterValues}`);
        if (!filterValues.includes(entry[category])) {
          return false;
        }
      }
    }
    return true;
  };

  const handleFilterApproval = () => {
    setActiveRuleIndex(null);
  };

  const resetCategory = (index) => {
    const newRules = filterRules.filter((_, i) => i !== index);
    setFilterRules(newRules);

    const categoryToRemove = filterRules[index].category;
    if (categoryToRemove) {
      const newFilters = { ...filters };
      delete newFilters[categoryToRemove];
      setFilters(newFilters);
    }

    if (activeRuleIndex === index) {
      setActiveRuleIndex(null);
    }
  };

  //SegmenteEdit-Komponente
  //Segmente für osm und fastApi aktualisieren---------------------------------------------------------------------

  const handleFormSubmit = () => {
    if (multiSelectMode) {
      const newEntries = Array.from(selectedSegments).map(id => {
        const seg = segment.find(seg => seg.segment_id === id);
        return {
          segment_id: id,
          fuhrungsform: fuhrungsformValue,
          oberflaeche: oberflaecheValue,
          tempolimit: tempolimitValue,
          geometry: seg ? seg.geometry : null,
          bicycle: seg ? seg.bicycle || 'keine Angabe' : 'keine Angabe',
          cycleway: seg ? seg.cycleway || 'keine Angabe' : 'keine Angabe',
          highway: seg ? seg.highway || 'keine Angabe' : 'keine Angabe',
          lit: seg ? seg.lit || 'keine Angabe' : 'keine Angabe',
          maxspeed: seg ? seg.maxspeed || 'keine Angabe' : 'keine Angabe',
          name: seg ? seg.name || 'keine Angabe' : 'keine Angabe',
          sidewalk: seg ? seg.sidewalk || 'keine Angabe' : 'keine Angabe',
          surface: seg ? seg.surface || 'keine Angabe' : 'keine Angabe',
        };
      });
      setEntries(prevEntries => [...prevEntries, ...newEntries]);
    } else {
      const seg = segment.find(seg => seg.segment_id === selectedSegmentId);
      const newEntry = {
        segment_id: selectedSegmentId,
        fuhrungsform: fuhrungsformValue,
        oberflaeche: oberflaecheValue,
        tempolimit: tempolimitValue,
        geometry: seg ? seg.geometry : null,
        bicycle: seg ? seg.bicycle || 'keine Angabe' : 'keine Angabe',
        cycleway: seg ? seg.cycleway || 'keine Angabe' : 'keine Angabe',
        highway: seg ? seg.highway || 'keine Angabe' : 'keine Angabe',
        lit: seg ? seg.lit || 'keine Angabe' : 'keine Angabe',
        maxspeed: seg ? seg.maxspeed || 'keine Angabe' : 'keine Angabe',
        name: seg ? seg.name || 'keine Angabe' : 'keine Angabe',
        sidewalk: seg ? seg.sidewalk || 'keine Angabe' : 'keine Angabe',
        surface: seg ? seg.surface || 'keine Angabe' : 'keine Angabe',

      };
      setEntries(prevEntries => {
        const updatedEntries = prevEntries.filter(entry => entry.segment_id !== selectedSegmentId);
        return [...updatedEntries, newEntry];
      });
    }
    console.log("Einträge:", entries);
    handleModalClose();
  };

  //Export-Komponente
  // Excel Export Functions
  const [exportModalOpen, setExportModalOpen] = useState(false);


  const exportNewEntries = () => {
    if (entries.length === 0) {
      console.warn("Keine neuen Einträge.");
      return;
    }
    const formattedEntries = entries.map(entry => ({
      ...entry,
      geometry: JSON.stringify(entry.geometry)
    }));
    console.log("Exportierte neue Einträge:", formattedEntries);
    const worksheet = XLSX.utils.json_to_sheet(formattedEntries);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Neue Einträge");
    XLSX.writeFile(workbook, "neue_eintraege.xlsx");
  };

  const exportAllSegments = () => {
    if (segment.length === 0) {
      console.warn("Keine Segmente.");
      return;
    }
    const formattedSegments = segment.map(seg => ({
      ...seg,
      geometry: JSON.stringify(seg.geometry)
    }));
    console.log("Exportierte Segmente:", formattedSegments);
    const worksheet = XLSX.utils.json_to_sheet(formattedSegments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alle Segmente");
    XLSX.writeFile(workbook, "alle_segmente.xlsx");
  };

  const exportFilteredSegments = () => {
    const filteredSegments = segment.filter(matchesFilter);
    if (filteredSegments.length === 0) {
      console.warn("Keine gefilterten Segmente.");
      return;
    }
    const formattedFilteredSegments = filteredSegments.map(seg => ({
      ...seg,
      geometry: JSON.stringify(seg.geometry)
    }));
    console.log("Exportierte gefilterte Segmente:", formattedFilteredSegments);
    const worksheet = XLSX.utils.json_to_sheet(formattedFilteredSegments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Gefilterte Segmente");
    XLSX.writeFile(workbook, "gefilterte_segmente.xlsx");
  };


  //Main
  return (
    <Container fluid className="map-container">
      <MapContainer
        key={key}
        center={center}
        zoom={zoom}
        whenReady={handleMapReady}
        style={{ height: "100%", width: "100%" }}
      >
        {mapView === 'standard' && (
          <TileLayer
            attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors"
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
            subdomains={['a', 'b', 'c', 'd']}
          />
        )}
        {mapView === 'satellite' && (
          <TileLayer
            attribution="&copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        )}
        <div className="flexMapTest row">
          <AddressSearchBar
            className="address-search address-search-wrapper col"
            onAddressSelected={handleAddressSelected}
            onZoomToAddress={handleZoomToAddress}
          />



          {/* Kartenstil wechseln */}
          <div className="map-type-toggle dissapear col">
            <button
              className={mapView === 'standard' ? 'active' : ''}
              onClick={() => handleMapTypeChange('standard')}
            >
              Standardmap
            </button>
            <button
              className={mapView === 'satellite' ? 'active' : ''}
              onClick={() => handleMapTypeChange('satellite')}
            >
              Satellitenmap
            </button>
          </div>
        </div>


        {/* ImportSegment.js 
        Render GeoJSON Data */}
        {dataSource === 'imported' && geojsonData.map((feature) => {
          if (feature.geometry) {
            if (feature.geometry.type === "MultiLineString") {
              return feature.geometry.coordinates.map((line, lineIndex) => (
                <Polyline
                  key={`geojson-${feature.osm_id}-${lineIndex}`}
                  positions={convertCoordinates(line)}
                  weight={3}
                  fill={false}
                  opacity={1}
                  color="black"
                >
                  <Tooltip>
                    {feature.properties.display_name || "No Name"}
                  </Tooltip>
                </Polyline>
              ));
            }

            // Handle LineString
            if (feature.geometry.type === "LineString") {
              return (
                <Polyline
                  key={`geojson-${feature.osm_id}`}
                  positions={feature.geometry.coordinates.map(coord => [coord[1], coord[0]])} 
                  weight={3}
                  fill={false}
                  opacity={1}
                  color="black"
                >
                  <Tooltip>
                    {feature.properties.display_name || "No Name"}
                  </Tooltip>
                </Polyline>
              );
            }
          }
          return null; 
        })}

 
   {/* FastApiSegment.js
   Fast API -------------------------------------------------------------------------------------------------------*/}
        {dataSource === 'fastapi' && Object.keys(fastApi).map((i) => {
          const road = fastApi[i];

          const isFiltered = matchesFilter(road);
          const isSelected = selectedSegments.has(road.segment_id);

          if (road.path_type !== undefined && road.slope !== undefined) {
            return (
              <React.Fragment key={road.segment_id}>
                <Polyline
                key={`${road.segment_id}-white`}
                positions={road.geometry.coordinates}
                color="white"
                weight={8}
                fill={false}
                opacity={1}
              />
                <Polyline 
                key={i} 
                positions={road.geometry.coordinates} 
                className="hoversegment"
                  color={isSelected ? "orange" : (isFiltered ? "black" : "blue")}
                  weight={6}
                  fill={false}
                  opacity={isFiltered ? 0.8 : 0.2}
                  eventHandlers={{
                    click: () => handlePolylineClick(road.segment_id),
                    mouseover: (e) => {
                      e.target.setStyle({ color: '#6c9c34', opacity: 1.0 });
                    },
                    mouseout: (e) => {
                      e.target.setStyle({ color: isSelected ? "orange" : (isFiltered ? "black" : "blue"), opacity: isSelected ? 1.0 : (isFiltered ? 0.8 : 0.2) });
                    }
                  }}
                >
                  <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                    <table class="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {road.segment_id && (
                          <tr>
                            <td><strong>ID</strong></td>
                            <td>{road.segment_id}</td>
                          </tr>
                        )}
                        {road.slope && (
                          <tr>
                            <td><strong>Steigung</strong></td>
                            <td>{(road.slope.toFixed(2))+ "%"}</td>
                          </tr>
                        )}
                        {road.path_type && (
                          <tr>
                            <td><strong>Führungsform</strong></td>
                            <td>{road.path_type}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Tooltip>
                </Polyline>
              </React.Fragment>
            );
          }
        }
        )
        }

{/* OsmSegment.js
OSM -------------------------------------------------------------------------------------------------------*/}

        {dataSource === 'overpass' && segment.map((road) => {

          if (road.geometry === undefined) {
            return null;
          }
          const positions = road.geometry;
          const isFiltered = matchesFilter(road);
          const isSelected = selectedSegments.has(road.segment_id);
          return (
            <React.Fragment key={road.segment_id}>
              {/* White polyline for the gap effect */}
              <Polyline
                key={`${road.segment_id}-white`}
                positions={positions}
                color="white"
                weight={8}
                fill={false}
                opacity={1}
              />
           
              <Polyline
                key={`${road.segment_id}-${isFiltered}-${isSelected}`}
                positions={positions}
                className="hoversegment"
                color={isSelected ? "orange" : (isFiltered ? "black" : "darkgrey")}
                weight={6}
                fill={false}
                opacity={isFiltered ? 0.8 : 0.2}
                eventHandlers={{
                  click: () => handlePolylineClick(road.segment_id),
                  mouseover: (e) => {
                    e.target.setStyle({ color: '#6c9c34', opacity: 1.0 });
                  },
                  mouseout: (e) => {
                    e.target.setStyle({ color: isSelected ? "orange" : (isFiltered ? "black" : "darkgrey"), opacity: isSelected ? 1.0 : (isFiltered ? 0.8 : 0.2) });
                  }
                }}
              >
                <Tooltip
                  sticky={false}
                  direction="auto"
                  offset={[0, -5]}
                >
                  <table class="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                    <thead>
                      <tr>
                        <th>Attribut</th>
                        <th>Wert</th>
                      </tr>
                    </thead>
                    <tbody>
                      {road.segment_id && (
                        <tr>
                          <td><strong>ID</strong></td>
                          <td>{road.segment_id}</td>
                        </tr>
                      )}
                      {road.name && (
                        <tr>
                          <td><strong>Name</strong></td>
                          <td>{road.name}</td>
                        </tr>
                      )}
                      {road.highway && (
                        <tr>
                          <td><strong>Wegtyp</strong></td>
                          <td>{translateValue('highway', road.highway)}</td>
                        </tr>
                      )}
                      {road.bicycle_road && (
                        <tr>
                          <td><strong>Fahrradstraße</strong></td>
                          <td>{road.bicycle_road}</td>
                        </tr>
                      )}
                      {road.bicycle && (
                        <tr>
                          <td><strong>Rad</strong></td>
                          <td>{translateValue('bicycle', road.bicycle)}</td>
                        </tr>
                      )}
                      {road.foot && (
                        <tr>
                          <td><strong>Fußgänger</strong></td>
                          <td>{road.foot}</td>
                        </tr>
                      )}
                      {(road.surface || road.cycleway_surface) && (
                        <tr>
                          <td><strong>Oberfläche</strong></td>
                          <td>{road.surface ? translateValue('surface', road.surface) : road.cycleway_surface}</td>
                        </tr>
                      )}
                       {(road.width || road.cycleway_width) && (
                        <tr>
                          <td><strong>Wegebreite</strong></td>
                          <td>{road.width ? road.width : road.cycleway_width}</td>
                        </tr>
                      )}
                      {road.lit && (
                        <tr>
                          <td><strong>Beleuchtung</strong></td>
                          <td>{translateValue('lit', road.lit)}</td>
                        </tr>
                      )}
                      {road.traffic_sign && (
                        <tr>
                          <td><strong>Verkehrszeichen</strong></td>
                          <td>{road.traffic_sign}</td>
                        </tr>
                      )}
                      {road.maxspeed && (
                        <tr>
                          <td><strong>Tempolimit</strong></td>
                          <td>{road.maxspeed}</td>
                        </tr>
                      )} {road.oneway && (
                        <tr>
                          <td><strong>Einbahnstraße</strong></td>
                          <td>{road.oneway}</td>
                        </tr>
                      )}                    </tbody>
                  </table>

                </Tooltip>
              </Polyline>
            </React.Fragment>
          );
        })}

{/* Tabellen-Komponente */}
        {entries.map((entry) => {

          if (!entry.geometry) {
            return null;
          }

          const isFiltered = matchesFilter(entry);
          const isSelected = selectedSegments.has(entry.segment_id);
          return (
            <Polyline
              key={`entry-${entry.segment_id}-${isFiltered}`}
              positions={entry.geometry}
              color={isSelected ? "orange" : (isFiltered ? "#6c9c34" : "darkgrey")}
              weight={6}
              fill={true}
              opacity={isFiltered ? 0.8 : 0.2}
              eventHandlers={{
                click: () => toggleOffcanvas(),
              }}
            >
              <Tooltip
                sticky={false}
                direction="auto"
                offset={[0, -5]}
              >
                <div class="table-container">
                  <div class="table-wrapper">
                    <h4>OSM-Daten</h4>
                    <table class="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entry.segment_id && (
                          <tr>
                            <td><strong>ID</strong></td>
                            <td>{entry.segment_id}</td>
                          </tr>
                        )}
                        {entry.name && (
                          <tr>
                            <td><strong>Name</strong></td>
                            <td>{entry.name}</td>
                          </tr>
                        )}
                        {entry.highway && (
                          <tr>
                            <td><strong>Wegtyp</strong></td>
                            <td>{translateValue('highway', entry.highway)}</td>
                          </tr>
                        )}
                        {entry.cycleway && (
                          <tr>
                            <td><strong>Führungsform</strong></td>
                            <td>{entry.fuhrungsform ? translateValue('cycleway', entry.fuhrungsform) : translateValue('cycleway', entry.cycleway)}</td>
                          </tr>
                        )}

                        {entry.bicycle && (
                          <tr>
                            <td><strong>Für Rad</strong></td>
                            <td>{translateValue('bicycle', entry.bicycle)}</td>
                          </tr>
                        )}
                        {entry.foot && (
                          <tr>
                            <td><strong>Für Fußgänger</strong></td>
                            <td>{entry.foot}</td>
                          </tr>
                        )}
                        {entry.surface && (
                          <tr>
                            <td><strong>Oberfläche</strong></td>
                            <td>{entry.oberflaeche ? translateValue('surface', entry.oberflaeche) : translateValue('surface', entry.surface)}</td>
                          </tr>
                        )}
                        {entry.lit && (
                          <tr>
                            <td><strong>Beleuchtung</strong></td>
                            <td>{translateValue('lit', entry.lit)}</td>
                          </tr>
                        )}
                        {entry.traffic_sign && (
                          <tr>
                            <td><strong>Verkehrszeichen</strong></td>
                            <td>{entry.traffic_sign}</td>
                          </tr>
                        )}
                        {entry.maxspeed && (
                          <tr>
                            <td><strong>Tempolimit</strong></td>
                            <td>{entry.tempolimit ? entry.tempolimit : entry.maxspeed}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div class="table-wrapper">
                    <h4>Ergänzte Daten</h4>
                    <table class="tooltipTable" border="1" cellpadding="5" cellspacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entry.fuhrungsform && (
                          <tr>
                            <td><strong>Führungsform</strong></td>
                            <td>{entry.fuhrungsform}</td>
                          </tr>
                        )}
                        {entry.oberflaeche && (
                          <tr>
                            <td><strong>Oberfläche</strong></td>
                            <td>{entry.oberflaeche}</td>
                          </tr>
                        )}
                        {entry.tempolimit && (
                          <tr>
                            <td><strong>Tempolimit</strong></td>
                            <td>{entry.tempolimit}</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                </div>
              </Tooltip>
            </Polyline>
          );
        })}
{/* Main */}
        <div className="testbutton-container">
          <button
            className="test-button" onClick={handleNavShow}>
            Optionen
          </button>
        </div>

        <MapEvents />
      </MapContainer>

{/* Nav-Komponente */}
      <Offcanvas show={navShow} onHide={handleNavClose} placement="start" className="greyOC">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Optionen</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="switch-container">

            <p>Lade eine Datenquelle:</p>
            <ButtonGroup className="mb-3">
              <Button onClick={() => handleLoadSegments('overpass')}>
                Overpass API
              </Button>
              <Button onClick={() => handleLoadSegments('fastapi')}>
                FastAPI
              </Button>
              <Button onClick={() => handleLoadSegments('imported')}>
                Importierte Segmente
              </Button>
            </ButtonGroup>
          </div>
          <hr></hr>


          <button className="test-button" onClick={() => setFilterModalOpen(true)}>
            Segmente filtern
          </button>

          <button
            className="test-button"
            onClick={toggleMultiSelectMode}
          >
            {multiSelectMode ? "Multi-Auswahl beenden" : "Multi-Auswahl starten"}
          </button>

          <button
            className="test-button"
            onClick={toggleOffcanvas}
          >
            Einträge anzeigen/ändern
          </button>

          <button
            className="test-button"
            onClick={() => setExportModalOpen(true)}
          >
            Exportieren
          </button>

          <button
            className="test-button"
            onClick={() => setImportModalOpen(true)}
          >
            GeoJson importieren
          </button>

          {multiSelectMode && selectedSegments.size > 0 && (
            <button
              className="test-button"
              onClick={() => setModalOpen(true)}
            >
              Eintrag für Auswahl
            </button>
          )}
        </Offcanvas.Body>
      </Offcanvas>






{/* Export-Komponente */}
      {/* Export Modal -------------------------------------------------------------------------------------------------------*/}
      <Modal
        show={exportModalOpen}
        onHide={() => setExportModalOpen(false)}
        centered
        className="modal-export"
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Optionen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button variant="primary" className="w-100 mb-2" onClick={exportNewEntries}>
            Neue Einträge exportieren
          </Button>
          <Button variant="primary" className="w-100 mb-2" onClick={exportAllSegments}>
            Alle Segmente exportieren
          </Button>
          <Button variant="primary" className="w-100" onClick={exportFilteredSegments}>
            Gefilterte Segmente exportieren
          </Button>
        </Modal.Body>
      </Modal>

{/* ImportSegment.js */}
      {/* Import Modal -------------------------------------------------------------------------------------------------------*/}
      <Modal
        show={importModalOpen}
        onHide={() => setImportModalOpen(false)}
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Optionen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>GeoJson importieren</p>
          <input
            type="file"
            accept=".geojson"
            onChange={(event) => {
              handleFileUpload(event); // Call the upload function directly
              setImportModalOpen(false); // Close the modal after file selection
            }}
          />
          <Button className="w-100" onClick={() => setImportModalOpen(false)}>
            Import abschließen
          </Button>
        </Modal.Body>
      </Modal>


{/* Filter-Komponente */}
      {/* Filter Modal -------------------------------------------------------------------------------------------------------*/}
      <Modal
        show={filterModalOpen}
        onHide={handleFilterModalClose}
        size="xl"
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Segmente filtern</Modal.Title>
        </Modal.Header>
        <Modal.Body className="filterModal">
          <Form>
            <Row className="selectedFilter">
              <Table>
                <tbody className="tableFilter">
                  {filterRules.map((rule, index) => (
                    <tr key={index}>
                      <td style={{ fontSize: '1.4em', color: '#6c9c34' }} lg={2}>
                        <strong>{translateCategory(rule.category)}:</strong>
                      </td>
                      <td style={{ fontSize: '1.2em', color: 'grey' }} lg={9}>
                        {rule.selectedEntries.length > 0
                          ? rule.selectedEntries.map(entry => translations[rule.category][entry]).join(', ')
                          : "kein Filter"}                    </td>
                      <td lg={1}>
                        <Button className="filterSegBtn" variant="secondary" onClick={() => setActiveRuleIndex(index)}>
                          Bearbeiten
                        </Button>
                        <Button className="filterSegBtn" variant="danger" onClick={() => resetCategory(index)}>
                          X
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
            {filterRules.map((rule, index) => (
              <Form.Group key={index} controlId={`filterRule${index}`}>
                {activeRuleIndex === index && (
                  <>
                    <Row>
                      <Col className="filtertitle" lg={2}>
                        <p className="filtertitleElement">Kategorie: </p>
                      </Col>
                      <Col lg={10}>
                        <Form.Control
                          as="select"
                          value={rule.category}
                          onChange={(e) => handleCategoryChange(index, e.target.value)}
                          className="formcontrolFilter"
                        >
                          <option value="">Wählen Sie eine Kategorie</option>
                          <option value="highway">Wegtyp</option>
                          <option value="surface">Oberfläche</option>
                          <option value="cycleway">Führungsform</option>

                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="filterSelection">
                      <Col lg={12}>
                        {rule.category && (
                          <>
                            <Row className="checkboxFilter">
                              {Object.entries(translations[rule.category]).map(([key, displayValue], idx) => (
                                <Col lg={4} key={key}>
                                  <Form.Check
                                    type="checkbox"
                                    className="segmentfiltercheck"
                                    id={`entry-${index}-${key}`}
                                    label={displayValue}
                                    checked={rule.selectedEntries.includes(key)}
                                    onChange={() => handleEntryChange(index, key)}
                                  />
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row className="filterApproval">
                      <Button variant="primary" onClick={() => handleFilterApproval(index)}>
                        Eingabe bestätigen
                      </Button>
                    </Row>
                  </>
                )}
              </Form.Group>
            ))}

          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Row className="w-100">
            <Col lg={6} className="d-flex justify-content-center">
              <Button variant="primary" className="w-100" onClick={addFilterRule}>
                Regel hinzufügen
              </Button>
            </Col>
            <Col lg={6} className="d-flex justify-content-center">
              <Button variant="primary" className="w-100" onClick={applyFilters}>
                Segmente filtern
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>

{/*SegmenteEdit-Komponente*/}
      {/* Wert ergänzen Modal -------------------------------------------------------------------------------------------------------*/}

      <Modal
        show={modalOpen}
        onHide={handleModalClose}
        size="lg"
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Wert ergänzen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSegmentId">
              <Form.Label>Segment ID</Form.Label>
              <Form.Control type="text" readOnly value={selectedSegmentId || ''} />
            </Form.Group>
            <Form.Group controlId="formFuhrungsform">
              <Form.Label>Führungsform</Form.Label>
              <Form.Control
                as="select"
                value={fuhrungsformValue}
                onChange={(e) => setFuhrungsformValue(e.target.value)}
              >
                <option value="">Bitte wählen</option>
                <option value="Fahrradspur">Fahrradspur</option>
                <option value="Radweg">Radweg</option>
                <option value="Fahrradstraße">Fahrradstraße</option>
                <option value="GehRadweg">Geh-/Radweg</option>
                <option value="Busspur">Busspur</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formOberflaeche">
              <Form.Label>Oberfläche</Form.Label>
              <Form.Control
                as="select"
                value={oberflaecheValue}
                onChange={(e) => setOberflaecheValue(e.target.value)}
              >
                <option value="">Bitte wählen</option>
                <option value="Asphalt">Asphalt</option>
                <option value="Kopfsteinpflaster">Kopfsteinpflaster</option>
                <option value="Beton">Beton</option>
                <option value="Erde">Erde/Schüttuntergund</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formTempolimit">
              <Form.Label>Tempolimit</Form.Label>
              <Form.Control
                as="select"
                value={tempolimitValue}
                onChange={(e) => setTempolimitValue(e.target.value)}
              >
                <option value="">Bitte wählen</option>
                <option value="Spielstraße">Spielstraße</option>
                <option value="30">30 km/h</option>
                <option value="40">40 km/h</option>
                <option value="50">50 km/h</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Schließen
          </Button>
          <Button variant="primary" onClick={handleFormSubmit}>
            Änderungen speichern
          </Button>
        </Modal.Footer>
      </Modal>

{/*Tabellen-Komponente*/}
      {/* Off Canvas Tabelle -------------------------------------------------------------------------------------------------------*/}

      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement="top"
        className="custom-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Einträge</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Segment ID</th>
                <th>Straße</th>
                <th>Führungsform</th>
                <th>Oberfläche</th>
                <th>Tempolimit</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{entry.segment_id}</td>
                  <td>{entry.name}</td>
                  <td>{entry.fuhrungsform}</td>
                  <td>{entry.oberflaeche}</td>
                  <td>{entry.tempolimit}</td>
                  <td>
                    <Button
                      variant="secondary"
                      size="s"
                      onClick={() => handleEditEntry(entry)}
                      style={{ marginRight: '5px' }}
                    >
                      Editieren
                    </Button>
                    <Button
                      variant="danger"
                      size="s"
                      onClick={() => handleDeleteEntry(entry.segment_id)}
                    >
                      Löschen
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default Testpage;