import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {fetchRoadsData} from "../../functions/MapFunctions";
import {getColorClass} from "../../functions/Colors";

function RoadsLayer({roadSegmentState, roadSegmentsChecked, roadsData, mapBounds, loadingSpin,
                        updateRoadsData, updateLoadingSpin}) {

    const useRoadsData = useCallback(() => {
        fetchRoadsData(mapBounds, updateRoadsData, updateLoadingSpin);
    }, [mapBounds]);

    useEffect(() => {
        if (roadSegmentsChecked) {
            useRoadsData();
        } else {
            updateRoadsData({});
        }
    }, [mapBounds, roadSegmentState, roadSegmentsChecked]);

    return (
        <LayersControl.Overlay name="3: Straßeneinfärbungen" checked={roadSegmentsChecked}>
            <LayerGroup>
                {/* Anomaly-RoadSegment-Score anzeigen */}
                {roadSegmentState === "AnomalieSeg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((key) => {

                    const road = roadsData[key];

                    return (
                        <Polyline
                            key={road.id}
                            positions={road.geometry.coordinates}
                            color={getColorClass(road.anomaly)}
                            weight={4}
                            fill={false}
                            opacity={1} // 1 for visible, 0 for hidden
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Anomalien: "}<br></br>
                                {(road.anomaly === null) ? 0 : road.anomaly.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* Time-Loss-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "TimeLossSeg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((key) => {

                    const road = roadsData[key];

         
                    return (
                        <Polyline
                        key={road.id}
                            positions={road.geometry.coordinates}
                            color={getColorClass(road.time_loss / 30)}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Zeitverlusts: "}<br></br>
                                {(road.time_loss === null) ? 0 : road.time_loss.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* V-Score-V1-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "V1Seg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((key) => {

                    const road = roadsData[key];

                  
                    return (
                        <Polyline
                        key={road.id}
                            positions={road.geometry.coordinates}
                            color={getColorClass((road.v_score_v1))}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen: "}<br></br>
                                {(road.v_score_v1 === null) ? 0 : road.v_score_v1.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}

                {/* V-Score-V2-RoadSegment-Daten anzeigen */}
                {roadSegmentState === "V2Seg" && !loadingSpin && roadsData.length > 0 && Object.keys(roadsData).map((key) => {

                    const road = roadsData[key];

                    return (
                        <Polyline
                        key={road.id}
                            positions={road.geometry.coordinates}
                            color={getColorClass(road.v_score_v2)}
                            weight={4}
                            fill={false}
                            opacity={1}
                        >
                            <Tooltip
                                sticky={true}
                                direction="right"
                                offset={[0, 5]}
                            >
                                {"Erschütterungen unter Berücksichtigung der Geschwindigkeit: "}<br></br>
                                {(road.v_score_v2 === null) ? 0 : road.v_score_v2.toFixed(2)}
                            </Tooltip>
                        </Polyline>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default RoadsLayer;