import React, { useCallback, useEffect } from "react";
import { LayerGroup, LayersControl, Polyline, Tooltip } from "react-leaflet";
import { fetchTrafficCount, fetchAccidentSegmentCount, fetchAccidentRateSegmentCount, fetchConcernsSegmentCount, fetchRoadDamageSegmentCount, fetchAccidentNodeCount } from "../../functions/MapFunctions";
import { getColorClassAccidents, getColorClassSegmentAccidents, getColorClassSegmentSpeed, getLegendColor } from "../../functions/Colors";
import { debounce } from 'lodash';

function AccumulationLayer({ accumulationState, accumulationChecked, mapBounds, accumulationData,
                               updateAccumulationData, updateLoadingSpin, sD, eD, town, loadingSpin }) {

    const useInfrastructure = useCallback(() => {
        if (accumulationState === "Unfälle") {
            fetchAccidentSegmentCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD);
        } else if (accumulationState === "Meldungen") {
            fetchConcernsSegmentCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD);
        } else if (accumulationState === "Schadensmeldungen") {
            fetchRoadDamageSegmentCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD);
        } else if (accumulationState === "Verkehrsaufkommen" || accumulationState === "Geschwindigkeit") {
            fetchTrafficCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD);
        } else if (accumulationState === "Unfälle per Knoten") {
            fetchAccidentNodeCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD);
        } else if (accumulationState === "Unfallrate") {
            fetchAccidentRateSegmentCount(updateAccumulationData, mapBounds, updateLoadingSpin, sD, eD, town);
        }
    }, [mapBounds, accumulationState, eD, sD, town]);

    const debouncedUseInfrastructure = useCallback(debounce(() => {
        function getCities() {
            return {
                "oldenburg": "Oldenburg",
                "osnabrueck": "Osnabrück",
                "Oldenburg": "oldenburg",
                "Osnabrück": "osnabrueck"
            };
        }
        
        if (accumulationChecked) {
            const cityMap = getCities();
            town = cityMap[town] || town;
            useInfrastructure();
        }
    }, 200), [accumulationChecked, useInfrastructure]);

    useEffect(() => {
        debouncedUseInfrastructure();
        return () => {
            debouncedUseInfrastructure.cancel();
        };
    }, [accumulationChecked, accumulationState, mapBounds, eD, sD, debouncedUseInfrastructure, town]);


    return (
        <>
            <LayersControl.Overlay name="14: Kumulierte Segmentwerte" checked={accumulationChecked}>
                <LayerGroup>

                    {/* Unfallrate je Straße anzeigen */}
                    {accumulationState === "Unfallrate" && !loadingSpin && Object.keys(accumulationData).map((station) => {
                        const road = accumulationData[station];
          
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }

                        return (
                            <Polyline key={road.stationname} positions={road.geometry.map(coord => [coord[1], coord[0]])}
                                      color={getLegendColor(road.unfallrate, road.max)} weight={3} fill={false} opacity={0.7}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    <strong>{"Straße: " + (road.stationname ? road.stationname : "k.A.")}</strong><br />
                                    <strong>{"Unfallrate: " + (road.unfallrate ? road.unfallrate.toFixed(2) : 0)}</strong><br />
                                    <strong>{"Unfälle: " + (road.accidentsCount ? road.accidentsCount : 0)}</strong><br />
                                    <strong>{"Länge in m: " + (road.length ? road.length.toFixed(2) : 0)}</strong><br />
                                </Tooltip>
                            </Polyline>
                        );
                    })}


                    {/* Unfälle je Straßensegment anzeigen */}
                    {accumulationState === "Unfälle" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const road = accumulationData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline key={road.segment_id} positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                      color={getColorClassAccidents(road.accident_count)} weight={2} fill={false} opacity={0.7}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    <strong>{"gezählte Unfälle: " + (road.accident_count ? road.accident_count : 0)}</strong><br />
                                    {Object.entries(road.accident_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Unfälle je Knoten anzeigen */}
                    {accumulationState === "Unfälle per Knoten" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const node = accumulationData[key];

                        if (!node || !node.geometry) {
                            return null;
                        }
                        return (
                            <Polyline key={node.node_id} positions={node.geometry.coordinates} color="black" weight={2} fill={true}
                                      fillColor={getColorClassAccidents(node.accident_count)} fillOpacity={0.6} opacity={1}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    {"ID: " + (node.node_id ? node.node_id : "k.A")}<br />
                                    {"gezählte Unfälle: " + (node.accident_count ? node.accident_count : 0)}<br />
                                    {Object.entries(node.accident_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Meldungen je Straßensegment anzeigen */}
                    {accumulationState === "Meldungen" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const road = accumulationData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline key={road.segment_id} positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                      color={getColorClassSegmentAccidents(road.concern_count)} weight={2} fill={false} opacity={1}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    {"gezählte Meldungen: "}<br />
                                     {(road.concern_count ? road.concern_count : "k.A")}<br />
                                    {Object.entries(road.concern_type_counts || {}).map(([type, count]) => (
                                        <React.Fragment key={type}>
                                            {type + ": " + count}<br />
                                        </React.Fragment>
                                    ))}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Schadensmeldungen je Straßensegment anzeigen */}
                    {accumulationState === "Schadensmeldungen" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const road = accumulationData[key];
                        if (road === null || road.geometry === null) {
                            return null
                        }

                        return (
                            <Polyline key={road.segment_id} positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                      color={getColorClassSegmentAccidents(road.damage_count)} weight={2} fill={false} opacity={1}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    {"gezählte Schadensmeldungen: "}<br />
                                    {(road.damage_count ? road.damage_count : 0)}
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Verkehrsaufkommen je Straßensegment anzeigen */}
                    {accumulationState === "Verkehrsaufkommen" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const road = accumulationData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline key={`${road.segment_id}-${road.max}`} positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                      color={getLegendColor(road.day_avg_traffic_count.toFixed(0),road.max)} weight={2} fill={false} opacity={1}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    <p className="tooltiptext">{"durchschnittliches VA der " + (road.stations) + " Zählstelle/n"}</p>
                                    <p className="tooltiptext">{(road.day_avg_traffic_count ? road.day_avg_traffic_count.toFixed(0) : "k.A") + " am Tag"}</p>
                                    <p className="tooltiptext">{(road.hour_avg_traffic_count ? road.hour_avg_traffic_count.toFixed(0) : "k.A") + " pro Stunde"}</p>
                                    <p className="tooltiptext">{(road.sum_traffic_count ? road.sum_traffic_count.toFixed(0) : "k.A") + " Summe Zeitraum"}</p>
                                </Tooltip>
                            </Polyline>
                        );
                    })}

                    {/* Speed je Straßensegment anzeigen */}
                    {accumulationState === "Geschwindigkeit" && !loadingSpin && Object.keys(accumulationData).map((key) => {
                        const road = accumulationData[key];
                        if (road === undefined || road.geometry === undefined) {
                            return null
                        }
                        return (
                            <Polyline key={road.segment_id} positions={road.geometry.coordinates.map(coord => [coord[1], coord[0]])}
                                      color={getColorClassSegmentSpeed(road.avg_speed_count)} weight={2} fill={false} opacity={1}>
                                <Tooltip sticky={false} direction="auto" offset={[0, -5]}>
                                    <p className="tooltiptext">{"Durchschnitt der " + (road.stations) + " Zählstelle/n"}</p>
                                    <p className="tooltiptext">{(road.avg_speed_count ? road.avg_speed_count.toFixed(2) : "k.A") + " km/h"}</p>
                                </Tooltip>
                            </Polyline>
                        );
                    })}
                </LayerGroup>
            </LayersControl.Overlay>
        </>
    );
}

export default AccumulationLayer;