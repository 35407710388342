import React from "react";
import logo from "../../images/logo.png";
import {Container, Nav, Navbar} from "react-bootstrap";
import {NavLink} from "react-router-dom";
//import {useKeycloak} from "@react-keycloak/web";

const Header = () => {
    //const {keycloak} = useKeycloak();

    return (
        <div>
            <Navbar collapseOnSelect expand="lg" className='header'>
                <Container>
                    <Navbar.Brand>
                        <a href='https://radweg-radar.de/'>
                            <img src={logo} height="50px" alt="logo"/>
                        </a>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink className="navlink" to='/'>Dashboard</NavLink>
                            <NavLink className="navlink" to='/meldung'>Meldeplattform</NavLink>
                            <NavLink className="navlink" to='/infrastructure'>Infrastruktur KPIs</NavLink>
                            <a className="navlink" href='https://radweg-radar.de/blog/'>Blog</a>
                            <a className="navlink" href='https://radweg-radar.de/ueber-uns/'>Über uns</a>
                            <a className="navlink" href='https://radweg-radar.de/projekt-infrasense/'>
                                Projekt INFRASense
                            </a>
                            <a className="navlink" href="https://radweg-radar.de/kontakt/">
                                Kontakt
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Header;
