import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {
    barrierIcon, bikeMIcon,
    bugIcon,
    bulbIcon,
    childIcon, dogIcon,
    prayIcon,
    reportIcon, trafficLightIcon,
    trafficSignIcon,
    trashIcon, treeIcon, waterIcon
} from "../../functions/Icons";
import {fetchConcerns} from "../../functions/MapFunctions";

function ConcernsLayer({concernsChecked, groupedConcernsData, concernActive, filterConcerns, sD, eD, mapBounds,
                           updateGroupedConcernsData, updateLoadingSpin}) {

    const useConcerns = useCallback(() => {
        fetchConcerns(sD, eD, mapBounds, updateGroupedConcernsData, updateLoadingSpin);
    }, [sD, eD, mapBounds]);

    useEffect(() => {
        if (concernsChecked) {
            useConcerns();
        } else {
            updateGroupedConcernsData({});
        }
    }, [sD, eD, mapBounds, concernsChecked]);

    return (
        <LayersControl.Overlay name="11: Meldungen" checked={concernsChecked}>
            <LayerGroup>
                {Object.keys(groupedConcernsData).map((year) =>
                    Object.keys(groupedConcernsData[year]).map((month) =>
                        Object.keys(groupedConcernsData[year][month]).map((primaryKey) => {
                                const data = groupedConcernsData[year][month][primaryKey];
                                let meldIcon;
                                let shouldDisplay = true;

                                if ((concernActive.includes("Alle")) === false) {
                                    shouldDisplay = filterConcerns.includes(data.category_text);
                                }

                                if (data.category_text === "Straßen" || data.category_text === "Straßen/Gehwege") {
                                    meldIcon = barrierIcon;
                                } else if (data.category_text === "Wilde Müllkippe" || data.category_text === "Wilde Müllablagerung" || data.category_text === "Müllkippe" || data.category_text === "Müllabfuhr" || data.category_text === "Papierkörbe") {
                                    meldIcon = trashIcon;
                                } else if (data.category_text === "Danke sagen") {
                                    meldIcon = prayIcon;
                                } else if (data.category_text === "Verkehrszeichen" || data.category_text === "Beschilderung") {
                                    meldIcon = trafficSignIcon;
                                } else if (data.category_text === "Eichenprozessionsspinner" || data.topic_text === "Eichenprozessionsspinner") {
                                    meldIcon = bugIcon;
                                } else if (data.category_text === "Straßenbeleuchtung") {
                                    meldIcon = bulbIcon;
                                } else if (data.category_text === "Spielplätze" || data.category_text === "Spiel- und Bolzplätze") {
                                    meldIcon = childIcon;
                                } else if (data.category_text === "Radwege") {
                                    meldIcon = bikeMIcon;
                                } else if (data.category_text === "Ampel" || data.category_text === "Ampeln") {
                                    meldIcon = trafficLightIcon;
                                } else if (data.category_text === "Fahrbahnmarkierungen/Verkehrsschilder") {
                                    meldIcon = trafficSignIcon;
                                } else if (data.category_text === "Kanalisation") {
                                    meldIcon = waterIcon;
                                } else if (data.category_text === "Hunde") {
                                    meldIcon = dogIcon;
                                } else if (data.category_text === "Bäume" || data.category_text === "Grün an Straßen" || data.category_text === "Grünflächen" || data.category_text === "Grün- und Parkanlagen") {
                                    meldIcon = treeIcon;
                                } else {
                                    meldIcon = reportIcon;
                                }

                                if (shouldDisplay) {
                                    return (
                                        <Marker key={primaryKey}
                                            position={[
                                                groupedConcernsData[year][month][primaryKey].latitude,
                                                groupedConcernsData[year][month][primaryKey].longitude
                                            ]} icon={meldIcon}>
                                            <Popup>
                                                <strong>TT/MM/JJJJ:</strong> {data.day}/{data.month}/{data.year} <br />
                                                <strong>Kategorie:</strong> {data.category_text} <br />
                                                <strong>Problem:</strong> {data.topic_text} <br />
                                                <strong>Status:</strong> {data.status_text} <br />
                                                <strong>Beschreibung:</strong> {data.description} <br />
                                            </Popup>
                                        </Marker>
                                    );
                                }
                                else {
                                    return null;
                                }
                            }
                        )
                    ))}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default ConcernsLayer;