import React from "react";
import {sortByKeyAsc} from "../functions/CommonFunctions";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

function Bar_Chart_Stacked_normal({data, dataX, dataY, colors}) {
  data = Array.from(data);
  data = sortByKeyAsc(data, dataY);

  const dataXValues = data.map(item => parseInt(item[dataY]));
  let maxValue = Math.max(...dataXValues);

  if (dataX === "category") {
    maxValue = 8000;
  }

  return (
    <ResponsiveContainer width='99%' height='88%'>
      <BarChart data={data} layout={"horizontal"} margin={{ top: 5, right: 10, left: 20, bottom: 5 }} height={90}>
        <XAxis fontSize={12} type="category" textAnchor="start" horizontalAnchor="end" interval={0} angle="40" dataKey={dataX} />
        <YAxis type="number" domain={[0, maxValue]} ow/>
        <Tooltip/>
        {dataY.map((dataKey, index) => (
          <Bar dataKey={dataKey} stackId="a" fill={colors[index]}/>
        ))}
        <Legend iconSize={8} verticalAlign="bottom" wrapperStyle={{ paddingTop: '28px' }}/>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Bar_Chart_Stacked_normal;
