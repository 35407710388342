import React, {useCallback, useEffect} from "react";
import {Circle, LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {whiteIcon} from "../../functions/Icons";
import {fetchAccidents} from "../../functions/MapFunctions";
import { Zoom } from "@mui/material";

function AccidentSeverityLayer({accidentChecked, perpetratorChecked, groupedAccData, mapBounds, loadingSpin, filterCondition,
                                   moneyDamageFrom, moneyDamageTo, perpChoice, roadCond, accType, filterWeather, sD, eD,
                                   sDQuarter, eDQuarter, updateGroupedAccData, updateLoadingSpin}) {

    const useAccidents = useCallback(() => {
        fetchAccidents(sD, eD, sDQuarter, eDQuarter, mapBounds, updateGroupedAccData, updateLoadingSpin);
    }, [sD, eD, mapBounds]);

    useEffect(() => {
        if (accidentChecked || perpetratorChecked) {
            useAccidents();
        } else {
            updateGroupedAccData({});
        }
    }, [sD, eD, mapBounds, accidentChecked, perpetratorChecked]);

    return (
        <LayersControl.Overlay name="9: Unfallschwere" checked={accidentChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(groupedAccData).map(year =>
                    Object.keys(groupedAccData[year]).map(month =>
                        Object.keys(groupedAccData[year][month]).map(primaryKey => {
                            const data = groupedAccData[year][month][primaryKey];
                            let shouldDisplay = true;



                            const circleStyle = {
                                radius: 5,
                                color: "none",
                                fillColor: `#${data.euska_accident_color}`,
                                fillOpacity: 1,
                            };

                            let fillColor = `#${data.euska_accident_color}`;


                            if (data.slightly_injured > 0) {
                                circleStyle.fillColor = fillColor;
                                circleStyle.radius = 5;
                            }
                            if (data.serious_injured > 0) {
                                circleStyle.fillColor = fillColor;
                                circleStyle.radius = 10;
                            }
                            if (data.killed > 0) {
                                circleStyle.fillColor = fillColor;
                                circleStyle.radius = 15;
                            }

                            const markerPosition = [
                                data.euska_latitude,
                                data.euska_longitude,
                            ];

                            filterCondition.forEach(condition => {
                                console.log(condition)
                                if (condition === "all") return;
                                switch (condition) {
                                    case "alcohol":
                                        shouldDisplay = shouldDisplay && data.cognos_alcohol === "true";
                                        break;
                                    case "drugs":
                                        shouldDisplay = shouldDisplay && data.cognos_drugs === "true";
                                        break;
                                    case "frost":
                                        shouldDisplay = shouldDisplay && data.temp < 0;
                                        break;
                                    case "money":
                                        shouldDisplay = shouldDisplay && data.cognos_monetary_damage >= moneyDamageFrom && data.cognos_monetary_damage <= moneyDamageTo;
                                        break;
                                    case "verletzte":
                                        shouldDisplay = shouldDisplay && data.injured >= injured;
                                        break;
                                    case "verursacher":
                                        shouldDisplay = shouldDisplay && perpChoice.includes(data.perpetrator_german);
                                        break;
                                    case "oberflaeche":
                                        shouldDisplay = shouldDisplay && roadCond.includes(data.euska_road_condition);
                                        break;
                                    case "unfalltyp":
                                        shouldDisplay = shouldDisplay && accType.includes(data.euska_accident_type_long);
                                        break;
                                    case "wetter":
                                        shouldDisplay = shouldDisplay && filterWeather.includes(data.condition);
                                        break;
                                    default:
                                        return;
                                }
                            });

                            if (shouldDisplay) {
                                return (
                                    <Marker position={markerPosition} icon={whiteIcon} {...circleStyle}>
                                        <Circle center={markerPosition} {...circleStyle}/>
                                        <Popup>
                                            <strong>Farbe:</strong> {data.euska_accident_color}
                                            <strong>Tag:</strong> {data.day}.{data.month}.{data.year}
                                            <strong> Uhrzeit:</strong> {data.hours}:{data.minutes} Uhr <br/>
                                            <strong> Straße:</strong> {data.road_name} <br/><br/>
                                            <strong>Unfalltyp Euska: </strong> {data.euska_accident_type_long}<br/>
                                            <strong> Cognos: </strong>{data.cognos_accident_type} <br/>
                                            <strong>Einfluss
                                                von</strong> Alkohol:{data.cognos_alcohol === "true" ? " Ja" : " - "} /
                                            Drogen: {data.cognos_drugs === "true" ? " Ja" : " - "} <br/>
                                            <br/>

                                            <strong>Verursacher:</strong> {data.perpetrator_german} <br/>
                                            <strong>Beteiligte:</strong> {data.number_participants} Person/en
                                            mit {data.euska_participant_german} <br/>
                                            <strong>Verletzte:</strong> {data.injured}
                                            <strong> (Tot/Schwer/Leicht):</strong> {data.killed}/{data.serious_injured}/{data.slightly_injured}
                                            <br/>
                                            <strong>Schadenshöhe
                                                :</strong> {data.cognos_monetary_damage !== 0 ? data.cognos_monetary_damage : 0},-
                                            € <br/><br/>
                                            <strong>Wetter</strong> <br/>
                                            <strong>Temperatur:</strong> {data.temp} Grad
                                            /<strong> Wetter:</strong> {data.condition ? data.condition : " k.A."}<br/>
                                            <strong>Oberfläche: </strong>{data.euska_road_condition}, <strong>Hitzeindex:</strong>{data.heatindex ? data.heatindex : " k.A."}
                                            <br/>
                                            <strong>Bewölkt: </strong>{data.cloudcover ? data.cloudcover + "%" : " k.A."}, <strong>Schnee: </strong>{data.snow ? data.snow : " k.A."},
                                            Tiefe: {data.snowdepth ? data.snowdepth : " k.A."} <br/>
                                        </Popup>
                                    </Marker>
                                )
                            }
                        })
                    )
                )}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default AccidentSeverityLayer;