import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell,Rectangle } from "recharts";
import {sortByKeyAsc} from "../functions/CommonFunctions";
import CustomTooltip from './CustomTooltip';

function Bar_Chart({data, dataX, dataY, colors}) {
  data = Array.from(data);
  data = sortByKeyAsc(data, dataY);
  let leftMargin;

  if (dataX ==="topic_text" || dataX=="road_name") {
    leftMargin = 85;
  } else {
    leftMargin = 10;
  }
  
  const dataXValues = data.map(item => parseInt(item[dataY])+1);
 const maxValue = Math.max(...dataXValues);


  return (
    <ResponsiveContainer width='99%' height='88%'>
      <BarChart data={data} layout={"vertical"} margin={{ top: 5, right: 5, left: leftMargin, bottom: 5 }}>
        <XAxis type="number" domain={[0, maxValue]} allowDataOverflow/>
        <YAxis fontSize={12} type="category" scaleToFit="true" textAnchor="end" textWrap={true} verticalAnchor="start" interval={0}
               angle="0" dataKey={dataX}/>
        <Tooltip content={<CustomTooltip />} />
        {/*<Legend/>*/}
        {dataY.map((dataKey, index) => (
          <Bar key={dataKey} dataKey={dataKey} activeBar={<Rectangle stroke="rgba(255, 255, 255, 0.3)" strokeWidth={4} />}>
            {data.map((entry, entryIndex) => (
              // if with colorarray <Cell key={`cell-${entryIndex}`} fill={colors[entryIndex % colors.length]} />
              <Cell key={`cell-${entryIndex}`} fill={"#6c9c34"} />
            ))}
          </Bar>
        ))}
          
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Bar_Chart;