import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

const SegmenteEditComponent = ({
  modalOpen,
  handleModalClose,
  selectedSegmentId,
  fuhrungsformValue,
  setFuhrungsformValue,
  oberflaecheValue,
  setOberflaecheValue,
  tempolimitValue,
  setTempolimitValue,
  segment,
  entries,
  setEntries,
  multiSelectMode,
  selectedSegments
}) => {
  const handleFormSubmit = () => {
    console.log(segment);
    if (multiSelectMode) {
      const newEntries = Array.from(selectedSegments).map(id => {
        const seg = segment.find(seg => seg.segment_id === id);
        return {
          segment_id: id,
          fuhrungsform: fuhrungsformValue,
          oberflaeche: oberflaecheValue,
          tempolimit: tempolimitValue,
          geometry: seg ? seg.geometry : null,
          bicycle: seg ? seg.bicycle || '-' : '-',
          cycleway: seg ? seg.cycleway || '-' : '-',
          highway: seg ? seg.highway || '-' : '-',
          lit: seg ? seg.lit || '-' : '-',
          maxspeed: seg ? seg.maxspeed || '-' : '-',
          name: seg ? seg.name || '-' : '-',
          sidewalk: seg ? seg.sidewalk || '-' : '-',
          surface: seg ? seg.surface || '-' : '-',
        };
      });
      setEntries(prevEntries => [...prevEntries, ...newEntries]);
    } else {
      const seg = segment.find(seg => seg.segment_id === selectedSegmentId);
      const newEntry = {
        segment_id: selectedSegmentId,
        fuhrungsform: fuhrungsformValue,
        oberflaeche: oberflaecheValue,
        tempolimit: tempolimitValue,
        geometry: seg ? seg.geometry : null,
        bicycle: seg ? seg.bicycle || '-' : '-',
        cycleway: seg ? seg.cycleway || '-' : '-',
        highway: seg ? seg.highway || '-' : '-',
        lit: seg ? seg.lit || '-' : '-',
        maxspeed: seg ? seg.maxspeed || '-' : '-',
        name: seg ? seg.name || '-' : '-',
        sidewalk: seg ? seg.sidewalk || '-' : '-',
        surface: seg ? seg.surface || '-' : '-',
      };
      setEntries(prevEntries => {
        const updatedEntries = prevEntries.filter(entry => entry.segment_id !== selectedSegmentId);
        return [...updatedEntries, newEntry];
      });
    }
    console.log("Einträge:", entries);
    handleModalClose();
  };

  return (
    <Modal
      show={modalOpen}
      onHide={handleModalClose}
      size="lg"
      centered
      className="modal-report-form"
    >
      <Modal.Header closeButton>
        <Modal.Title>Wert ergänzen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSegmentId">
            <Form.Label>Segment ID</Form.Label>
            <Form.Control type="text" readOnly value={selectedSegmentId || ''} />
          </Form.Group>
          <Form.Group controlId="formFuhrungsform">
            <Form.Label>Führungsform</Form.Label>
            <Form.Control
              as="select"
              value={fuhrungsformValue}
              onChange={(e) => setFuhrungsformValue(e.target.value)}
            >
              <option value="">Bitte wählen</option>
              <option value="Fahrradspur">Fahrradspur</option>
              <option value="Radweg">Radweg</option>
              <option value="Fahrradstraße">Fahrradstraße</option>
              <option value="GehRadweg">Geh-/Radweg</option>
              <option value="Busspur">Busspur</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formOberflaeche">
            <Form.Label>Oberfläche</Form.Label>
            <Form.Control
              as="select"
              value={oberflaecheValue}
              onChange={(e) => setOberflaecheValue(e.target.value)}
            >
              <option value="">Bitte wählen</option>
              <option value="Asphalt">Asphalt</option>
              <option value="Kopfsteinpflaster">Kopfsteinpflaster</option>
              <option value="Beton">Beton</option>
              <option value="Erde">Erde/Schüttuntergund</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formTempolimit">
            <Form.Label>Tempolimit</Form.Label>
            <Form.Control
              as="select"
              value={tempolimitValue}
              onChange={(e) => setTempolimitValue(e.target.value)}
            >
              <option value="">Bitte wählen</option>
              <option value="Spielstraße">Spielstraße</option>
              <option value="30">30 km/h</option>
              <option value="40">40 km/h</option>
              <option value="50">50 km/h</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Schließen
        </Button>
        <Button variant="primary" onClick={handleFormSubmit}>
          Änderungen speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SegmenteEditComponent;