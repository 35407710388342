import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {carIcon} from "../../functions/Icons";
import {format} from "date-fns";
import {fetchCarStationsData} from "../../functions/MapFunctions";

function CarCountingLayer({carChecked, carStations, sD, eD, mapBounds, loadingSpin, updateMapBounds, updateCarStations,
                              updateLoadingSpin}) {

    const useCarStationsData = useCallback(() => {
        fetchCarStationsData(sD, eD, mapBounds, updateMapBounds, updateCarStations, updateLoadingSpin);
    }, [sD, eD, mapBounds]);

    useEffect(() => {
        if (carChecked) {
            useCarStationsData();
        } else {
            updateCarStations({});
        }
    }, [sD, eD, mapBounds, carChecked]);

    return (
        <LayersControl.Overlay name="8: Auto Zählstellen" checked={carChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(carStations).map(stationId => {
                    const station = carStations[stationId];

                    return (
                        <Marker key={stationId} position={[station.latitude, station.longitude]} icon={carIcon}>
                            <Popup>
                                <strong>Zeitraum: </strong>{format(sD, "dd.MM.yy")} bis {format(eD, "dd.MM.yy")}<br/>
                                <strong>ID:</strong> {stationId}<br/><br/>
                                <strong>Verkehrsaufkommen:</strong> {station.sum_traffic_volume ? station.sum_traffic_volume.toFixed(0) : 0}<br/>
                                <strong>Verkehrsaufkommen/h:</strong> {station.hour_avg_traffic_volume ? station.hour_avg_traffic_volume.toFixed(0) : 0} <br/>
                                <strong>Max. Verkehrsaufkommen/h:</strong> {station.max_traffic_volume ? station.max_traffic_volume.toFixed(0) : 0} <br/>
                                <strong>Verkehrsaufkommen/Tag:</strong> {station.day_avg_traffic_volume ? station.day_avg_traffic_volume.toFixed(0) : 0} <br/>
                                <strong>Verkehrsaufkommen/Monat:</strong> {station.day_avg_traffic_volume ? station.day_avg_traffic_volume.toFixed(0) * 30 : 0} <br/><br/>

                                <strong>Durch. Geschwindigkeit:</strong> {station.avg_speed ? station.avg_speed.toFixed(2) : 0} km/h <br/>
                                <strong>Max. Geschwindigkeit:</strong> {station.max_speed ? station.max_speed.toFixed(2) : 0} km/h <br/><br/>
                            </Popup>
                        </Marker>
                    );
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default CarCountingLayer;