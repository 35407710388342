import {errorHandling} from "../functions/Errors";
import {
    accidentCountPeopleTime,
    accidentCountPeopleType, accidentPerpetrator,
    accidentTime,
    accidentTypes,
    avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount,
    moneyDamagePerAccidentPerpetrator
} from "../functions/ApiFunctions";
import {ApiConnect} from "../Dashboard";

export async function getAccidents(city, start, end, addBoxes) {
    Promise.all([
        ApiConnect.fetchAccidentTypes(city, [start, end]),
        ApiConnect.fetchAccidentTime(city, "month", [start, end]),
        ApiConnect.fetchAccidentCountPeopleType(city, [start, end]),
        ApiConnect.fetchAccidentCountPeopleTime(city, "month", [start, end]),
        ApiConnect.fetchAccidentPerpetrator(city, [start, end]),
        ApiConnect.fetchMoneyDamagePerAccidentPerpetrator(city, [start, end]),
        ApiConnect.fetchAvgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(city, [start, end])
    ])
        .then(([data1, data2, data3, data4, data5, data6, data7]) => {
            const box1 = accidentTypes(data1, city, start, end, "year", "accidents-type", "accidents");
            const box2 = accidentTime(data2, city, start, end, "month", "accidents-time", "accidents");
            const box3 = accidentCountPeopleType(data3, city, start, end, "year", "accidents-people-type", "accidents");
            const box4 = accidentCountPeopleTime(data4, city, start, end, "month", "accidents-people-time", "accidents");
            const box5 = accidentPerpetrator(data5, city, start, end, "year", "accidents-perpetrator", "accidents");
            const box6 = moneyDamagePerAccidentPerpetrator(data6, city, start, end, "year", "accidents-monetary-damage", "accidents");
            const box7 = avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(data7, city, start, end, "year", "avg-accidents-monetary-damage", "accidents");
            addBoxes([box1, box2, box3, box4, box5, box6, box7]);
        }).catch((error) => {
        errorHandling(error);
    });
}