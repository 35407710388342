import React from "react";
import {Button, ButtonGroup, Modal} from "react-bootstrap";

function MapLayer({ showLayerChoice, roadScoreChecked, nodeChecked, roadSegmentsChecked, heatmapsChecked, busChecked,
    busStopChecked, bikeChecked, carChecked, accidentChecked, perpetratorChecked, concernsChecked,
    reportChecked, roadGeometryChecked, infrastructureChecked, overlayEventSwitch, updateShowLayerChoice, updateLoadingSpin }) {

    const buttons = [
        { label: "1: Straßenscore", checked: roadScoreChecked },
        { label: "2: Knotenwerte", checked: nodeChecked },
        { label: "3: Straßeneinfärbungen", checked: roadSegmentsChecked },
        { label: "4: Heatmaps", checked: heatmapsChecked },
        { label: "5: Buslinien", checked: busChecked },
        { label: "6: Bushaltestellen", checked: busStopChecked },
        { label: "7: Rad Zählstellen", checked: bikeChecked },
        { label: "8: Auto Zählstellen", checked: carChecked },
        { label: "9: Unfallschwere", checked: accidentChecked },
        { label: "10: Unfallverursacher", checked: perpetratorChecked },
        { label: "11: Meldungen", checked: concernsChecked },
        { label: "12: RDD(Schadensmeldungen)", checked: reportChecked },
        { label: "13: Radweg Eigenschaften", checked: roadGeometryChecked },
        { label: "14: Kumulierte Segmentwerte", checked: infrastructureChecked }
    ];

    return (
        <Modal show={showLayerChoice} size={'lg'} scrollable animation autoFocus>
            <Modal.Header className='row'>
                <Modal.Title>Layer über Buttonklick ein-/ausblenden</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="infotext">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 192 512">
                        <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V448h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H64V256H32c-17.7 0-32-14.3-32-32z" />
                    </svg>
                    <p>: aktive Layer sind schwarz eingefärbt</p>
                </div>
                <ButtonGroup className="layerbuttonrow">
                    {buttons.map(button => (
                        <Button onClick={() => {
                            overlayEventSwitch(button.label);
                            if (!button.checked) {
                                updateLoadingSpin(true);
                            }
                        }}
                            className={`layerbutton ${button.checked ? "activebtn" : ""}`}>
                            {button.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => updateShowLayerChoice(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MapLayer;