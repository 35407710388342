import {dataNotAvailable, dataZero, errorHandling} from "./Errors";
import {createBoxData, sortByKey, sortByKeyAsc} from "./CommonFunctions";
import {
    getMonthShortcuts,
    getRoadConditionsTranslations,
    getWeatherConditionsTranslations,
    getTemperaturesTranslations, getTopics, getWeatherConditions, getTemperatures
} from "./Translations";
import {calculate_avg_factor} from "./Time";
import {ApiConnect} from "../Dashboard";
import {
    getLengthCategories,
    getLengthCategory,
    getSpeedCategories,
    getSpeedCategory,
    getTemperatureCategory
} from "./Categories";

export function loadStations(city, updateRoads) {
    ApiConnect.fetchBikeStations(city)
        .then((data) => {
            data = data.filter(function (arr) {
                return arr.city === city;
            });
            data = sortByKey(data, "road_name")
            updateRoads(data.map(a => a.road_name));
        }).catch((error) => {
        errorHandling(error);
    });
}

export function bikeTraffic(data, city, start, end, timeDimension, topic, overallTopic, road) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);

    data.forEach(date => {
        if ((date.sum_countfrom === 0 || date.sum_countto === 0) && !(date.sum_countfrom === 0 && date.sum_countto === 0)) {
            date.sum_bothdirections = null;
        } else {
            date.sum_bothdirections = Math.round(date.sum_bothdirections * avgFactor);
        }
        if (date.sum_countfrom === 0) {
            date.sum_countfrom = null;
        } else {
            date.sum_countfrom = Math.round(date.sum_countfrom * avgFactor);
        }
        if (date.sum_countto === 0) {
            date.sum_countto = null;
        } else {
            date.sum_countto = Math.round(date.sum_countto * avgFactor);
        }
    });

    data.forEach(date => delete Object.assign(date, {["Gesamt"]: date["sum_bothdirections"]})["sum_bothdirections"]);
    data.forEach(date => delete Object.assign(date, {["Richtung Innenstadt"]: date["sum_countto"]})["sum_countto"]);
    data.forEach(date => delete Object.assign(date, {["Aus der Innenstadt hinaus"]: date["sum_countfrom"]})["sum_countfrom"]);

    return createBoxData(["line", data, timeDimension, ["Richtung Innenstadt", "Aus der Innenstadt hinaus", "Gesamt"],
        'Fahrrad-Verkehrsaufkommen ' + road, city, overallTopic, topic, road, timeDimension, start, end,
        "Kennzahlen", false, false, false, []]);
}

export function bikeTrafficAllStreets(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data === null) {
        dataNotAvailable();
        return;
    }

    data.forEach(date => {
        let sum_sorting = 0;
        if (date.sum_countfrom === 0 && date.sum_countto === 0) {
            date.sum_countfrom = null;
            date.sum_countto = null;
            sum_sorting += date.sum_bothdirections;
        }
        if ((date.sum_countfrom !== 0 || date.sum_countto !== 0) && !(date.sum_countfrom === null && date.sum_countto === null)) {
            date.sum_bothdirections = null;
            sum_sorting += date.sum_countfrom;
            sum_sorting += date.sum_countto;
        }
        if (date.sum_countfrom === 0) {
            date.sum_countfrom_approx = date.sum_countto;
            date.sum_countfrom = null;
            sum_sorting += date.sum_countfrom_approx;
            sum_sorting += date.sum_countfrom;
        }
        date.sum_sorting = sum_sorting;
    });

    data = sortByKeyAsc(data, "sum_sorting");
    data.forEach(date => date.road_name = date.road_name.replace(" ", "_"));
    data.forEach(date => delete Object.assign(date, {["Richtung Innenstadt"]: date["sum_countto"]})["sum_countto"]);
    data.forEach(date => delete Object.assign(date, {["Aus der Innenstadt hinaus"]: date["sum_countfrom"]})["sum_countfrom"]);
    data.forEach(date => delete Object.assign(date, {["Beide Richtungen"]: date["sum_bothdirections"]})["sum_bothdirections"]);
    data.forEach(date => delete Object.assign(date, {["Aus der Innenstadt hinaus approximiert"]: date["sum_countfrom_approx"]})["sum_countfrom_approx"]);

    return createBoxData(["bar-stacked", data, "road_name", ["Richtung Innenstadt", "Aus der Innenstadt hinaus",
        "Aus der Innenstadt hinaus approximiert", "Beide Richtungen"], "Fahrrad-Verkehrsaufkommen Zählstellenvergleich",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function concernsType(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    return createBoxData(["pie", data, "category_text", "count_", "Meldungstypen", city, overallTopic,
        topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function concernsTypeProblem(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    data.forEach(date => {
        if (date.topic_text === "Defekte Straßenbeleuchtung/Ampeln") {
            date.topic_text = "Beleuchtung/Ampeln";
        } else if (date.topic_text === "Herabfallende Gegenstände") {
            date.topic_text = "Herabfallendes";
        } else if (date.topic_text === "None") {
            date.topic_text = "Andere";
        } else if (date.topic_text === "Fahrbahnmarkierungen/Verkehrsschilder") {
            date.topic_text = "Markierungen/Schilder";
        }
    })

    data.forEach(date => date.topic_text = date.topic_text.replace(" ", "_"));
    sortByKeyAsc(data, "count_")
    data.forEach(date => delete Object.assign(date, {["Anzahl Meldungen"]: date["count_"]})["count_"]);

    return createBoxData(["bar", data, "topic_text", ["Anzahl Meldungen"], "Meldungen: erkannte Probleme",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function concernsTime(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    data.forEach(date => delete Object.assign(date, {["Anzahl Meldungen"]: date["count_"]})["count_"]);

    return createBoxData(["line", data, timeDimension, ["Anzahl Meldungen"], "Zeitverteilung von Meldungen",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function concernsDuration(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    let newData = [];

    data.forEach(date => {
        if (date.avg_duration != null) {
            date.avg_duration = parseInt(date.avg_duration.substring(0, date.avg_duration.indexOf("day") - 1));
            newData.push(date);
        }
    });

    data = newData;
    data.forEach(date => date.category_text = date.category_text.replace(" ", "_"));
    data.forEach(date => date.category_text = date.category_text.replace(" ", "_"));
    data = sortByKeyAsc(data, "avg_duration");
    data.forEach(date => delete Object.assign(date, {["Anzahl Tage"]: date["avg_duration"]})["avg_duration"]);

    return createBoxData(["bar", data, "category_text", ["Anzahl Tage"], "Durchschnittliche Dauer bis Meldungen behoben",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentTypes(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    data.forEach(date => {
        if (date.euska_accident_type_simplified === null) {
            date.euska_accident_color_simplified = "eb34b4";
            date.euska_accident_type_simplified = "Andere";
        }
        if (date.euska_accident_type_simplified === "Überschreiten-Unfall") {
            date.euska_accident_color_simplified = "adadad";
        }
    });

    return createBoxData(["pie_new_colors", data, "euska_accident_type_simplified", "count_", "Unfalltypen",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentCountPeopleType(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    data.forEach(date => {
        if (date.euska_accident_type_simplified === null) {
            date.euska_accident_color_simplified = "eb34b4";
            date.euska_accident_type_simplified = "Andere";
        }
        if (date.euska_accident_type_simplified === "Überschreiten-Unfall") {
            date.euska_accident_color_simplified = "adadad";
        }
    });

    return createBoxData(["pie", data, "euska_accident_type_simplified", "sum_number_participants",
        "Unfallbeteiligte", city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentTime(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    let count = false;

    data.forEach(date => {
        if (date.count_ !== 0) {
            count = true
        }
    });

    if (count === false) {
        dataZero("Unfälle");
        return;
    }

    data.forEach(date => delete Object.assign(date, {["Anzahl Unfälle"]: date["count_"]})["count_"]);

    return createBoxData(["line", data, timeDimension, ["Anzahl Unfälle"], "Zeitverteilung von Unfällen",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentCountPeopleTime(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const euskaTypes = ["Fahrunfall", "Sonstiger Unfall", "Unfall im Längsverkehr", "Überschreiten-Unfall",
        "Einbiegen/Kreuzen bzw. Abbiege-Unfall", "Unfall durch ruhenden Verkehr", "null"];
    const avgFactor = calculate_avg_factor(timeDimension);
    const result = {};

    // Loop through the data to calculate the sums
    data.forEach((date) => {
        const month = date.month;
        const euskaType = date.euska_accident_type_simplified;
        const value = Math.round(date.sum_number_participants * avgFactor);

        // Initialize the month entry if it doesn't exist
        if (!result[month]) {
            result[month] = {};
        }

        // Initialize the euskaType entry if it doesn't exist
        if (!result[month][euskaType]) {
            result[month][euskaType] = 0;
        }

        // Add the value to the euskaType for the month
        result[month][euskaType] += value;
    });

    // Create an array of objects with the desired format
    const formattedResult = Object.keys(result).map((month) => ({
        month,
        ...result[month],
    }));

    if (data.every((date) => date.Anzahl_Beteiligte === 0)) {
        dataZero("Unfallbeteiligten");
        return;
    }

    data.forEach(date => date.sum_number_participants = Math.round(date.sum_number_participants * avgFactor));
    data.forEach(date => delete Object.assign(date, { ["Anzahl Beteiligte"]: date["sum_number_participants"] })["sum_number_participants"]);

    return createBoxData(["line", formattedResult, "month", euskaTypes, "Zeitverteilung von Unfallbeteiligten",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    return createBoxData(["pie", data, "perpetrator_german", "count_", "Unfallverursacher", city,
        overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentGround(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const roadConditionsTranslations = getRoadConditionsTranslations();

    data.forEach((item) => {
        if (item.euska_road_condition && roadConditionsTranslations[item.euska_road_condition]) {
            item.euska_road_condition = roadConditionsTranslations[item.euska_road_condition];
        } else {
            item.euska_road_condition = "keine Angabe";
        }
    });

    return createBoxData(["pie", data, "euska_road_condition", "count_", "Unfälle nach Oberfläche",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentWeather(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const weatherConditionsTranslations = getWeatherConditionsTranslations();
    const transformedData = {};
    const weatherTypes = ["Klarer Himmel", "Wolkig", "Regen", "Schnee"];

    data.forEach((item) => {
        const {month, condition, count_} = item;
        const translatedCondition = weatherConditionsTranslations[condition] || condition;

        if (!transformedData[month]) {
            transformedData[month] = {};
        }

        transformedData[month][translatedCondition] = count_;
    });

    // Create the transformedDataArray after the forEach loop
    const transformedDataArray = Object.entries(transformedData).map(([month, conditions]) => ({
        name: month, // This will be displayed on the X-axis
        ...conditions, // Include the weather conditions data
    }));

    transformedDataArray.sort((a, b) => parseInt(a.name) - parseInt(b.name));

    return createBoxData(["stacked-area", transformedDataArray, "name", weatherTypes, "Unfälle nach Witterung",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function accidentTemperature(dataMinus10, dataTo0, dataTo10, dataTo20, dataTo30, dataHigher30,
                                    city, start, end, timeDimension, topic, overallTopic) {
    const newData = {};
    const addNewData = (item, temp) => {
        const {month, count_} = item;
        if (!newData[month]) {
            newData[month] = {};
        }
        newData[month][temp] = count_;
    }

    dataMinus10.forEach(item => addNewData(item, "unter -10"));
    dataTo0.forEach(item => addNewData(item, "-10 bis -1"));
    dataTo10.forEach(item => addNewData(item, "0 bis 10"));
    dataTo20.forEach(item => addNewData(item, "10 bis 20"));
    dataTo30.forEach(item => addNewData(item, "21 bis 30"));
    dataHigher30.forEach(item => addNewData(item, "über 30"));

    const temperature = ["über 30", "21 bis 30", "10 bis 20", "0 bis 10", "-10 bis -1", "unter -10"];

    // Create the transformedDataArray after the forEach loop
    const transformedDataArray = Object.entries(newData).map(([month, temp]) => ({
        name: month, // This will be displayed on the X-axis
        ...temp, // Include the weather conditions data
    }));

    const monthShortcuts = getMonthShortcuts();

    const translatedDataArray = transformedDataArray.map((item) => ({
        ...item,
        name: monthShortcuts[item.name] || item.name,
    }));

    translatedDataArray.sort((a, b) => parseInt(a.name) - parseInt(b.name));

    return createBoxData(["stacked-area", translatedDataArray, "name", temperature, "Unfälle nach Temperatur",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function moneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);

    let processedData = data.map(item => {
        if (item.sum_cognos_monetary_damage !== undefined && item.sum_cognos_monetary_damage !== null) {
            item.sum_cognos_monetary_damage = parseFloat(item.sum_cognos_monetary_damage).toFixed(2);
        }
        item.sum_cognos_monetary_damage = Math.round(item.sum_cognos_monetary_damage * avgFactor);
        //werte nach Volkswirtschaftliche Kosten von Straßenverkehrsunfällen in Deutschland - BAST 2022
        item.sum_slightly_injured = (item.sum_slightly_injured*5904).toFixed(2);
        item.sum_serious_injured = (item.sum_serious_injured*130453 ).toFixed(2);
        item.sum_killed = (item.sum_killed*1248970).toFixed(2);
        item.sum = item.sum_cognos_monetary_damage + item.sum_slightly_injured + item.sum_serious_injured + item.sum_killed;
        return {
            total : parseFloat(item.sum),
            perpetrator_german: item.perpetrator_german,
            Sachschaden: parseFloat(item.sum_cognos_monetary_damage),
            leichte_Verletzung: parseFloat(item.sum_slightly_injured),
            schwere_Verletzung: parseFloat(item.sum_serious_injured),
            Tod: parseFloat(item.sum_killed)
            
        };
    });
    processedData = sortByKeyAsc(processedData, 'total');
    console.log(processedData);

    return createBoxData(["bar-stacked-normal", processedData, "perpetrator_german", ["Sachschaden","leichte_Verletzung","schwere_Verletzung","Tod"], "Personen-/Sachschaden in € pro Verursacher (Absolut)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function AvgMoneyDamagePerAccidentPerpetratorWithPeopleDamage(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);

    let processedData = data.map(item => {
        if (item.sum_cognos_monetary_damage !== undefined && item.sum_cognos_monetary_damage !== null) {
            item.sum_cognos_monetary_damage = parseFloat(item.sum_cognos_monetary_damage).toFixed(2);
        }
    
        item.sum_cognos_monetary_damage = Math.round(item.sum_cognos_monetary_damage * avgFactor);
        //werte nach Volkswirtschaftliche Kosten von Straßenverkehrsunfällen in Deutschland - BAST 2022
        item.sum_slightly_injured = (item.sum_slightly_injured*5904);
        item.sum_serious_injured = (item.sum_serious_injured*130453);
        item.sum_killed = (item.sum_killed*1248970).toFixed(2);
        item.sum = item.sum_cognos_monetary_damage + item.sum_slightly_injured + item.sum_serious_injured + item.sum_killed;
        return {
            total : parseFloat(item.sum),
            perpetrator_german: item.perpetrator_german,
            Sachschaden: parseFloat(item.sum_cognos_monetary_damage/item.count_number_participants).toFixed(0),
            leichte_Verletzung: parseFloat(item.sum_slightly_injured/item.count_number_participants).toFixed(0),
            schwere_Verletzung: parseFloat(item.sum_serious_injured/item.count_number_participants).toFixed(0),
            Tod: parseFloat(item.sum_killed/item.count_number_participants).toFixed(0)
            
        };
    });
    processedData = sortByKeyAsc(processedData, 'total');
    console.log(processedData);

    return createBoxData(["bar-stacked-normal", processedData, "perpetrator_german", ["Sachschaden","leichte_Verletzung","schwere_Verletzung","Tod"], "Personen-/Sachschaden in € pro Verursacher (Mittel)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function moneyDamagePerAccidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);

    const processedData = data.map(item => {
        if (item.sum_cognos_monetary_damage !== undefined && item.sum_cognos_monetary_damage !== null) {
            item.sum_cognos_monetary_damage = parseFloat(item.sum_cognos_monetary_damage).toFixed(2);
        }
        item.sum_cognos_monetary_damage = Math.round(item.sum_cognos_monetary_damage * avgFactor);
        return {
            perpetrator_german: item.perpetrator_german,
            sum_cognos_monetary_damage: item.sum_cognos_monetary_damage,
        };
    });
   
    return createBoxData(["bar", processedData, "perpetrator_german", ["sum_cognos_monetary_damage"], "Sachschaden in € pro Verursacher (Absolut)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}
export function damagePerAccidentPerpetrator(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);

    const processedData = data.map(item => {
        if (item.sum_cognos_monetary_damage !== undefined && item.sum_cognos_monetary_damage !== null) {
            item.sum_cognos_monetary_damage = parseFloat(item.sum_cognos_monetary_damage).toFixed(2);
        }
        item.sum_cognos_monetary_damage = Math.round(item.sum_cognos_monetary_damage * avgFactor);
        return item;
    });


    return createBoxData(["pie", processedData, "perpetrator_german", ["count_"], "Sachschaden in € (Absolut)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function avgMoneyDamagePerAccidentPerpetratorAndPerAccidentcount(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

    const avgFactor = calculate_avg_factor(timeDimension);
    
    const processedData = data.map(item => {
        if (item.sum_cognos_monetary_damage !== undefined && item.sum_cognos_monetary_damage !== null) {
            item.sum_cognos_monetary_damage = parseFloat(item.sum_cognos_monetary_damage).toFixed(2);
        }
        item.sum_cognos_monetary_damage = Math.round(item.sum_cognos_monetary_damage * avgFactor);
        item.avgMoneyDamagePerParticipant = parseFloat((item.sum_cognos_monetary_damage / item.count_number_participants).toFixed(0));
        return {
            perpetrator_german: item.perpetrator_german,
            avgMoneyDamagePerParticipant: item.avgMoneyDamagePerParticipant
        };
    });

    return createBoxData(["bar", processedData, "perpetrator_german", ["avgMoneyDamagePerParticipant"], "Schaden in € pro Verursacher (Mittel)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function streetCountAccidentsTop10(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }
    data.sort((a, b) => b.count_ - a.count_);
    const top10Data = data.slice(0, 10);

    return createBoxData(["bar", top10Data, "road_name", ["count_"], "Unfälle gesamt nach Straße",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function streetCountAccidentsTop10LengthSensitive(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }

     // Berechnung der Anzahl der Tage zwischen Start und Ende
     const startDate = new Date(start);
     const endDate = new Date(end);
     const timeDiff = Math.abs(endDate - startDate);
     const numDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1; // +1 um den letzten Tag einzuschließen

    data.forEach(item => {
        if (item.length && item.length !== 0) {
            item.index = (item.count_ / item.length);
        } else {
            item.index = 0;
        }
    });

    data.sort((a, b) => b.count_ - a.count_);

    const top10Data = data.slice(0, 10);

    return createBoxData(["bar", top10Data, "road_name", ["index"], "Unfällhäufungsindex (Unfälle/Straßenlänge)",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function biketrafficAccidents(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }
    data.sort((a, b) => b.unfallrate - a.unfallrate);
    const top10Data = data.slice(0, 10);

    return createBoxData(["bar", top10Data, "road_name", ["unfallrate"], "Unfallrate(Rad) gemäß BASt",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function cartrafficAccidents(data, city, start, end, timeDimension, topic, overallTopic) {
    if (data == null) {
        dataNotAvailable();
        return;
    }
    data.sort((a, b) => b.unfallrate - a.unfallrate);
    const top10Data = data.slice(0, 10);

    return createBoxData(["bar", top10Data, "road_name", ["unfallrate"], "Unfallrate(Auto-Adaption) gemäß BASt",
        city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function ridesCount(dataWeather, dataRides, city, start, end, timeDimension, topic, overallTopic, condition) {
    let newData = {};

    let categories = [];

    switch (condition) {
        case "weather":
            categories = getWeatherConditions();
            break;
        case "temperature":
            categories = getTemperatures();
            break;
    }

    for (let i=0; i < categories.length; i++) {
        newData[categories[i]] = {};
    }
    const addNewData = (condition, attribute, value) => {
        if (!newData[condition]) {
            newData[condition] = {};
        }

        if (!newData[condition][attribute]) {
            newData[condition][attribute] = value;
        } else {
            newData[condition][attribute] += value;
        }
    }

    let conditions = {};

    switch (condition) {
        case "weather":
            dataWeather.forEach((item) => addNewData(item.condition, "count_days", 1));
            dataRides.forEach((item) => addNewData(item.condition, "count_rides", 1));
            conditions = getWeatherConditionsTranslations();
            break;
        case "temperature":
            dataWeather.forEach((item) => addNewData(getTemperatureCategory(item.temp), "count_days", 1));
            dataRides.forEach((item) => addNewData(getTemperatureCategory(item.temp), "count_rides", 1));
            conditions = getTemperaturesTranslations();
            break;
    }

    let transformedDataArray = Object.entries(newData).map(([condition, rest]) => ({
        condition: conditions[condition],
        ...rest,
    }));

    const filterValue = (value) => {
        if (value === undefined || value === null || isNaN(value)) {
            return 0;
        } else {
            return value;
        }
    }

    transformedDataArray = transformedDataArray.map(item => ({
        condition: item.condition,
        count_rides: filterValue(item.count_rides),
        count_days: filterValue((item.count_days / 96).toFixed(1)),
        normalized_rides: filterValue((item.count_rides / (item.count_days / 96)).toFixed(1))
    }));

    transformedDataArray = transformedDataArray.filter(item => item.condition !== undefined && item.condition !== null);

    const topics = getTopics();

    return createBoxData(["bar-several", transformedDataArray, "condition", ["normalized_rides", "count_days"],
        topics[topic], city, overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}

export function ridesCondition(data, city, start, end, timeDimension, topic, overallTopic, attribute, condition) {
    let newData = {};

    let categories = [];

    switch (attribute) {
        case "length":
            categories = getLengthCategories();
            break;
        case "speed":
            categories = getSpeedCategories();
            break;
    }

    for (let i=0; i < categories.length; i++) {
        newData[categories[i]] = {};
    }

    let conditionsTranslations = {};

    switch (condition) {
        case "weather":
            conditionsTranslations = getWeatherConditionsTranslations();
            break;
        case "temperature":
            conditionsTranslations = getTemperaturesTranslations();
            break;
    }

    const count = (item, attribute, condition) => {
        let category = "";
        let itemCondition = "";

        switch (condition) {
            case "weather":
                itemCondition = item.condition;
                break;
            case "temperature":
                itemCondition = getTemperatureCategory(item.temp);
                break;
        }

        switch (attribute) {
            case "length":
                category = getLengthCategory(item.length);
                break;
            case "speed":
                category = getSpeedCategory(item.average_speed);
                break;
        }
        itemCondition = conditionsTranslations[itemCondition];

        if (!newData[category][itemCondition]) {
            newData[category][itemCondition] = 0;
        }
        newData[category][itemCondition] += 1;
    }

    if (data !== null) {
        data.forEach(item => count(item, attribute, condition));
    }

    let transformedDataArray = Object.entries(newData).map(([category, rest]) => ({
        category: category,
        ...rest,
    }));

    let conditions = Object.entries(conditionsTranslations).map(([, value]) => (value));
    conditions = conditions.filter((item) => (item !== undefined));

    const topics = getTopics();

    return createBoxData(["line", transformedDataArray, "category", conditions, topics[topic], city,
        overallTopic, topic, "", timeDimension, start, end, "Kennzahlen", false, false, false, []]);
}
