import React, { useState, useEffect } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import { Modal, Button } from "react-bootstrap";
import proj4 from 'proj4';

proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");

const ImportSegment = ({
  segment,
  mapBounds,
  setSegments,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments,
  importModalOpen,
  setImportModalOpen,
  selectedRSCategories
}) => {
  const [geojsonData, setGeojsonData] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0); // Zustand für den Upload-Fortschritt
  const [error, setError] = useState(null);

  const inputProjection = 'EPSG:25832';
  const outputProjection = 'EPSG:4326';

  // Function to convert and switch coordinates
  const convertCoordinates = (coordinates) => {
    return coordinates.map(coord => {
      if (typeof coord[0] !== 'number' || typeof coord[1] !== 'number') {
        console.error("Invalid coordinate:", coord);
        return null;
      }
      // Convert coordinates and switch them
      //const [lng, lat] = proj4(inputProjection, outputProjection, [coord[0], coord[1]]);
      return [coord[1], coord[0]]; // Return in [longitude, latitude] order
    });
  };



  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      const chunkSize = 100; // Anzahl der Features pro Chunk
      let featuresBuffer = []; // Puffer, um Features vor der Verarbeitung zu speichern

      reader.onload = (e) => {
        const content = e.target.result;
        console.log("Nicht geparst:", content);

        let geojson;
        try {
          geojson = JSON.parse(content);
          console.log(content)
        } catch (error) {
          console.error("Fehler beim Parsen von JSON:", error);
          return;
        }

        // Überprüfen, ob das geparste Objekt ein gültiges Features-Array hat
        if (!geojson.features || !Array.isArray(geojson.features)) {
          console.error("Ungültige GeoJSON-Datei: Fehlendes oder ungültiges Features-Array.");
          return;
        }

        console.log("Start:", geojson.features);

          // Filter features by map bounds
      const filteredFeatures = geojson.features.filter(feature => {
        if (feature.geometry && feature.geometry.type === "LineString") {
          return feature.geometry.coordinates.some(coord => {
            const [lng, lat] = coord;
            return (
              lat >= mapBounds.latmin &&
              lat <= mapBounds.latmax &&
              lng >= mapBounds.lngmin &&
              lng <= mapBounds.lngmax
            );
          });
        }
        return false;
      });

      // Process features in chunks
      for (let i = 0; i < filteredFeatures.length; i++) {
        featuresBuffer.push(filteredFeatures[i]);

        if (featuresBuffer.length === chunkSize || i === filteredFeatures.length - 1) {
          const processedFeatures = processFeatures(featuresBuffer);
          setGeojsonData(prevData => [...prevData, ...processedFeatures]);
          featuresBuffer = [];
        }
      }
    };


      reader.readAsText(file);
    } else {
      console.error("Keine Datei ausgewählt.");
    }
  };

  const processFeatures = (features) => {
  
    // Ensure features is an array
    if (!Array.isArray(features)) {
      console.error("Expected an array of features");
      return [];
    }
  
    // Merge features by 'grid_id'
    const mergedByGridId = mergeFeaturesById(features, 'grid_id');
  
    return mergedByGridId.map(feature => {
      if (feature.geometry && Array.isArray(feature.geometry.coordinates)) {
        feature.geometry.coordinates = feature.geometry.coordinates.map(coord => {
          return [coord[1], coord[0]]; // [lng, lat] in [lat, lng] umwandeln
        });
      } else {
        console.error("Ungültige Geometrie oder Koordinaten für Feature:", feature);
      }
      return feature; // Das modifizierte Feature zurückgeben
    });
  };
  
  // Function to merge features by a given ID key
  const mergeFeaturesById = (features, idKey) => {
    const mergedFeatures = {};
  
    features.forEach(feature => {
      const id = feature.properties[idKey];
      if (!id) return; // Skip if there's no ID
  
      if (!mergedFeatures[id]) {
        // Initialize a new feature if it doesn't exist
        mergedFeatures[id] = {
          type: "Feature",
          geometry: feature.geometry,
          properties: { ...feature.properties }
        };
      } else {
        // Merge properties
        const existingFeature = mergedFeatures[id];
        for (const key in feature.properties) {
          if (key === idKey) continue; // Skip the ID key
  
          const value = feature.properties[key];
          const existingValue = existingFeature.properties[key];
  
          if (typeof value === 'number' && value !== null) {
            // Calculate average for numerical values
            if (existingValue === null || existingValue === undefined) {
              existingFeature.properties[key] = value;
            } else {
              existingFeature.properties[key] = (existingValue + value) / 2;
            }
          } else if (typeof value === 'string') {
            // Concatenate strings into a list
            if (!Array.isArray(existingValue)) {
              existingFeature.properties[key] = existingValue ? [existingValue] : [];
            }
            if (value) {
              existingFeature.properties[key].push(value);
            }
          }
        }
      }
    });
    console.log(mergedFeatures)
    return Object.values(mergedFeatures);
  };

  // Protokollieren der aktualisierten geojsonData, wann immer sie sich ändert
  useEffect(() => {
    console.log("Vollständig importiert:", geojsonData);
  }, [geojsonData]);

  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };

  return (
    <>
      {/* Import Modal */}
      <Modal
        show={importModalOpen}
        onHide={() => setImportModalOpen(false)}
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Optionen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>GeoJson importieren</p>
          <input
            type="file"
            accept=".geojson"
            onChange={handleFileUpload}
          />
          <Button className="w-100" onClick={() => setImportModalOpen(false)}>
            Import abschließen
          </Button>
        </Modal.Body>
      </Modal>

      {geojsonData.map((feature, index) => {
        // Ensure that the feature has geometry
        if (feature.geometry) {
          if (feature.geometry.type === "LineString") {
            // Ensure that feature has valid geometry and coordinates
            if (Array.isArray(feature.geometry.coordinates)) {
              return (
                <Polyline
                key={`${feature.properties.road_id || 'no-road-id'}-${feature.properties.grid_id || 'no-grid-id'}-${index}`}
                  positions={feature.geometry.coordinates} // Ensure coordinates are converted from [lng, lat] to [lat, lng]
                  className="hoversegment"
                  color={"orange"}
                  weight={3}
                  fill={false}
                  opacity={1}
                >
                  <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                    <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                      <thead>
                        <tr>
                          <th>Attribut</th>
                          <th>Wert</th>
                        </tr>
                      </thead>
                      <tbody>
                        {feature.properties.road_id !== null && (
                          <tr>
                            <td><strong>Road ID</strong></td>
                            <td>{feature.properties.road_id}</td>
                          </tr>
                        )}
                        {feature.properties.grid_id !== null && (
                          <tr>
                            <td><strong>Grid ID</strong></td>
                            <td>{feature.properties.grid_id}</td>
                          </tr>
                        )}
                        {feature.properties.avg_anomaly_v1 !== undefined && (
                          <tr>
                            <td><strong>Avg Anomaly V1</strong></td>
                            <td>{feature.properties.avg_anomaly_v1}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("avg_anomaly_v2") && feature.properties.avg_anomaly_v2 !== undefined && (
                          <tr>
                            <td><strong>Avg Anomaly V2</strong></td>
                            <td>{feature.properties.avg_anomaly_v2.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("frequency") && feature.properties.frequency !== undefined && (
                          <tr>
                            <td><strong>Frequency</strong></td>
                            <td>{feature.properties.frequency.toFixed(0)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("avg_v_score_v1") && feature.properties.avg_v_score_v1 !== undefined && (
                          <tr>
                            <td><strong>Avg V-Score V1</strong></td>
                            <td>{feature.properties.avg_v_score_v1.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("avg_v_score_v2") && feature.properties.avg_v_score_v2 !== undefined && (
                          <tr>
                            <td><strong>Avg V-Score V2</strong></td>
                            <td>{feature.properties.avg_v_score_v2.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("absolute_speed") && feature.properties.absolute_speed !== undefined && (
                          <tr>
                            <td><strong>Absolute Speed</strong></td>
                            <td>{feature.properties.absolute_speed.toFixed(2)} km/h</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("normalized_speed") && feature.properties.normalized_speed !== undefined && (
                          <tr>
                            <td><strong>Normalized Speed</strong></td>
                            <td>{feature.properties.normalized_speed.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("waiting_time") && feature.properties.waiting_time !== undefined && (
                          <tr>
                            <td><strong>Waiting Time</strong></td>
                            <td>{feature.properties.waiting_time.toFixed(2)} s</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("full_brakings") && feature.properties.full_brakings !== null && (
                          <tr>
                            <td><strong>Full Brakings</strong></td>
                            <td>{feature.properties.full_brakings !== null ? feature.properties.full_brakings : 'N/A'}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("anomaly") && feature.properties.anomaly !== undefined && (
                          <tr>
                            <td><strong>Anomaly</strong></td>
                            <td>{feature.properties.anomaly.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("v_score_v1") && feature.properties.v_score_v1 !== undefined && (
                          <tr>
                            <td><strong>V-Score V1</strong></td>
                            <td>{feature.properties.v_score_v1.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("v_score_v2") && feature.properties.v_score_v2 !== undefined && (
                          <tr>
                            <td><strong>V-Score V2</strong></td>
                            <td>{feature.properties.v_score_v2.toFixed(2)}</td>
                          </tr>
                        )}
                        {selectedRSCategories.includes("time_loss") && feature.properties.time_loss !== undefined && (
                          <tr>
                            <td><strong>Time Loss</strong></td>
                            <td>{feature.properties.time_loss.toFixed(2)} s</td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </Tooltip>
                </Polyline>
              );
            } else {
              console.error("Invalid coordinates for feature:", feature);
            }
          }
        } else {
          console.error("Feature does not have valid geometry:", feature);
        }
        return null;
      })}
    </>
  )
}
export default ImportSegment;