import React from "react";
import {filterInfrastructureOptions} from "../../functions/FilterFunctions";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";

function FilterInfrastructure({infrastructureState, updateInfrastructureState, updateLoadingSpin}) {

    return (
        <div>
            {filterInfrastructureOptions.map((option, index) => (
                <button key={index} className={`col-12 filterBtn ${infrastructureState === option.keyword ? "selected" : ""}`}
                        onClick={() => { updateInfrastructureState(option.keyword); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container">
                                <img src={infrastructureState === option.keyword ? checked : unchecked}
                                     alt={infrastructureState === option.keyword ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {option.value}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterInfrastructure;