import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import {fetchInfrastructure} from "../../functions/MapFunctions";
import {
    getColorClassElevation,
    getPathTypeColorClass,
    getSurfaceTypeColorClass,
    getWidthColorClass
} from "../../functions/Colors";

function InfrastructureLayer({infrastructureState, infrastructureChecked, infrastructureData, mapBounds, loadingSpin,
                               updateInfrastructureData, updateLoadingSpin}) {

    const useRoadGeometry = useCallback(() => {
        fetchInfrastructure(mapBounds, updateInfrastructureData, updateLoadingSpin)
    }, [mapBounds]);

    useEffect(() => {
        if (infrastructureChecked) {
            useRoadGeometry();
        } else {
            updateInfrastructureData({});
        }
    }, [mapBounds, infrastructureChecked]);

    return (
        <LayersControl.Overlay name="13: Radweg Eigenschaften" checked={infrastructureChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(infrastructureData).map((i) => {
                    const road = infrastructureData[i];

                    if (infrastructureState === "Slope" && road.slope !== null) {
                        return (
                            <Polyline key={i} positions={road.geometry.coordinates} color={getColorClassElevation(road.slope)}
                                      opacity={1} weight={2} fill={false}>
                                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                    {"Steigung: " + (road.slope ? road.slope.toFixed(2) : "k.A.") + " %"}
                                </Tooltip>
                            </Polyline>
                        );
                    }

                    if (infrastructureState === "PathType" && (road.path_type !== null || road.path !== null)) {
                        return (
                            <Polyline key={i} positions={road.geometry.coordinates} color={getPathTypeColorClass(road.path_type, road.path)}
                                      opacity={1} weight={2} fill={false}>
                                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                    {"Führungsform: " + (road.path_type ? road.path_type : road.path ? road.path : "k.A.")}
                                </Tooltip>
                            </Polyline>
                        );
                    }

                    if (infrastructureState === "SurfaceType" && road.surface_type !== null) {
                        return (
                            <Polyline key={i} positions={road.geometry.coordinates} color={getSurfaceTypeColorClass(road.surface_type)}
                                      opacity={1} weight={2} fill={false}>
                                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                    {"Oberflächenart: " + (road.surface_type ? road.surface_type : "k.A.")}
                                </Tooltip>
                            </Polyline>
                        );
                    }

                    if (infrastructureState === "Width" && road.width !== null) {
                        return (
                            <Polyline key={i} positions={road.geometry.coordinates} color={getWidthColorClass(road.width)}
                                      opacity={1} weight={2} fill={false}>
                                <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                                    {"Radwegbreite: " + (road.width ? road.width : "k.A.")}
                                </Tooltip>
                            </Polyline>
                        );
                    }
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default InfrastructureLayer;