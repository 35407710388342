import React, { useState } from "react"; // Importieren von React und useState-Hook
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap"; // Importieren von Bootstrap-Komponenten
import { translationOsmValues } from "../dashboard/functions/Translations"; // Importieren der Übersetzungsfunktion

// Filterkomponente
const FilterComponent = ({ 
  filters, // Aktuelle Filter
  setFilters, // Funktion zum Setzen der Filter
  filterModalOpen, // Zustand für die Sichtbarkeit des Filtermodals
  setFilterModalOpen, // Funktion zum Schließen des Filtermodals
  entries, // Einträge, die gefiltert werden sollen
  setEntries // Funktion zum Setzen der gefilterten Einträge
}) => {
  const [filterRules, setFilterRules] = useState([]); // Zustand für die Filterregeln
  const [activeRuleIndex, setActiveRuleIndex] = useState(null); // Zustand für den aktuell aktiven Regelindex

  // Übersetzungsfunktionsreferenz
  const translations = translationOsmValues();

  // Funktion zur Übersetzung von Werten
  const translateValue = (category, value) => {
    if (!value || value === '-') return '-'; // Rückgabe von '-' für leere Werte
    return translations[category] && translations[category][value] ? translations[category][value] : value; // Rückgabe der Übersetzung oder des ursprünglichen Wertes
  };

  // Funktion zur Übersetzung von Kategorien
  const translateCategory = (category) => {
    const categoryTranslations = {
      highway: "Wegtyp",
      surface: "Oberfläche",
      cycleway: "Führungsform",
      maxspeed: "Tempolimit",
      rcn: "Regionale Radroute",
      bicycle_road: "Fahrradstraße"
    };
    return categoryTranslations[category] || category; // Rückgabe der Übersetzung oder der ursprünglichen Kategorie
  };

  // Funktion zum Hinzufügen einer neuen Filterregel
  const addFilterRule = () => {
    setFilterRules([...filterRules, { category: '', selectedEntries: [] }]); // Neue Regel hinzufügen
    setActiveRuleIndex(filterRules.length); // Setzen des aktiven Regelindex auf die neue Regel
  };

  // Funktion zur Handhabung der Kategorieänderung
  const handleCategoryChange = (index, value) => {
    const newRules = [...filterRules]; // Kopieren der aktuellen Regeln
    newRules[index].category = value; // Setzen der neuen Kategorie
    newRules[index].selectedEntries = []; // Zurücksetzen der ausgewählten Einträge
    setFilterRules(newRules); // Aktualisieren der Filterregeln
  };

  // Funktion zur Handhabung der Eintragsänderung
  const handleEntryChange = (index, entry) => {
    const newRules = [...filterRules]; // Kopieren der aktuellen Regeln
    const selectedEntries = newRules[index].selectedEntries; // Ausgewählte Einträge der aktuellen Regel

    // Hinzufügen oder Entfernen des Eintrags aus den ausgewählten Einträgen
    if (selectedEntries.includes(entry)) {
      newRules[index].selectedEntries = selectedEntries.filter(e => e !== entry); // Eintrag entfernen
    } else {
      newRules[index].selectedEntries.push(entry); // Eintrag hinzufügen
    }

    setFilterRules(newRules); // Aktualisieren der Filterregeln
  };

  // Funktion zum Schließen des Filtermodals
  const handleFilterModalClose = () => {
    setFilterModalOpen(false); // Setzen des Modals auf geschlossen
  };

  // Funktion zum Anwenden der Filter
  const applyFilters = () => {
    const newFilters = { ...filters }; // Kopieren der aktuellen Filter
    filterRules.forEach(rule => {
      if (rule.category) {
        newFilters[rule.category] = rule.selectedEntries.join(', '); // Setzen der ausgewählten Einträge für die Kategorie
      }
    });
    setFilters(newFilters); // Aktualisieren der Filter
    console.log('Applied Filters:', newFilters); // Ausgabe der angewendeten Filter
    handleFilterModalClose(); // Schließen des Modals
  };

  // Funktion zur Überprüfung, ob ein Eintrag den Filtern entspricht
  const matchesFilter = (entry) => {
    for (const category in filters) {
      if (filters[category]) {
        const filterValues = filters[category].split(', ').map(value => value.trim()); // Aufteilen der Filterwerte
        console.log(`Checking category: ${category}, Entry value: ${entry[category]}, Filter values: ${filterValues}`);
        if (!filterValues.includes(entry[category])) {
          return false; // Rückgabe false, wenn der Eintrag nicht übereinstimmt
        }
      }
    }
    return true; // Rückgabe true, wenn alle Filter übereinstimmen
  };

  // Funktion zur Genehmigung der Filter
  const handleFilterApproval = () => {
    setActiveRuleIndex(null); // Zurücksetzen des aktiven Regelindex
  };

  // Funktion zum Zurücksetzen einer Kategorie
  const resetCategory = (index) => {
    const newRules = filterRules.filter((_, i) => i !== index); // Entfernen der Regel
    setFilterRules(newRules); // Aktualisieren der Filterregeln

    const categoryToRemove = filterRules[index].category; // Kategorie, die entfernt werden soll
    if (categoryToRemove) {
      const newFilters = { ...filters };
      delete newFilters[categoryToRemove]; // Entfernen der Kategorie aus den Filtern
      setFilters(newFilters); // Aktualisieren der Filter
    }

    if (activeRuleIndex === index) {
      setActiveRuleIndex(null); // Zurücksetzen des aktiven Regelindex, wenn die Regel entfernt wurde
    }
  };

  return (
    <Modal
      show={filterModalOpen} // Sichtbarkeit des Modals
      onHide={handleFilterModalClose} // Funktion zum Schließen des Modals
      size="xl" // Größe des Modals
      centered // Zentrieren des Modals
      className="modal-report-form" // CSS-Klasse für das Modal
    >
      <Modal.Header closeButton>
        <Modal.Title>Segmente filtern</Modal.Title> {/* Titel des Modals */}
      </Modal.Header>
      <Modal.Body className="filterModal">
        <Form>
          <Row className="selectedFilter">
            <Table>
              <tbody className="tableFilter">
                {filterRules.map((rule, index) => ( // Iteration über die Filterregeln
                  <tr key={index}>
                    <td style={{ fontSize: '1.4em', color: '#6c9c34' }} lg={2}>
                      <strong>{translateCategory(rule.category)}:</strong> {/* Übersetzung der Kategorie */}
                    </td>
                    <td style={{ fontSize: '1.2em', color: 'grey' }} lg={9}>
                      {rule.selectedEntries.length > 0
                        ? rule.selectedEntries.map(entry => translations[rule.category][entry]).join(', ') // Anzeige der ausgewählten Einträge
                        : "kein Filter"}
                    </td>
                    <td lg={1}>
                      <Button className="filterSegBtn" variant="secondary" onClick={() => setActiveRuleIndex(index)}>
                        Bearbeiten {/* Button zum Bearbeiten der Regel */}
                      </Button>
                      <Button className="filterSegBtn" variant="danger" onClick={() => resetCategory(index)}>
                        X {/* Button zum Entfernen der Regel */}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
          {filterRules.map((rule, index) => ( // Iteration über die Filterregeln für die Eingabe
            <Form.Group key={index} controlId={`filterRule${index}`}>
              {activeRuleIndex === index && ( // Überprüfen, ob die Regel aktiv ist
                <>
                  <Row>
                    <Col className="filtertitle" lg={2}>
                      <p className="filtertitleElement">Kategorie: </p> {/* Titel für die Kategorieauswahl */}
                    </Col>
                    <Col lg={10}>
                      <Form.Control
                        as="select"
                        value={rule.category} // Aktuelle Kategorie
                        onChange={(e) => handleCategoryChange(index, e.target.value)} // Funktion zur Handhabung der Kategorieänderung
                        className="formcontrolFilter"
                      >
                        <option value="">Wählen Sie eine Kategorie</option> {/* Standardoption */}
                        <option value="highway">Wegtyp</option>
                        <option value="surface">Oberfläche</option>
                        <option value="cycleway">Führungsform</option>
                        <option value="maxspeed">Tempolimit</option>
                        <option value="rcn">regionale Radroute</option>
                        <option value="bicycle_road">Fahrradstraße</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row className="filterSelection">
                    <Col lg={12}>
                      {rule.category && ( // Überprüfen, ob eine Kategorie ausgewählt ist
                        <>
                          <Row className="checkboxFilter">
                            {Object.entries(translations[rule.category]).map(([key, displayValue], idx) => ( // Iteration über die Übersetzungen der Einträge
                              <Col lg={4} key={key}>
                                <Form.Check
                                  type="checkbox"
                                  className="segmentfiltercheck"
                                  id={`entry-${index}-${key}`} 
                                  label={displayValue} 
                                  checked={rule.selectedEntries.includes(key)} 
                                  onChange={() => handleEntryChange(index, key)} 
                                />
                              </Col>
                            ))}
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>

                  <Row className="filterApproval">
                    <Button variant="primary" onClick={() => handleFilterApproval(index)}>
                      Eingabe bestätigen {/* Button zur Bestätigung der Eingabe */}
                    </Button>
                  </Row>
                </>
              )}
            </Form.Group>
          ))}

        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <Row className="w-100">
          <Col lg={6} className="d-flex justify-content-center">
            <Button variant="primary" className="w-100" onClick={addFilterRule}>
              Regel hinzufügen {/* Button zum Hinzufügen einer neuen Regel */}
            </Button>
          </Col>
          <Col lg={6} className="d-flex justify-content-center">
            <Button variant="primary" className="w-100" onClick={applyFilters}>
              Segmente filtern {/* Button zum Anwenden der Filter */}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterComponent; // Export der Filterkomponente