import React, { useState, useEffect } from 'react';
import Grid from './canvas/Grid'
import { Modal, Button, ModalBody, ModalFooter } from "react-bootstrap";
import ApiConnection from "./api/ApiConnection";
import { getFastload } from "./templates/FastLoad";
import CanvasInput from "./api/CanvasInput";
import logo from '../../images/logo.png';
import ClipLoader from "react-spinners/ClipLoader";
import ConnectionError from './api/ConnectionError';

export const ApiConnect = new ApiConnection();

function Dashboard() {
    const [boxes, setBoxes] = useState([]);
    const [error, setError] = useState(null);

    const addBoxes = (newEntries) => {
        const newBoxes = boxes.concat(newEntries);
        setBoxes(newBoxes);
    }

    const updateBoxes = (newBoxes) => {
        setBoxes(newBoxes);
    }

    const [open, setOpen] = useState(true);
    const onCloseModal = () => setOpen(false);

    const fastload = () => {
        try {
            getFastload(addBoxes).then(() => {
                onCloseModal();
                setLoadingSpin(true);
            });
        } catch (error) {
            if (error instanceof ConnectionError) {
                setError('Verbindung zur Datenbank fehlerhaft. Versuchen Sie es erneut.');
            } else {
                setError('Aufbau nicht möglich. Versuchen Sie es erneut.');
            }
        } 
    }

    const [show, setShow] = useState(false);
    const handleShow = () => { setShow(!show); }

    const [loadingSpin, setLoadingSpin] = useState(false);

    useEffect(() => {
        if (boxes.length > 0) {
            setLoadingSpin(false);
        }
    }, [boxes]);

    return (
        <div className='street container-fluid'>
            <Modal show={open} onHide={onCloseModal} centered fullscreen>
                <Modal.Header closeButton closeVariant="close">
                    <Modal.Title id="contained-modal-title-lg">Einstieg zum Dashboard</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    <div className='flex intro'>
                        <Button className="col btn intro" onClick={fastload}>
                            <h2>Schnelleinstieg</h2>
                        </Button>
                        <Button className="col btn intro" onClick={() => { onCloseModal(); handleShow(); }}>
                            <h2>Kategorieset auswählen</h2>
                        </Button>
                    </div>
                </ModalBody>
                <ModalFooter className='einstiegfooter'>
                    <div className='flex row descintro'>
                        <p>Wähle Schnelleinstieg um das Standardset an Kennzahlen zu laden, Kategorieset auswählen um
                            selber ein vordefiniertes Set zu wählen oder drücke x um alles selber aufzubauen.
                        </p>
                    </div>
                </ModalFooter>
            </Modal>
            {loadingSpin && (
                <div className="sweet-loading overlay-modal">
                    <img src={logo} alt="Logo" className="logo" />
                    <p className="loading-text">Dashboard wird aufgebaut. Sollte nichts erscheinen, laden Sie bitte die Seite neu.</p><br />
                    <p className="loading-text">{error}</p><br />
                    <ClipLoader
                        color={"#6c9c34"}
                        loading={loadingSpin}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            )}
            {error && (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            )}
            {!loadingSpin && (
                <Grid boxes={boxes} updateBoxes={updateBoxes} addBoxes={addBoxes} handleShow={handleShow} />
            )}

            <CanvasInput show={show} handleShow={handleShow} addBoxes={addBoxes} />
        </div>
    );
}

export default Dashboard;