import dayjs from "dayjs";

export function calculate_avg_factor(time) {
    let average_factor = 1.0;
    switch (time) {
        case "year":
            average_factor = 1.0;
            break;
        case "month":
            break;
        case "dow":
            average_factor = 1 / 7;
            break;
        case "day":
            average_factor = 1 / 12;
            break;
        case "hour":
            average_factor = 1 / 24;
            break;
        case "quarter":
            average_factor = 1 / 96; //96 = 4 * 24
            break;
        default:
            average_factor = 1.0;
            break;
    }
    return average_factor;
}

export function handleDateButtonClick(timeSpan, start, end, updateStart, updateEnd) {
    let endTime = dayjs();
    let startTime = endTime;

    switch (timeSpan) {
        case "lastWeek":
            startTime = startTime.subtract(7, 'day');
            break;
        case "last14Days":
            startTime = startTime.subtract(14, 'day');
            break;
        case "last30Days":
            startTime = startTime.subtract(30, 'day');
            break;
        case "lastMonth":
            startTime = startTime.subtract(1, 'month');
            break;
        case "lastQuarter":
            startTime = startTime.subtract(3, 'month');
            break;
        case "lastHalfYear":
            startTime = startTime.subtract(6, 'month');
            break;
        case "lastYear":
            startTime = startTime.subtract(1, 'year');
            break;
        case "last3Years":
            startTime = startTime.subtract(3, 'year');
            break;
        case "last5Years":
            startTime = startTime.subtract(5, 'year');
            break;
    }

    start = start.set('year', startTime.year()).set('month', startTime.month()).set('date', startTime.date());
    end = end.set('year', endTime.year()).set('month', endTime.month()).set('date', endTime.date());

    updateStart(start);
    updateEnd(end);
}

export function handleTimeButtonClick(dayTime, start, end, updateStart, updateEnd) {
    let startTime = 0;
    let endTime = 0;

    switch (dayTime) {
        case "day":
            startTime = 6;
            endTime = 22;
            break;
        case "night":
            startTime = 22;
            endTime = 6;
            break;
        case "morning":
            startTime = 6;
            endTime = 9;
            break;
        case "afternoon":
            startTime = 15;
            endTime = 19;
            break;
    }

    start = start.set('hours', startTime).set('minutes', 0);
    end = end.set('hours', endTime).set('minutes', 0);

    updateStart(start);
    updateEnd(end);
}

export function secondsToTime(e){
    const h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');

    return `${h}:${m}:${s}`;
}