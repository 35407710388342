import React, { useEffect } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import * as turf from "@turf/turf";
import ApiConnection from "../dashboard/api/ApiConnection";

export const ApiConnect = new ApiConnection();

const Connected = ({
  connectedSegment,
  setConnectedSegments,
  mapBounds,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments
}) => {
  useEffect(() => {
    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;


      Promise.all([
        ApiConnect.fetchQualityDataHeatmap(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsBounded(latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("disruptions", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("daily_traffic", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("time_loss", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("v_score_v1", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("v_score_v2", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("accidents", latMax, lngMax, latMin, lngMin)
      ])
        .then(([heatmapData, roadSegmentData, disruptionsData, dailyTrafficData, timeLossData, vScoreV1Data, vScoreV2Data, accidentsData]) => {
          const heatmapPolygons = heatmapData.map((element) => {
            const coordinates = element.geometry.coordinates[0].map((value) => [value[1], value[0]]);
            return {
              polygon: turf.polygon([coordinates]),
              data: {
                absolute_speed: element.absolute_speed,
                normalized_speed: element.normalized_speed,
                full_brakings: element.full_brakings,
                waiting_time: element.waiting_time,
                frequency: element.frequency
              }
            };
          });

          const roadDataMap = new Map();

          const mergeRoadData = (data, attribute) => {
            data.forEach((element) => {
              const id = element.geometry_id;
              if (!roadDataMap.has(id)) {
                roadDataMap.set(id, {
                  geometry: {
                    type: "LineString",
                    coordinates: element.geometry.coordinates.map(value => [value[1], value[0]])
                  },
                  geometry_id: id
                });
              }
              roadDataMap.get(id)[attribute] = element[attribute];
            });
          };

          roadSegmentData.forEach((element, index) => {
            const id = element.id = `rs${index + 1}`;
            roadDataMap.set(id, {
              geometry: {
                type: "LineString",
                coordinates: element.geometry.coordinates.map(value => [value[1], value[0]])
              },
              id: id
            });
          });

          // Merge additional road data attributes
          mergeRoadData(disruptionsData, "disruptions");
          mergeRoadData(dailyTrafficData, "daily_traffic");
          mergeRoadData(timeLossData, "time_loss");
          mergeRoadData(vScoreV1Data, "v_score_v1");
          mergeRoadData(vScoreV2Data, "v_score_v2");
          mergeRoadData(accidentsData, "accidents");

          const roadSegmentDataAdjusted = Array.from(roadDataMap.values());

          // Buffer distance in meters
          const bufferDistance = 3;

          // Combine logic: map heatmap data onto road segments using buffer logic
          const combinedData = roadSegmentDataAdjusted.map((road) => {
            const roadLine = turf.lineString(road.geometry.coordinates);
            const roadBuffer = turf.buffer(roadLine, bufferDistance, { units: 'meters' });

            // Initialize accumulators and count
            let count = 0;
            let totalAbsoluteSpeed = 0;
            let totalNormalizedSpeed = 0;
            let totalFullBrakings = 0;
            let totalWaitingTime = 0;
            let totalFrequency = 0;

            heatmapPolygons.forEach((heatmap) => {
              if (turf.booleanIntersects(roadBuffer, heatmap.polygon)) {
                count++;
                totalAbsoluteSpeed += heatmap.data.absolute_speed;
                totalNormalizedSpeed += heatmap.data.normalized_speed;
                totalFullBrakings += heatmap.data.full_brakings;
                totalWaitingTime += heatmap.data.waiting_time;
                totalFrequency += heatmap.data.frequency;
              }
            });

            // Calculate averages
            const averageAbsoluteSpeed = count > 0 ? totalAbsoluteSpeed / count : null;
            const averageNormalizedSpeed = count > 0 ? totalNormalizedSpeed / count : null;
            const averageFullBrakings = count > 0 ? totalFullBrakings / count : null;
            const averageWaitingTime = count > 0 ? totalWaitingTime / count : null;
            const averageFrequency = count > 0 ? totalFrequency / count : null;

            return {
              ...road,
              absolute_speed: averageAbsoluteSpeed,
              normalized_speed: averageNormalizedSpeed,
              full_brakings: averageFullBrakings,
              waiting_time: averageWaitingTime,
              frequency: averageFrequency
            };
          });

          setConnectedSegments(combinedData);
        })
        .catch((error) => {
          console.error("Error loading data:", error);
        });
    }
  }, [mapBounds, setConnectedSegments]);

  const handlePolylineClick = (segmentId) => {
    if (multiSelectMode) {
      setSelectedSegments((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(segmentId)) {
          newSelected.delete(segmentId);
        } else {
          newSelected.add(segmentId);
        }
        return newSelected;
      });
    } else {
      setSelectedSegmentId(segmentId);
      setModalOpen(true);
    }
  };

  return (
    <>
      {connectedSegment && connectedSegment.map((segment, index) => {

        if (segment && segment.geometry.coordinates) {
          return (
            <Polyline
              key={segment.id}
              positions={segment.geometry.coordinates}
              className="hoversegment"
              color={"orange"}
              weight={3}
              fill={true}
              opacity={1}
            
            >
              <Tooltip sticky={true} direction="right" offset={[0, 5]}>
                <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>Attribut</th>
                      <th>Wert</th>
                    </tr>
                  </thead>
                  <tbody>
                    {segment.geometry_id && (
                      <tr>
                        <td><strong>ID</strong></td>
                        <td>{segment.geometry_id}</td>
                      </tr>
                    )}
                    {segment.absolute_speed !== null && (
                      <tr>
                        <td><strong>Geschwindigkeit absolut</strong></td>
                        <td>{segment.absolute_speed.toFixed(2)} km/h</td>
                      </tr>
                    )}
                    {segment.normalized_speed !== null && (
                      <tr>
                        <td><strong>Geschwindigkeit normalisiert</strong></td>
                        <td>{(segment.normalized_speed * 100).toFixed(2)}%</td>
                      </tr>
                    )}
                    {segment.full_brakings !== null && (
                      <tr>
                        <td><strong>Vollbremsungen</strong></td>
                        <td>{segment.full_brakings}</td>
                      </tr>
                    )}
                    {segment.waiting_time !== null && (
                      <tr>
                        <td><strong>Wartezeit</strong></td>
                        <td>{segment.waiting_time.toFixed(2)} Minuten</td>
                      </tr>
                    )}
                    {segment.frequency !== null && (
                      <tr>
                        <td><strong>Frequenz</strong></td>
                        <td>{segment.frequency}</td>
                      </tr>
                    )}
                    {segment.daily_traffic !== undefined && (
                      <tr>
                        <td><strong>Täglicher Verkehr</strong></td>
                        <td>{segment.daily_traffic.toFixed(0)} Autos</td>
                      </tr>
                    )}
                    {segment.disruptions !== undefined && (
                      <tr>
                        <td><strong>Störungen</strong></td>
                        <td>{segment.disruptions}</td>
                      </tr>
                    )}
                    {segment.time_loss !== undefined && (
                      <tr>
                        <td><strong>Zeitverlust</strong></td>
                        <td>{segment.time_loss.toFixed(2)}s</td>
                      </tr>
                    )}
                    {segment.v_score_v1 !== undefined && (
                      <tr>
                        <td><strong>V-Score V1</strong></td>
                        <td>{segment.v_score_v1.toFixed(2)}</td>
                      </tr>
                    )}
                    {segment.v_score_v2 !== undefined && (
                      <tr>
                        <td><strong>V-Score V2</strong></td>
                        <td>{segment.v_score_v2.toFixed(2)}</td>
                      </tr>
                    )}
                    {segment.accidents !== undefined && (
                      <tr>
                        <td><strong>Unfälle</strong></td>
                        <td>{segment.accidents} Unfälle</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Tooltip>
            </Polyline>
          );
        }
        return null;
      })}
    </>
  );
};

export default Connected;