//Farbfärbung je nach Rang

export function getLegendColor(score) {
    if (score == "A") {
        return "#437c17"; // Dunkelgrün
    } else if (score == "B") {
        return "#a4c02c"; // Hellgrün
    } else if (score == "C") {
        return "#dce738"; // Gelbgrün
    } else if (score == "D") {
        return "#f8e53a"; // Gelb
    } else if (score == "E") {
        return "#eeac2e"; // Orange
    } else if (score == "F") {
        return "#db4918"; // Rot
    } else {
        return "grey"; // Grau
    }
}

