import React from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal} from "react-bootstrap";

function MapFilter({ tab, tabContents, tabLabel, roadScoreChecked, nodeChecked, roadSegmentsChecked, heatmapsChecked,
    accidentChecked, perpetratorChecked, concernsChecked, roadGeometryChecked, infrastructureChecked, updateTab, updateTabLabel,
    showDatePicker, updateShowDatePicker }) {

    const tabs = [
        { value: "1", label: "Zeitfilter", show: true, content: tabContents[0] },
        { value: "2", label: "Straßenscore", show: roadScoreChecked, content: tabContents[1] },
        { value: "3", label: "Knotenwerte", show: nodeChecked, content: tabContents[2] },
        { value: "4", label: "Straßenabschnitte", show: roadSegmentsChecked, content: tabContents[3] },
        { value: "5", label: "Heatmaps", show: heatmapsChecked, content: tabContents[4] },
        { value: "6", label: "Unfälle", show: accidentChecked || perpetratorChecked, content: tabContents[5] },
        { value: "7", label: "Meldungen", show: concernsChecked, content: tabContents[6] },
        { value: "8", label: "Radweg Eigenschaften", show: roadGeometryChecked, content: tabContents[7] },
        { value: "9", label: "Kumulierte Segmentwerte", show: infrastructureChecked, content: tabContents[8] }
    ];

    return (
        <Modal show={showDatePicker} size={'lg'} scrollable animation autoFocus>
            <Modal.Header className='row'>
                <Modal.Title>Filtern</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalStyle">
                <p className="auswahlTitel col-12">Wähle Filter für das aktive Datenlayer <i>(Zeit ist immer aktiv)</i>:</p>
                <Dropdown>
                    <DropdownToggle className="filterdropdown">
                        {tabLabel}
                    </DropdownToggle>
                    <DropdownMenu>
                        {tabs.map((tab => (tab.show &&
                            <DropdownItem eventKey={tab.value} onClick={() => { updateTab(tab.value); updateTabLabel(tab.label) }}>
                                {tab.label}
                            </DropdownItem>
                        )))}
                    </DropdownMenu>
                </Dropdown>

                {tabs.map((value => (value.value === tab && value.content)))}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => { updateTab('1'); updateTabLabel('Zeitfilter'); updateShowDatePicker(false); }}>
                    Schließen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MapFilter;