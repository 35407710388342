import React from "react";
import {Button, ButtonGroup, Modal} from "react-bootstrap";
import AddressSearchBar from "../../../report/AddressSearchBar";

function CityModal({showTownPicker, town, updateShowTownPicker, handleSwitchChange}) {

    const cities = ["Oldenburg", "Osnabrück", "Köln"];



    return (
        <Modal show={showTownPicker} size={'lg'} scrollable animation autoFocus>
            <Modal.Header className='row'>
                <Modal.Title>Stadt wählen</Modal.Title>
            </Modal.Header>

            <Modal.Body className="modalStyle">
                <p>Aktuelle Stadt: {town}</p>

                <ButtonGroup className="townbuttonrow">
                    {cities.map(city => (
                        <button className="townbutton"
                                onClick={() => { handleSwitchChange(city); updateShowTownPicker(false) }}>
                            {city}
                        </button>
                    ))}
                </ButtonGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => updateShowTownPicker(false)}>Schließen</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CityModal;