import {ApiConnect} from "../Dashboard";
import {errorHandling} from "../functions/Errors";
import {
    ridesCondition,
    ridesCount
} from "../functions/ApiFunctions";

export async function getRides(city, start, end, addBoxes) {
    Promise.all([
        ApiConnect.fetchWeatherCondition(city, [start, end]),
        ApiConnect.fetchTemperatureCondition(city, [start, end]),
        ApiConnect.fetchRidesConditions(city, [start, end]),
        ApiConnect.fetchRidesTemperatures(city, [start, end])
    ])
        .then(([data1, data2, data3, data4]) => {
            const box1 = ridesCount(data1, data3, city, start, end, "year", "rides-weather", "rides", "weather");
            const box2 = ridesCount(data2, data4, city, start, end, "year", "rides-temperature", "rides", "temperature");
            const box3 = ridesCondition(data3, city, start, end, "year", "rides-weather-length", "rides", "length", "weather");
            const box4 = ridesCondition(data3, city, start, end, "year", "rides-weather-speed", "rides", "speed", "weather");
            const box5 = ridesCondition(data4, city, start, end, "year", "rides-temperature-length", "rides", "length", "temperature");
            const box6 = ridesCondition(data4, city, start, end, "year", "rides-temperature-speed", "rides", "speed", "temperature");
            addBoxes([box1, box3, box4, box2, box5, box6]);
        }).catch((error) => {
        errorHandling(error);
    })
}