import React, { useState, useRef, useEffect } from "react"; // Importieren von React und Hooks
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet"; // Importieren von Leaflet-Komponenten
import 'leaflet/dist/leaflet.css'; // Importieren von Leaflet CSS
import { Container, Button } from "react-bootstrap"; // Importieren von Bootstrap-Komponenten
import Papa from "papaparse"; // Importieren von PapaParse für CSV-Verarbeitung
import AddressSearchBar from "../report/AddressSearchBar"; // Importieren der Adresssuchleiste
import FastApiSegment from "./FastApiSegment"; // Importieren der FastAPI-Segmentkomponente
import OsmSegment from "./OsmSegment"; // Importieren der OSM-Segmentkomponente
import Connected from "./old_Connected"; // Importieren der Connected-Komponente
import FilterComponent from "./FilterComponent"; // Importieren der Filterkomponente
import SegmenteEditComponent from "./SegmenteEditComponent"; // Importieren der Segmentbearbeitungs-Komponente
import ExportComponent from "./ExportComponent"; // Importieren der Exportkomponente
import NavComponent from "./NavComponent"; // Importieren der Navigationskomponente
import TabellenComponent from "./TabellenComponent"; // Importieren der Tabellenkomponente
import "../../styles/Infrastructure.css"; // Importieren von CSS für die Infrastruktur
import HeatmapQualitySegment from "./HeatmapQualitySegment"; // Importieren der Heatmap-Qualitätssegmentkomponente
import RoadSegmentBq from "./RoadSegmentBq"; // Importieren der RoadSegmentBq-Komponente
import ImportSegment from "./ImportSegment"; // Importieren der ImportSegment-Komponente
import Legende from "./Legend"; // Importieren der Legende-Komponente

// Hauptkomponente für die Infrastruktur
const InfrastructureMain = () => {
  // State-Variablen für verschiedene Segmente
  const [segment, setSegments] = useState([]); // Zustand für Segmente
  const [segment2, setSegments2] = useState([]); // Zustand für weitere Segmente
  const [segment3, setSegments3] = useState([]); // Zustand für zusätzliche Segmente
  const [heatmapSegment, setHeatmapSegments] = useState([]); // Zustand für Heatmap-Segmente
  const [roadSegment, setRoadSegments] = useState([]); // Zustand für Straßen-Segmente
  const [connectedSegment, setConnectedSegments] = useState([]); // Zustand für verbundene Segmente
  const [importSegment, setImportedSegment] = useState([]); // Zustand für importierte Segmente

  // Zustand für Rang CSV-Daten
  const [rangAktiv, setRangAktiv] = useState("Oberflaechen"); // Aktiver Rang
  const [csvData, setCsvData] = useState([]); // Zustand für CSV-Daten

  const [selectedRSCategories, setselectedRSCategories] = useState(["Alle"]); // Ausgewählte Kategorien
  const [selectedCombinations, setSelectedCombinations] = useState({}); // Ausgewählte Kombinationen

  const [zoom, setZoom] = useState(17); // Zoom-Stufe der Karte
  const [mapView, setMapView] = useState('standard'); // Aktuelle Kartenansicht
  const [center, setCenter] = useState([53.1435, 8.2146]); // Mittelpunkt der Karte
  const mapRef = useRef(null); // Referenz für die Karte
  const [mapBounds, setMapBounds] = useState(null); // Zustand für Kartenbegrenzungen
  const [modalOpen, setModalOpen] = useState(false); // Zustand für das Modal
  const [selectedSegmentId, setSelectedSegmentId] = useState(null); // Ausgewählte Segment-ID
  const [fuhrungsformValue, setFuhrungsformValue] = useState(""); // Zustand für Führungsform
  const [oberflaecheValue, setOberflaecheValue] = useState(""); // Zustand für Oberfläche
  const [tempolimitValue, setTempolimitValue] = useState(""); // Zustand für Tempolimit
  const [segmentName, setSegmentName] = useState(""); // Zustand für Segmentnamen
  const [entries, setEntries] = useState([]); // Zustand für Einträge
  const [multiSelectMode, setMultiSelectMode] = useState(false); // Zustand für Mehrfachauswahlmodus
  const [selectedSegments, setSelectedSegments] = useState(new Set()); // Zustand für ausgewählte Segmente
  const [dataSource, setDataSource] = useState('road'); // Aktuelle Datenquelle
  const [key, setKey] = useState(0); // Schlüssel für die Karte
  const [navShow, setNavShow] = useState(false); // Zustand für die Sichtbarkeit der Navigation
  const [exportModalOpen, setExportModalOpen] = useState(false); // Zustand für das Exportmodal
  const [importModalOpen, setImportModalOpen] = useState(false); // Zustand für das Importmodal
  const [showOffcanvas, setShowOffcanvas] = useState(false); // Zustand für Offcanvas-Anzeige
  const [filterModalOpen, setFilterModalOpen] = useState(false); // Zustand für das Filtermodal
  const [filters, setFilters] = useState({ // Zustand für Filter
    highway: '',
    bicycle: '',
    foot: '',
    surface: '',
    lit: '',
    traffic_sign: '',
    maxspeed: '',
    fuhrungsform: '',
    oberflaeche: '',
    width: '',
    rcn: '',
    bicycle_road: '',
  });

  // Zustand zur Verwaltung der ausgewählten Kategorie für die Farbe
  const [selectedCategory, setSelectedCategory] = useState('bremsrate_rank'); // Ausgewählte Kategorie
  const rangOptions = ["Oberflaechen", "Steigung", "Radwegebreite", "Führungsform"]; // Rangoptionen
  const [infrastrukturCategory, setInfrastrukturCategory] = useState("Alle"); // Infrastrukturkategorie
  const [infrastrukturOptions, setInfrastrukturOptions] = useState(['Alle']); // Optionen für Infrastruktur

  // Übersetzungen der Kategorien
  const categoryTranslations = {
    absolute_speed: "Geschwindigkeit (absolut)",
    normalized_speed: "Geschwindigkeit (normalisiert)",
    anomaly: "Schwere Erschütterungen",
    bremsrate: "Bremsrate",
    full_brakings: "Vollbremsungen (absolut)",
    durchfahrten: "Durchfahrten",
    time_loss: "Zeitverlust",
    waiting_time: "Wartezeit",
    erschuetterung_v1: "Erschütterung V1",
    erschuetterung_v2: "Erschütterung V2"
  };

  // Funktion zur Ermittlung der Infrastruktur-Optionen basierend auf dem Rang
  const getInfrastrukturOptions = (rang) => {
    switch (rang) {
      case 'Oberflaechen':
        return ['Alle', // "Alle" als erste Option hinzufügen
          'Asphalt',
          'befestigt',
          'Betonsteinpflaster',
          'Klinkerpflaster',
          'Kopfsteinpflaster',
          'Natursteinpflaster',
          'Platten',
          'Sonstige',
          'unbefestigt',
          'Wassergebundene Decke',
        ];
      case 'Führungsform':
        return ['Alle', // "Alle" als erste Option hinzufügen
          'Busspur',
          'Fahrradstraße',
          'Geh-/Radweg',
          'Mischverkehr',
          'Radfahrspur',
          'Radweg',
        ];
      case 'Steigung':
        return ['Alle', // "Alle" als erste Option hinzufügen
          'Flach',
          'leichte Steigung',
          'Steil',
          'Unbedenklich',
          'Zu Steil',
        ];
      case 'Radwegebreite':
        return ['Alle', // "Alle" als erste Option hinzufügen
          'Mindestbreite',
          'Radfreundlich',
          'Radschnellweg',
          'Standard',
          'Zu schmal',
        ];
      default:
        return [];
    }
  };

  // Effekt zum Laden der CSV-Daten basierend auf dem aktiven Rang
  useEffect(() => {
    let path = '/csv/oberflaechenrang.csv'; // Standardpfad
    switch (rangAktiv) {
      case "Oberflaechen":
        path = '/csv/oberflaechenrang.csv';
        break;
      case "Steigung":
        path = '/csv/steigungsrang.csv';
        break;
      case "Radwegebreite":
        path = '/csv/radwegebreitenrang.csv';
        break;
      case "Führungsform":
        path = '/csv/fuehrungsformenrang.csv';
        break;
      default:
        break;
    }

    // Abrufen der CSV-Daten
    fetch(path)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP-Fehler! Status: ${response.status}`);
        }
        return response.text();
      })
      .then(data => {
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            setCsvData(results.data); // Setzen der CSV-Daten
            console.log("CSV Daten:", results.data);
          },
          error: (error) => {
            console.error("CSV Lesefehler:", error);
          }
        });
      })
      .catch(error => console.error("CSV fehlerhaft:", error));
  }, [rangAktiv]); // Abhängigkeit: rangAktiv

  // Effekt zur Überwachung der ausgewählten Kategorien und Datenquelle
  useEffect(() => {
    console.log("Kategorie: " + selectedRSCategories);
    console.log("Quelle: " + dataSource);
  }, [selectedRSCategories, dataSource]);

  // Funktion zum Umschalten der Navigation
  const handleNavShow = () => {
    setNavShow(!navShow);
  };

  // Funktion zum Aktualisieren der Kartenbegrenzungen
  const updateMapBounds = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      //console.log("Aktuelle Karten-Grenzen:", bounds);
    }
  };

  // Komponente für Kartenereignisse
  const MapEvents = () => {
    useMapEvents({
      moveend: updateMapBounds, // Aktualisieren der Grenzen nach Bewegung
      zoomend: updateMapBounds, // Aktualisieren der Grenzen nach Zoom
    });
    return null;
  };

  // Funktion, die aufgerufen wird, wenn die Karte bereit ist
  const handleMapReady = (event) => {
    const mapInstance = event.target;
    mapRef.current = mapInstance; // Speichern der Karteninstanz
    updateMapBounds(); // Aktualisieren der Kartenbegrenzungen
  };

  // Funktion zur Handhabung der Adressauswahl
  const handleAddressSelected = (address) => {
    const { lat, lon } = address; // Extrahieren von Breiten- und Längengrad
    setCenter([lat, lon]); // Setzen des Kartenmittelpunkts
    setKey((prevKey) => prevKey + 1); // Aktualisieren des Schlüssels
  };

  // Funktion zur Änderung des Kartentyps
  const handleMapTypeChange = (newMapView) => {
    const newZoom = newMapView === 'standard' ? 13 : 10; // Zoomstufe basierend auf Kartentyp
    setMapView(newMapView); // Setzen der Kartenansicht
    setZoom(newZoom); // Setzen des Zooms
  };

  // Funktion zum Laden von Segmenten
  const handleLoadSegments = (source) => {
    setDataSource(source); // Setzen der Datenquelle
  };

  const [csvFile, setCsvFile] = useState(null); // Zustand für die CSV-Datei

  // Funktion zur Handhabung von Dateiänderungen
  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]); // Setzen der ausgewählten Datei
  };

  // Funktion zum Importieren von Segmenten
  const handleImportClick = () => {
    if (!csvFile) {
      console.error("Keine Datei ausgewählt");
      return;
    }

    const reader = new FileReader(); // Erstellen eines FileReader-Objekts
    reader.onload = (event) => {
      const csvText = event.target.result; // CSV-Inhalt lesen
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          const csvData = results.data; // CSV-Daten setzen
          setInfrastructure(csvData);
        },
        error: (error) => {
          console.error("Fehler beim Parsen der CSV:", error);
        },
      });
    };

    reader.onerror = (error) => {
      console.error("Fehler beim Lesen der Datei:", error);
    };

    reader.readAsText(csvFile); // Lesen der Datei als Text
  };

  // Dynamisches Generieren von Kategorien aus categoryTranslations
  const categories = Object.keys(categoryTranslations).map(key => ({
    value: `${key}_rank`, // "_rank" an den Schlüssel anhängen
    label: categoryTranslations[key] // Übersetzung für die Anzeige verwenden
  }));

  // Funktion zur Handhabung der Kategorieänderung
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value); // Setzen der ausgewählten Kategorie
  };

  // Effekt zur Aktualisierung der Infrastruktur-Optionen
  useEffect(() => {
    setInfrastrukturOptions(getInfrastrukturOptions(rangAktiv)); // Setzen der Infrastruktur-Optionen
  }, [rangAktiv]); // Abhängigkeit: rangAktiv

  return (
    <Container fluid className="map-container"> {/* Container für die Karte */}
      <MapContainer
        key={key} // Schlüssel für die Karte
        center={center} // Mittelpunkt der Karte
        zoom={zoom} // Zoomstufe der Karte
        whenReady={handleMapReady} // Funktion, die aufgerufen wird, wenn die Karte bereit ist
        style={{ height: "100%", width: "100%" }} // Stil für die Karte
      >
        {mapView === 'standard' && ( // Standardkartenansicht
          <TileLayer
            attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors" // Attribution für die Karte
            url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" // URL für die Kachel
            subdomains={['a', 'b', 'c', 'd']} // Subdomains für die Kacheln
          />
        )}
        {mapView === 'satellite' && ( // Satellitenkartenansicht
          <TileLayer
            attribution="&copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors" // Attribution für die Karte
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" // URL für die Kachel
          />
        )}
        <div className="flexMapTest row"> {/* Flexbox für die Adresssuchleiste und Kartentyp-Umschalter */}
          <AddressSearchBar
            className="address-search address-search-wrapper col" // CSS-Klasse für die Adresssuchleiste
            onAddressSelected={handleAddressSelected} // Funktion zur Handhabung der Adressauswahl
          />
          <div className="map-type-toggle dissapear col"> {/* Umschalter für Kartentyp */}
            <button
              className={mapView === 'standard' ? 'active' : ''} // Aktive Klasse für den Standardkartenbutton
              onClick={() => handleMapTypeChange('standard')} // Funktion zum Ändern des Kartentyps
            >
              Standardmap
            </button>
            <button
              className={mapView === 'satellite' ? 'active' : ''} // Aktive Klasse für den Satellitenkartenbutton
              onClick={() => handleMapTypeChange('satellite')} // Funktion zum Ändern des Kartentyps
            >
              Satellitenmap
            </button>
          </div>
        </div>

        <div style={{ position: 'absolute', bottom: '200px', left: '5px', zIndex: 1000, width: '320px', height: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid white', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', padding: '10px', backgroundColor: '#6c9c34' }}>
        {dataSource === "road" && (
          <>
          {/* Dropdown für die Auswahl von rangAktiv */}
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: "left" }}>
            <p style={{ color: "white", fontSize: "1.5em", margin: '0' }}>Infrastruktur:</p>
            <select value={rangAktiv} onChange={(e) => setRangAktiv(e.target.value)} 
            style={{ backgroundColor: 'white', border: '1px solid grey', borderRadius: '10px', padding: '5%', marginTop: '5px' }}>
              {rangOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* Dropdown für die Auswahl der Infrastrukturkategorie basierend auf rangAktiv */}
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: "left" }}>
            <p style={{ color: "white", fontSize: "1.5em", margin: '0' }}>Infrastruktur Kategorie:</p>
            <select value={infrastrukturCategory} onChange={(e) => setInfrastrukturCategory(e.target.value)} 
            style={{ backgroundColor: 'white', border: '1px solid grey', borderRadius: '10px', padding: '5%', marginTop: '5px' }}>
              {infrastrukturOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* Dropdown für die Auswahl der Kategorie */}
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: "left" }}>
            <p style={{ color: "white", fontSize: "1.5em", margin: '0' }}>Farbfilter Qualitätsmetrik:</p>
            <select value={selectedCategory} onChange={handleCategoryChange} 
            style={{ backgroundColor: 'white', border: '1px solid grey', borderRadius: '10px', padding: '5%', marginTop: '5px' }}>
              {categories.map((category) => (
                <option key={category.value} value={category.value}>
                  {category.label} {/* Übersetzung anzeigen */}
                </option>
              ))}
            </select>
          </div>
          </>
        )}
          {/* Button für Optionen */}
          <button
            className="test-button"
            onClick={handleNavShow}
            style={{ padding: '1% 3%', backgroundColor: 'white', color: 'grey', border: '1px solid grey', cursor: 'pointer', flexGrow: 1 }}>
            Optionen
          </button>
        </div>
        {/* Zusätzliche Komponente für die Legende */}
        <Legende />

        {dataSource === 'connected' && ( // Wenn die Datenquelle 'connected' ist
          <Connected
            connectedSegment={connectedSegment} // Verbundene Segmente
            setConnectedSegments={setConnectedSegments} // Funktion zum Setzen der verbundenen Segmente
            roadSegment={roadSegment} // Straßen-Segmente
            heatmapSegment={heatmapSegment} // Heatmap-Segmente
            fastapi={segment} // FastAPI-Segmente
            osm={segment2} // OSM-Segmente
            imported={segment3} // Importierte Segmente
            mapBounds={mapBounds} // Kartenbegrenzungen
            selectedSegments={selectedSegments} // Ausgewählte Segmente
            multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
            setSelectedSegmentId={setSelectedSegmentId} // Funktion zum Setzen der ausgewählten Segment-ID
            setModalOpen={setModalOpen} // Funktion zum Öffnen des Modals
            setSelectedSegments={setSelectedSegments} // Funktion zum Setzen der ausgewählten Segmente
          />
        )}

        {dataSource === 'heatmap' && ( // Wenn die Datenquelle 'heatmap' ist
          <HeatmapQualitySegment
            heatmapSegment={heatmapSegment} // Heatmap-Segmente
            setHeatmapSegments={setHeatmapSegments} // Funktion zum Setzen der Heatmap-Segmente
            mapBounds={mapBounds} // Kartenbegrenzungen
          />
        )}

        {dataSource === 'road' && ( // Wenn die Datenquelle 'road' ist
          <RoadSegmentBq
            roadSegment={roadSegment} // Straßen-Segmente
            setRoadSegments={setRoadSegments} // Funktion zum Setzen der Straßen-Segmente
            selectedRSCategories={selectedRSCategories} // Ausgewählte Kategorien
            mapBounds={mapBounds} // Kartenbegrenzungen
            rangAktiv={rangAktiv} // Aktiver Rang
            csvData={csvData} // CSV-Daten
            selectedCombinations={selectedCombinations} // Ausgewählte Kombinationen
            selectedCategory={selectedCategory} // Ausgewählte Kategorie
            infrastrukturCategory={infrastrukturCategory} // Infrastrukturkategorie
          />
        )}

        {dataSource === 'fastapi' && ( // Wenn die Datenquelle 'fastapi' ist
          <FastApiSegment
            segment={segment} // FastAPI-Segmente
            mapBounds={mapBounds} // Kartenbegrenzungen
            setSegments={setSegments} // Funktion zum Setzen der Segmente
            selectedSegments={selectedSegments} // Ausgewählte Segmente
            multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
            setSelectedSegmentId={setSelectedSegmentId} // Funktion zum Setzen der ausgewählten Segment-ID
            setModalOpen={setModalOpen} // Funktion zum Öffnen des Modals
            setSelectedSegments={setSelectedSegments} // Funktion zum Setzen der ausgewählten Segmente
          />
        )}

        {dataSource === 'osm' && ( // Wenn die Datenquelle 'osm' ist
          <OsmSegment
            segment={segment2} // OSM-Segmente
            entries={entries} // Einträge
            filters={filters} // Filter
            mapBounds={mapBounds} // Kartenbegrenzungen
            setSegments={setSegments2} // Funktion zum Setzen der Segmente
            selectedSegments={selectedSegments} // Ausgewählte Segmente
            multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
            setSelectedSegmentId={setSelectedSegmentId} // Funktion zum Setzen der ausgewählten Segment-ID
            setModalOpen={setModalOpen} // Funktion zum Öffnen des Modals
            setSelectedSegments={setSelectedSegments} // Funktion zum Setzen der ausgewählten Segmente
          />
        )}

        {dataSource === 'imported' && ( // Wenn die Datenquelle 'imported' ist
          <ImportSegment
            segment={segment3} // Importierte Segmente
            mapBounds={mapBounds} // Kartenbegrenzungen
            setSegments={setSegments3} // Funktion zum Setzen der Segmente
            selectedSegments={selectedSegments} // Ausgewählte Segmente
            multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
            setSelectedSegmentId={setSelectedSegmentId} // Funktion zum Setzen der ausgewählten Segment-ID
            importModalOpen={importModalOpen} // Zustand für das Importmodal
            setImportModalOpen={setImportModalOpen} // Funktion zum Setzen des Importmodal-Zustands
            setSelectedSegments={setSelectedSegments} // Funktion zum Setzen der ausgewählten Segmente
            selectedRSCategories={selectedRSCategories} // Ausgewählte Kategorien
          />
        )}

        <MapEvents /> {/* Kartenereignisse */}

      </MapContainer>

      <NavComponent
        dataSource={dataSource} // Aktuelle Datenquelle
        setDataSource={setDataSource} // Funktion zum Setzen der Datenquelle
        selectedRSCategories={selectedRSCategories} // Ausgewählte Kategorien
        setselectedRSCategories={setselectedRSCategories} // Funktion zum Setzen der ausgewählten Kategorien
        handleLoadSegments={handleLoadSegments} // Funktion zum Laden von Segmenten
        setFilterModalOpen={setFilterModalOpen} // Funktion zum Öffnen des Filtermodals
        toggleMultiSelectMode={() => setMultiSelectMode(!multiSelectMode)} // Funktion zum Umschalten des Mehrfachauswahlmodus
        toggleOffcanvas={() => setShowOffcanvas(!showOffcanvas)} // Funktion zum Umschalten der Offcanvas-Anzeige
        setExportModalOpen={setExportModalOpen} // Funktion zum Öffnen des Exportmodals
        setImportModalOpen={setImportModalOpen} // Funktion zum Öffnen des Importmodals
        multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
        selectedSegments={selectedSegments} // Ausgewählte Segmente
        navShow={navShow} // Sichtbarkeit der Navigation
        handleNavShow={handleNavShow} // Funktion zum Umschalten der Navigation
        setModalOpen={setModalOpen} // Funktion zum Öffnen des Modals
        selectedCombinations={selectedCombinations} // Ausgewählte Kombinationen
        setSelectedCombinations={setSelectedCombinations} // Funktion zum Setzen der ausgewählten Kombinationen
      />

      <FilterComponent
        filters={filters} // Filter
        setFilters={setFilters} // Funktion zum Setzen der Filter
        filterModalOpen={filterModalOpen} // Zustand für das Filtermodal
        setFilterModalOpen={setFilterModalOpen} // Funktion zum Öffnen des Filtermodals
        entries={entries} // Einträge
        setEntries={setEntries} // Funktion zum Setzen der Einträge
      />

      <SegmenteEditComponent
        modalOpen={modalOpen} // Zustand für das Modal
        handleModalClose={() => setModalOpen(false)} // Funktion zum Schließen des Modals
        handleFormSubmit={() => { }} // Funktion zum Absenden des Formulars
        selectedSegmentId={selectedSegmentId} // Ausgewählte Segment-ID
        fuhrungsformValue={fuhrungsformValue} // Zustand für Führungsform
        setFuhrungsformValue={setFuhrungsformValue} // Funktion zum Setzen der Führungsform
        oberflaecheValue={oberflaecheValue} // Zustand für Oberfläche
        setOberflaecheValue={setOberflaecheValue} // Funktion zum Setzen der Oberfläche
        tempolimitValue={tempolimitValue} // Zustand für Tempolimit
        setTempolimitValue={setTempolimitValue} // Funktion zum Setzen des Tempolimits
        segment={segment2} // Segmentdaten
        entries={entries} // Einträge
        setEntries={setEntries} // Funktion zum Setzen der Einträge
        multiSelectMode={multiSelectMode} // Mehrfachauswahlmodus
        selectedSegments={selectedSegments} // Ausgewählte Segmente
      />

      <ExportComponent
        exportModalOpen={exportModalOpen} // Zustand für das Exportmodal
        setExportModalOpen={setExportModalOpen} // Funktion zum Öffnen des Exportmodals
        entries={entries} // Einträge
        segment={segment2} // Segmentdaten
      />

      <TabellenComponent
        showOffcanvas={showOffcanvas} // Sichtbarkeit der Offcanvas
        toggleOffcanvas={() => setShowOffcanvas(!showOffcanvas)} // Funktion zum Umschalten der Offcanvas-Anzeige
        entries={entries} // Einträge
        setEntries={setEntries} // Funktion zum Setzen der Einträge
        setSelectedSegmentId={setSelectedSegmentId} // Funktion zum Setzen der ausgewählten Segment-ID
        setSegmentName={setSegmentName} // Funktion zum Setzen des Segmentnamens
        setFuhrungsformValue={setFuhrungsformValue} // Funktion zum Setzen der Führungsform
        setOberflaecheValue={setOberflaecheValue} // Funktion zum Setzen der Oberfläche
        setTempolimitValue={setTempolimitValue} // Funktion zum Setzen des Tempolimits
        setModalOpen={setModalOpen} // Funktion zum Öffnen des Modals
        setSelectedSegments={setSelectedSegments} // Funktion zum Setzen der ausgewählten Segmente
      />
    </Container>
  );
};

export default InfrastructureMain; // Export der Hauptinfrastrukturkomponente