import React from "react";
import "../styles/App.css";
import {Routes, Route, Navigate} from "react-router-dom";
import Header from "./common/Header.js";
import Footer from "./common/Footer";
import Dashboard from "./dashboard/Dashboard";
import NotExisting404 from './NotExisting404';
import ReportPlatform from './report/ReportPlatform';
import PrivacyPolicy from './report/PrivacyPolicy';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de';
import Infrastructure from "./infrastructure/InfrastructureMain.js";
import Testpage from "./report/Testpage.js"

function App() {
    return (
        <div className="App">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                {/* <ReactKeycloakProvider authClient={keycloak}>*/}
                <Header/>
                <br/>
                <div className="main"/>
                <Routes>
                    <Route exact path="/" element={<Dashboard/>}/>

                    <Route path="/404" element={<NotExisting404/>}/>
                    <Route path="*" element={<Navigate replace to="/404"/>}/>
                    <Route exact path='/meldung' element={<ReportPlatform/>}></Route>
                    <Route exact path='/infrastructure' element={<Infrastructure/>}></Route>
                    <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
                </Routes>
                {/*</ReactKeycloakProvider>*/}
                <br/>
                <br/>
                <br/>
                <Footer/>
            </LocalizationProvider>
        </div>
    );
}

export default App;
