export function translationOsmValues() {
    return {
        bicycle: {
            designated: "vorgesehen",
            yes: "ja",
            no: "nein",

        },
        cycleway: {
            Fahrradspur: "Fahrradspur",
            Radweg:"Radweg",
            Fahrradstraße: "Fahrradstraße",
            Busspur:"Busspur",
            GehRadweg:"Geh-/Radweg",
            track: "Geh-/Radweg",
            lane: "Fahrradspur",
            shared_lane: "Geh-/Radweg",
            opposite: "entgegen der Fahrtrichtung",
            opposite_lane: "entgegen der Fahrtrichtung auf der Spur",
            opposite_track: "entgegen der Fahrtrichtung auf dem Radweg",
            shared: "Geh-/Radweg",
            segregated: "getrennt",
            crossing: "Kreuzung",
            sidewalk:"Geh-/Radweg",
            sidepath: "Fahrradspur",
            no: "Mischverkehr",
            seperate: "Geh-/Radweg"
        },
        highway: {
            motorway: "Autobahn",
            trunk: "Schnellstraße",
            primary: "Primärstraße",
            secondary: "Sekundärstraße",
            tertiary: "Tertiärstraße",
            unclassified: "nicht klassifiziert",
            residential: "Wohnstraße",
            service: "Zubringerstraße",
            motorway_link: "Autobahnzubringer",
            trunk_link: "Schnellstraßenzubringer",
            primary_link: "Primärstraßenzubringer",
            secondary_link: "Sekundärstraßenzubringer",
            tertiary_link: "Tertiärstraßenzubringer",
            living_street: "Verkehrsberuhigter Bereich",
            pedestrian: "Fußgängerzone",
            track: "Wirtschaftsweg",
            bus_guideway: "Busspur",
            escape: "Rettungsweg",
            raceway: "Rennstrecke",
            road: "Straße",
            footway: "Fußweg",
            bridleway: "Reitweg",
            steps: "Treppen",
            path: "Pfad",
            cycleway: "Radweg",
            construction: "im Bau",

          },
        lit: {
            yes: "beleuchtet",
            no: "nicht beleuchtet",

        },
        sidewalk: {
            both: "beidseitig",
            left: "links",
            right: "rechts",
            no: "keine",

        },
        surface: {
            asphalt: "Asphalt",
            paving_stones: "Betonsteinpflaster",
            gravel: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            grass: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            dirt: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            sand: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            cobblestone: "Kopfsteinpflaster",
            concrete: "Betonsteinpflaster",
            wood: "Sonstige",
            metal: "Sonstige",
            mud: "Sonstige",
            ice: "Sonstige",
            snow: "Sonstige",
            ground: "Sonstige",
            clay: "Sonstige",
            pebblestone: "Kopfsteinpflaster",
            sett: "Kopfsteinpflaster",
            unpaved: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            paved: "befestigt",
            compacted: "ungebundene Decke (Kies/Split/Sand/Erde/Gras)",
            fine_gravel: "Sonstige",
            stone: "Sonstige",
            grass_paver: "Sonstige",

        },
        oneway:{
            yes:"Ja",
            no:"Nein"
        },
        rcn:{
            yes:"Ja",
            no:"Nein"
        },
        bicycle_road:{
            yes:"Ja",
            no:"Nein"
        },
        maxspeed:{
            30:30,
            40:40,
            50:50
        }
    }
}

export function getRoadConditionsTranslations() {
    return {
        wet: "Nass",
        dry: "Trocken",
        icy: "Vereist",
        slick: "Glatt",
        null: "keine Angabe"
    };
}

export function getCities() {
    return {
        "oldenburg": "Oldenburg",
        "osnabrueck": "Osnabrück"
    };
}

export function getCitiesShort() {
    return {
        "oldenburg": "OL",
        "osnabrueck": "OS"
    };
}

export function getOverallTopics(all) {
    if (all) {
        return {
            "standard": "Standard",
            "bicycle-volume": "Fahrrad-Verkehrsaufkommen",
            "concerns": "Bürger Meldungen",
            "accidents": "Unfälle",
            "rides": "Fahrten"
        };
    } else {
        return {
            "bicycle-volume": "Fahrrad-Verkehrsaufkommen",
            "concerns": "Bürger Meldungen",
            "accidents": "Unfälle",
            "rides": "Fahrten"
        };
    }
}


export function translateTabTitle() {
    return {
      roadScore: 'Roadscore',
      nodes: 'Knotenpunkte',
      roadSegments: 'Straßensegmente',
      heatmaps: 'Heatmap',
      busLines: 'Buslinien',
      busStops: 'Bushaltestellen',
      bikeStations: 'Radzählstellen',
      carStations: 'Autozählstellen',
      accidents: 'Unfälle',
      concerns: 'Meldungen',
      reports: 'Straßenschäden',
      infrastructure: 'Infrastruktur',
      accumulations: 'Kumulierte Segmentwerte'
    };
}

export function getTopics() {
    return {
        "bicycle-volume-all-streets": "Fahrrad-Verkehrsaufkommen Zählstellenvergleich",
        "bicycle-volume": "Fahrrad-Verkehrsaufkommen Zählstellen",
        "concerns-duration": "Dauer bis Meldungen behoben",
        "concerns-time": "Meldungen nach Zeit",
        "concerns-type": "Meldungen nach Typ",
        "concerns-type-problem": "Meldungen nach erkanntem Problem",
        "accidents-type": "Unfälle nach Unfalltyp",
        "accidents-weather": "Unfälle nach Witterung",
        "accidents-temperature": "Unfälle nach Temperatur",
        "accidents-time": "Unfälle nach Zeit",
        "accidents-perpetrator": "Unfälle nach Verursachern",
        "accidents-people-time": "Unfallbeteiligte nach Zeit",
        "accidents-people-type": "Unfallbeteiligte nach Unfalltyp",
        "accidents-road-condition": "Unfälle nach Oberfläche",
        "accidents-monetary-damage": "Verursacher nach Schadenshöhe",
        "avg-accidents-monetary-damage": "Durchschnittlicher Schaden in € pro Verursacher",
        "accidents-monetary-damage-people": "Verursacher nach Schadenshöhe in € (Personen/Sach)",
        "avg-accidents-monetary-damage-people": "Durchschnittlicher Schaden in € pro Verursacher (Personen/Sach)",
        "accidents-count-per-street": "Unfälle nach Straße",
        "accidents-count-per-street-length": "Unfallindex nach Straße und Länge",
        "accidents-to-biketraffic-count": "Unfälle zu Radverkehrsaufkommen",
        "accidents-to-cartraffic-count": "Unfälle zu Autoverkehrsaufkommen",
        "rides-weather": "Fahrten nach Witterung",
        "rides-temperature": "Fahrten nach Temperatur",
        "rides-weather-length": "Anzahl der Fahrten nach Länge & Witterung",
        "rides-weather-speed": "Anzahl der Fahrten nach Geschwindigkeit & Witterung",
        "rides-temperature-length": "Anzahl der Fahrten nach Länge & Temperatur",
        "rides-temperature-speed": "Anzahl der Fahrten nach Geschwindigkeit & Temperatur"
    };
}

export function getTimeDimensions() {
    return {
        "year": "Jahr",
        "month": "Monat",
        "dow": "Tage in der Woche",
        "day": "Tage im Monat",
        "hour": "Stunde",
        "quarter": "15 Minuten"
    };
}

export function getTimeSpans() {
    return {
        "lastWeek": "Letzte Woche",
        "last14Days": "Letzte 14 Tage",
        "last30Days": "Letzte 30 Tage",
        "lastMonth": "Letzter Monat",
        "lastQuarter": "Letztes Quartal",
        "lastHalfYear": "Letztes Halbjahr",
        "lastYear": "Letztes Jahr",
        "last3Years": "Letzte 3 Jahre",
        "last5Years": "Letzte 5 Jahre",
    };
}

export function getDayTimes() {
    return {
        "day": "Tagsüber (6-22 Uhr)",
        "night": "Nachts (22-6 Uhr)",
        "morning": "Morgens (6-9 Uhr)",
        "afternoon": "Nachmittags (15-19 Uhr)"
    };
}

export function getMonthShortcuts() {
    return {
        1: 'Jan', // Januar
        2: 'Feb', // Februar
        3: 'Mär', // März
        4: 'Apr', // April
        5: 'Mai', // Mai
        6: 'Jun', // Juni
        7: 'Jul', // Juli
        8: 'Aug', // August
        9: 'Sep', // September
        10: 'Okt', // Oktober
        11: 'Nov', // November
        12: 'Dez', // Dezember
    };
}

export function getScoreStateProperties() {
    return {
        "Score": "full_percentile_score",
        "Unfälle": "full_percentile_accidents_per_m",
        "Meldungen": "full_percentile_concerns_per_m",
        "Verkehrsaufkommen": "full_percentile_daily_traffic",
        "Zeitverlust": "full_percentile_time_loss",
        "Anomalie": "full_percentile_anomaly",
        "Erschütterungen": "full_percentile_v_score_v2",
    };
}

export function getHeatmapProperties() {
    return {
        "AnomalieV1": "anomaly_v1",
        "AnomalieV2": "anomaly_v2",
        "FrequencyRoad": "frequency",
        "V1Road": "v_score_v1",
        "V2Road": "v_score_v2",
        "WaitingTimeRoad": "waiting_time",
        "NormSpeedRoad": "normalized_speed",
        "AbsoluteSpeedRoad": "absolute_speed",
        "FullBrakings": "full_brakings"
    };
}

export function getValue(road, state) {
    switch (state) {
        case "AnomalieV1":
            return road.anomaly_v1;
        case "AnomalieV2":
            return road.anomaly_v2;
        case "FrequencyRoad":
            return road.frequency;
        case "V1Road":
            return road.v_score_v1;
        case "V2Road":
            return road.v_score_v2;
        case "WaitingTimeRoad":
            return road.waiting_time;
        case "NormSpeedRoad":
            return road.normalized_speed;
        case "AbsoluteSpeedRoad":
            return road.absolute_speed;
        case "FullBrakings":
            return road.full_brakings;
        default:
            return null;
    }
}

export function getGermanWordBiqemonitor(state) {
    const kategorie = {
        "V1": "Erschütterungen",
        "V2": "Erschütterungen unter Berücksichtigung der Geschwindigkeit",
        "Anomaly": "Anomalien",
        "TimeLoss": "Zeitverlust",
        "AnomalieSeg": "Anomalien",
        "TimeLossSeg": "Zeitverlust",
        "V1Seg": "Erschütterungen",
        "V2Seg": "Erschütterungen unter Berücksichtigung der Geschwindigkeit",
        "AnomalieV2": "Anomalien (inkl. Geschwindigkeit)",
        "FrequencyRoad": "Verkehrsstärke",
        "V1Road": "Erschütterungen",
        "V2Road": "Erschütterungen (inkl. Geschwindigkeit)",
        "WaitingTimeRoad": "Zeitverlust",
        "NormSpeedRoad": "Norm. Geschwindigkeit",
        "AbsoluteSpeedRoad": "Abs. Geschwindigkeit",
        "FullBrakings": "Vollbremsungen"
    };
    return kategorie[state];
}


export function chartTranslation() {
    return {
        "length": "Länge",
        "speed": "Geschwindigkeit",
        "time": "Zeit",
        "count_days": "Anzahl Tage",
        "count_rides": "Anzahl Fahrten",
        "normalized_rides": "Anzahl Fahrten normalisiert",
        "days": "Tage",
        "rides": "Fahrten",
        "accidents" : "Unfälle"
    };
}

export function getDomainMax(dataY) {
    switch (dataY) {
        case "accidents":
            return 250;
        case "count_days":
        case "normalized_rides":
            return 200;
        case "length":
        case "speed":
            return 25;
        case "time":
            return 3600;
        default:
            return 100;
    }
}

export function getWeatherConditionsTranslations() {
    return {
        Clear: "Klarer Himmel",
        "Partially cloudy": "Leicht bewölkt",
        Overcast: "Wolkig",
        Rain: "Regen",
        Snow: "Schnee"
    };
}

export function getWeatherConditions() {
    return ["Clear", "Partially cloudy", "Overcast", "Rain", "Snow"];
}

export function getTemperatures() {
    return ["Over30", "20To30", "TenTo20", "ZeroToTen", "MinusTenToZero", "UnderMinusTen"];
}

export function getTemperaturesTranslations() {
    return {
        "Over30": "über 30",
        "20To30": "20 bis 30",
        "TenTo20": "10 bis 20",
        "ZeroToTen": "0 bis 10",
        "MinusTenToZero": "-10 bis 0",
        "UnderMinusTen": "unter -10"
    };
}