import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, Polygon, Tooltip, useMap } from "react-leaflet";
import 'leaflet-providers';
import 'leaflet/dist/leaflet.css';
import "../../styles/ReportPlatform.css";
import "../../styles/Modal.css";
import MarkerClusterGroup from "react-leaflet-cluster";
//import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import ExifReader from "exifreader";
import PrivacyPolicy from "./PrivacyPolicy";
import AddressSearchBar from "./AddressSearchBar";
import PolygonNet from "./PolygonNet";
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import ApiConnection from "../dashboard/api/ApiConnection";
import L from "leaflet";
import { Container, Modal, Button, ModalBody } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import logo from '../../images/logo.png';
import InfoPopup from "./InfoPopup";
import { format } from 'date-fns';
import { de } from 'date-fns/locale';



//Modal.setAppElement("#root");

const API_SERVER_URL = "https://api.radweg-radar.de";
const FASTAPI_URL = "https://api.radweg-radar.de/api/v1/image"

const defaultLeafletIconUrl = require('leaflet/dist/images/marker-icon.png').default;
const customIcon = new L.Icon({
  iconUrl: require('../../images/road-circle-exclamation-solid.svg').default || defaultLeafletIconUrl,
})

const provider = new OpenStreetMapProvider();

const ReportPlatform = () => {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);

  // Function to handle when the map is ready
  const handleMapReady = (mapInstance) => {
    setMap(mapInstance);
    mapRef.current = mapInstance;
  };

  useEffect(() => {
    mapRef.current = map;
  }, [map]);

  // State-Hooks
  const [loadingSpin, setLoadingSpin] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [mapVisible, setMapVisible] = useState(true);
  const [files, setFiles] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [infoButton, setInfoButtonVisible] = useState(false);
  const [locationTrue, setLocationTrue] = useState(false);
  const [reportButton, setReportButtonVisible] = useState("block");
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [markerActive, setMarkerActive] = useState(false);
  const [checkbox, setCheckboxChecked] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [loading, setLoading] = useState(false); // Zustand für den Fortschrittsstatus
  const [progress, setProgress] = useState(0); // Zustand für den aktuellen Fortschritt
  const [mapView, setMapView] = useState('standard');
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [showPolygonNet, setShowPolygonNet] = useState(false);


  const tileLayerUrls = {
    standard: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    satellite: "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
  };

  function reloadPage() {
    window.location.reload();
  }

  // Methode zum Öffnen des Modals
  const handleModalOpen = () => {
    setModalOpen(true);
    setMapVisible(false);
    setReportButtonVisible(false);
    setCheckboxChecked(false);
  };
  const handleInfoButton = () => {
    setInfoButtonVisible(false);
    setLocationTrue(true);
  }


  // Methode zum Schließen des Modals
  const handleModalClose = () => {
    setModalOpen(false);
    setInfoButtonVisible(false);
    setMapVisible(true);
    setReportButtonVisible(true);
  };

  const closeSuccessPopup = () => {
    setSuccessPopupOpen(false);
    reloadPage();
  };

  // Methode zum Behandeln der Checkbox-Änderung
  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  // Methode, wenn der Standort nicht gefunden wird
  const handleLocationNotFound = () => {
    console.log("kein GeoTag");
    setZoom(18);
    setInfoButtonVisible(true);
    setReportButtonVisible(false);
    setModalOpen(false);
    setMapVisible(true);
  };

  // Methode zum Behandeln des Datei-Drops
  const [uploadedImage, setUploadedImage] = useState(null);
  const [locationDetected, setLocationDetected] = useState(false);
  const handleDrop = async (acceptedFiles) => {
    setFiles(acceptedFiles);
    const file = acceptedFiles[0];
    const exifLocation = await detectLocation(file);
    setMarkerPosition(null); // Zurücksetzen des Standorts des Markers beim Hochladen eines neuen Bildes
    setLocationDetected(false); // Zurücksetzen des LocationDetected-Zustands beim Hochladen eines neuen Bildes

    if (exifLocation) {
      setSelectedLocation(exifLocation);
      setLatitude(exifLocation.latitude);
      setLongitude(exifLocation.longitude);
    } else {
      handleLocationNotFound();
    }
    console.log(markerPosition)
    const uploadedFile = acceptedFiles[0];
    setUploadedImage(URL.createObjectURL(uploadedFile));
  };

  //Bereits hochgeladenes Bild löschen von Nutzerseite
  const handleImageRemove = () => {
    setUploadedImage(null); // Setze den Zustand des hochgeladenen Bildes auf null, um es zu entfernen
  };


  // Methode zur Standorterkennung aus den Exif-Daten der Datei
  const detectLocation = async (file) => {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = function (e) {
        const tags = ExifReader.load(e.target.result);

        if (tags && tags.GPSLatitude && tags.GPSLongitude) {
          const latitude = tags.GPSLatitude.description;
          const longitude = tags.GPSLongitude.description;

          console.log("Gelesener Standort:", latitude, longitude);
          resolve({ latitude, longitude });
        } else {
          console.log("Kein Standort gefunden.");
          resolve(null);
        }
      };

      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  };

  const handleLocationButtonClick = () => {
    setModalOpen(true);
    setMapVisible(false);
    setReportButtonVisible(false);
    console.log("Location Button clicked");
  };


  // Methode zum Behandeln des Formular-Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", files[0]);

      if (latitude && longitude) {
        formData.append("latitude", latitude);
        formData.append("longitude", longitude);
      }

      console.log("FormData:", formData);
      const response = await axios.post(`${API_SERVER_URL}/upload`, formData);
      console.log("Upload erfolgreich", response);

      // Setzen Sie den Fortschritt auf 100%, wenn der Upload erfolgreich ist
      setProgress(100);

      setSuccessPopupOpen(true); // Öffnet das Pop-Up-Fenster
    } catch (error) {
      console.error("Fehler beim Upload", error);
      setErrorPopupOpen(true); // Öffnet das Pop-Up-Fenster
      setLoading(false); // Stoppt den Fortschrittsbalken
      setFiles([]); // Dateien zurücksetzen
      handleCheckboxChange(false); // Checkbox zurücksetzen
    }
  };

  const closeErrorPopup = () => {
    setErrorPopupOpen(false);
  };

  // Verwenden Sie useEffect, um den Fortschritt zu aktualisieren, während das Formular gesendet wird
  useEffect(() => {
    let interval;
    if (loading) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 3.33;
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
      }, 30000); // Stoppt den Fortschrittsbalken nach 30 Sekunden (30000 Millisekunden)
    } else {
      setProgress(0);
    }
    return () => clearInterval(interval);
  }, [loading]);



  //Satellitenansicht und Standardansicht verwalten
  const [prevZoom, setPrevZoom] = useState(13);
  const [radioButtonClicked, setRadioButtonClicked] = useState(false);  // Methoden und Konstanten um die Karte zu initialisieren und zu konfigurieren

  const handleRadioButtonClick = () => {
    setRadioButtonClicked(true); // Markieren, dass der Radio Button geklickt wurde
  };

  const [markerData, setMarkerData] = useState([]);

  // Image Daten aus Api Connection holen

  const ApiConnect = new ApiConnection();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Zustand für die ausgewählte Bild-URL

  useEffect(() => {
    ApiConnect.fetchImageGeoData()
      .then((markers) => {
        // Zählen Sie die ursprüngliche Anzahl der Marker
        const totalMarkers = markers.length;

        // Filtern Sie die Marker und überprüfen Sie das Upload-Datum
        const filteredMarkers = markers.filter((marker) => {
          const uploadDate = new Date(marker.upload_date);
          const cutoffDate = new Date("2023-07-07"); // Passen Sie das Datum an

          const meetsDateCriteria = uploadDate >= cutoffDate;
          const hasDetection = !marker.image_path_detect.includes("no detection");

          return hasDetection && meetsDateCriteria;
        });

        // Zählen Sie die Anzahl der nach dem Filtern verbleibenden Marker
        const remainingMarkers = filteredMarkers.length;
        // Setzen Sie die gefilterten Marker in den Zustand
        setMarkerData(filteredMarkers);
        setLoadingSpin(false);
        console.log(filteredMarkers);
        console.log(`Geladene Marker: ${totalMarkers}, Verbleibende Marker nach dem Filtern: ${remainingMarkers}`);

        // Zählen Sie die Anzahl der bereits geladenen Marker

      })
      .catch(error => {
        console.error(error);
        setLoadingSpin(false);
        alert("Daten können derzeit nicht abgerufen werden. Probieren Sie es später erneut.");
      });
  }, []);


  // Bilder laden wenn auf Marker geklickt wird

  const loadImageById = async (annotation_id) => {
    try {
      console.log("Image ID", annotation_id);
      const response = await axios.get(`${FASTAPI_URL}/${annotation_id}`, {
        responseType: 'arraybuffer', // Hinzufügen des response-Typs für Binärdaten
      });
      const imageData = new Blob([response.data], { type: 'image/jpeg' }); // Erstellen eines Blob-Objekts mit MIME-Typ 'image/jpeg'
      const imageUrl = URL.createObjectURL(imageData); // Erstellen einer URL für das Blob-Objekt
      console.log("Bild erfolgreich geladen", imageUrl);
      return imageUrl; // Rückgabe der Bild-URL
    } catch (error) {
      console.error("Fehler beim Laden des Bildes", error);
      throw error;
    }
  };

  const handleMarkerClick = async (image) => {
    try {
      console.log("Marker geklickt", image.annotation_id);
      const imageUrl = await loadImageById(image.annotation_id);
      setSelectedImageUrl(imageUrl); // Hier sollten Sie die Bild-URL in Ihrem Zustand speichern
      setIsModalOpen(true); // Öffnen Sie das Modal
    } catch (error) {
      console.error("Fehler beim Klicken des Markers", error);
      // Hier können Sie eine Fehlerbehandlung durchführen, z.B. eine Benachrichtigung anzeigen
    }
  };


  const closeModal = () => {
    setSelectedImage(null); // Setzen Sie das ausgewählte Bild zurück
    setIsModalOpen(false); // Schließen Sie das Modal
  };



  const [zoom, setZoom] = useState(13); // Setze den anfänglichen Zoomwert entsprechend

  //wird das genutzt?
  const handleMapZoom = (e) => {
    if (e.target) {
      setZoom(e.target.getZoom()); // Update zoom state
    }

    if (e.target.dragging) {
      e.target.dragging.disable();
      setTimeout(() => {
        e.target.dragging.enable();
      }, 100);
    }

    const clickedOnRadioButton = e.target.closest('.map-type-toggle');
    if (!radioButtonClicked && clickedOnRadioButton) {
      setZoom(e.target.getZoom());
    }
    setRadioButtonClicked(false);
  };

  const [center, setCenter] = useState(
    [53.1435,
      8.2146]
  );
  const [latitude, setLatitude] = useState(center[0]);
  const [longitude, setLongitude] = useState(center[1]);

  const [key, setKey] = useState(0); // Zustand für den key

  const handleAddressSelected = (address) => {
    const { lat, lon } = address;
    console.log('Neue Adresse ausgewählt:', address);
    console.log('Center auf Lat:', lat, 'Lng:', lon, 'angepasst');
    setCenter(
      [lat, lon]
    );
    setKey((prevKey) => prevKey + 1); // Aktualisiere den key 
  };

  const handleZoomToAddress = (coordinates, zoomLevel) => {
    setZoom(zoomLevel);
  };

  const handleMapTypeChange = (newMapView) => {
    const newZoom = newMapView === 'standard' ? 13 : 10;

    setMapView(newMapView);
    setZoom(newZoom);
  };

  useEffect(() => {
    // Nach dem Umschalten der Kartenansicht den Zoomwert wiederherstellen
    setZoom(prevZoom);
  }, [mapView]);


  //Karte zum Start zentrieren
  useEffect(() => {
    setLatitude(center[0]);
    setLongitude(center[1]);
  }, []);

  const MapEvents = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setLatitude(lat);
        setLongitude(lng);
      },
      zoomend: (e) => {
        if (e.target && e.target._zoom !== null && e.target._zoom !== undefined) {
          setZoom(e.target._zoom);
        }
      },

    });
    return null;
  };

  useEffect(() => {
    if (zoom < 13) {
      const timeout = setTimeout(() => {
        setShowPolygonNet(true);
      }, 10000);
      return () => clearTimeout(timeout);
    }
    else {
      setShowPolygonNet(false);
    }
  }, [zoom]);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [center]);

  //Kopfleiste minimieren mobil - Funktion
  function minimize(className, iconclass) {
    // alle Klassenelemente selektieren
    //Kartenstil
    var elements = document.querySelectorAll("." + className);
    //Pfeilicon - Auslöser
    var iconElements = document.querySelectorAll("." + iconclass);

    elements.forEach(function (element) {
      // Element versteckt
      if (element.style.display === "none") {
        // Wechsel zu flex display
        element.style.display = "flex";
      } else {
        // wenn sichtbar, verstecken
        element.style.display = "none";
      }
    });

    iconElements.forEach(function (iconMini) {
      if (iconMini.style.background === "transparent") {
        iconMini.style.background = "white";
      } else {
        iconMini.style.background = "transparent";
      }
    });
  }


  return (
    <Container fluid className="map-container" >
      {/* Ladebildschirm */}
      {loadingSpin && (
        <div className="sweet-loading overlay-container">
          <img src={logo} alt="Logo" className="logo" />
          <p className="loading-text">Marker werden geladen</p><br />
          <ClipLoader
            color={"#6c9c34"}
            loading={loadingSpin}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      {/* Map Container und Kartenstilvarianten laden */}
      {mapVisible && (
        <MapContainer key={key} center={center} zoom={zoom} whenReady={handleMapReady} ref={mapRef} >
          {mapView === 'standard' && (
            <TileLayer
              attribution="&copy; <a href='https://carto.com/'>Carto</a> contributors"
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              subdomains={['a', 'b', 'c', 'd']}
            />
          )}
          {mapView === 'satellite' && (
            <TileLayer
              attribution="&copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          )}

          {/* Container für die Kopfreihe*/}
          <div className="flexMap row">

            {zoom >= 12 && mapRef.current && (
              // Adresssuche anzeigen, wenn der Zoomlevel kleiner als 12 ist
              <AddressSearchBar
                className="address-search address-search-wrapper col"
                onAddressSelected={handleAddressSelected}
                onZoomToAddress={handleZoomToAddress}
              />
            )}

            {/* mobil minimieren */}
            <svg className="minimize col-1" xmlns="http://www.w3.org/2000/svg" height="1.4em" viewBox="0 0 256 512" onClick={() => minimize("dissapear", "minimize")}>
              <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
            </svg>

            {/* Kartenstil wechseln */}
            <div className="map-type-toggle dissapear col">
              <button
                className={mapView === 'standard' ? 'active' : ''}
                onClick={() => handleMapTypeChange('standard')}
              >
                Standardmap
              </button>
              <button
                className={mapView === 'satellite' ? 'active' : ''}
                onClick={() => handleMapTypeChange('satellite')}
              >
                Satellitenmap
              </button>
            </div>

          </div>

          {/* neuem Marker Position zuweisen */}
          {files.length > 0 && !selectedLocation && (

            <Marker
              position={[latitude, longitude]}
              icon={customIcon}
            >
              <Popup minWidth={90}>
                <p>Latitude: {latitude}</p>
                <p>Longitude: {longitude}</p>
              </Popup>
            </Marker>

          )}
          {selectedLocation && (

            <Marker
              position={[latitude, longitude]}
              icon={customIcon}
            >
              <Popup minWidth={90}>
                <p>Latitude: {latitude}</p>
                <p>Longitude: {longitude}</p>
              </Popup>
            </Marker>

          )}
          {!selectedLocation && markerPosition && (

            <Marker
              position={markerPosition}
              icon={customIcon}
            >
              <Popup minWidth={90}>
                <p>Latitude: {markerPosition.lat}</p>
                <p>Longitude: {markerPosition.lng}</p>
              </Popup>
            </Marker>

          )}
          <MapEvents />

          {/* Verwaltungsgrenzen Polygonlininen */}
          {zoom < 12 && mapRef.current && markerData && (
            <PolygonNet markerProp={markerData} />
          )}


          {/* Markercluster */}
          {zoom >= 12 && mapRef.current && (
            <MarkerClusterGroup
              chunkedLoading={true}
              chunkInterval={3}
              maxClusterRadius={60}
              spiderfyOnMaxZoom={true}
              showCoverageOnHover={true}
              zoomToBoundsOnClick={true}
              removeOutsideVisibleBounds={true}
              polygonOptions={{
                fillColor: '#e83b46',
                color: '#e83b46',
                weight: 2,
                opacity: 0.8,
                fillOpacity: 0.2,
              }}
            >
              {markerData.map((marker, index) => (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={customIcon}

                >
                  <Popup minWidth={90}>
                    <strong>Gemeldet am:</strong><p>{format(marker.upload_date, 'dd. MMMM yyyy', { locale: de })}</p>
                    <hr></hr>
                    <Button className="damageBtn" onClick={() => handleMarkerClick(marker)}>Schaden anzeigen</Button>
                  </Popup>
                </Marker>
              ))}
            </MarkerClusterGroup>
          )}

          {/* Meldemodal ausgewähltes Bild anzeigen */}
          <Modal
            show={isModalOpen}
            onHide={closeModal}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Schaden anzeigen
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-content-report">
                <img className="repImg" src={selectedImageUrl} alt="Bild" />
              </div>
            </Modal.Body >
            <Modal.Footer>
              <Button onClick={() => closeModal()}>Schließen</Button>
            </Modal.Footer>
          </Modal>


          <button
            className="report-button"
            onClick={() => {
              setLocationTrue(false);
              handleModalOpen();
              handleLocationButtonClick();
            }}
            style={{ display: locationTrue ? "block" : "none" }}
          >
            Standort bestätigen
          </button>

          < button
            className="report-button"
            onClick={() => handleModalOpen()}
            style={{ display: reportButton ? "block" : "none" }}
          >
            Neuen Schaden erfassen
          </button>
        </MapContainer>
      )
      }

      <Modal
        show={infoButton}
        size="sm"
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            Standort wählen
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <p>Bitte wählen Sie Ihren Standort durch Anklicken der Karte mit dem Marker aus und bestätigen Sie durch Klick auf "Standort bestätigen".</p>

        </Modal.Body >

        <Modal.Footer>
          <Button onClick={handleInfoButton}>Los geht's</Button>
        </Modal.Footer>
      </Modal>

      {/* Info-Modal Komponente */}
      <InfoPopup></InfoPopup>

      {/* Einstieg Modal Schaden melden */}
      <Modal
        show={modalOpen}
        onHide={handleModalClose}
        size="lg"
        centered
        className="modal-report-form"
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            Neuen Schaden erfassen
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="dropzone">
              <Dropzone onDrop={handleDrop} multiple={false}>
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div
                    {...getRootProps()}
                    className={`dropzone-content ${isDragActive ? 'drag-active' : ''}`}
                  >
                    <input {...getInputProps()} />
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        style={{ maxWidth: '20%', maxHeight: '20%' }}
                      />

                    ) : (
                      <React.Fragment>
                        <FiUpload />
                        <p>
                          {!isDragActive
                            ? 'Bild auswählen oder per Drag & Drop hier ablegen'
                            : 'Lass los, um das Bild hochzuladen'}
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
            {/* Datenschutzbestimmung */}
            {files.length > 0 && <p>Hochgeladene Datei: {files[0].name}{"  "}{uploadedImage ?
              <Button className="remove" onClick={handleImageRemove}>Bild entfernen</Button>
              : ""
            }</p>}
            <div className="checkbox-container">
              <label>
                <input type="checkbox" required onChange={handleCheckboxChange} />
                {" "}Ich akzeptiere die{" "}
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Datenschutzbestimmungen
                </a>
                .
              </label>
            </div>

            {/* Absenden - Button
            <Button
              type="submit"
              className="submit-button"
              onClick={checkbox ? handleSubmit : null}
              disabled={!checkbox || loading || successPopupOpen}
              style={{ cursor: checkbox || loading || successPopupOpen ? "pointer" : "not-allowed" }}
            >
              Senden
            </Button>
          */}
            <br></br>

            {/* Ladebalken für das Hochladen */}
            {loading && (
              <div className="progress-bar">
                <progress value={progress} max={100} />
              </div>
            )}

            {/* Feedback Erfolg / Misserfolg beim Hochladen */}
            <Modal
              show={successPopupOpen}
              size="sm"
              centered
              className="modal-report-form"
            >
              <Modal.Header closeButton={true}>
                Danke Dir!
              </Modal.Header>
              <ModalBody>
                <p>Bild erfolgreich hochgeladen!</p>
              </ModalBody>
              <Modal.Footer>
                <Button onClick={closeSuccessPopup}>Übersicht laden</Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={errorPopupOpen}
              size="sm"
              centered
              className="modal-report-form"
            >
              <Modal.Header closeButton={true}>
                Fehler
              </Modal.Header>
              <ModalBody>
                <p>Ein Fehler ist aufgetreten. Bitte versuche es erneut.</p>
              </ModalBody>
              <Modal.Footer>
                <Button onClick={closeErrorPopup}>Übersicht laden</Button>
              </Modal.Footer>
            </Modal>
          </form>
        </Modal.Body >

        <Modal.Footer>
          <Button
            type="submit"
            className="submit-button"
            onClick={checkbox ? handleSubmit : null}
            disabled={!checkbox || loading || successPopupOpen}
            style={{ cursor: checkbox || loading || successPopupOpen ? "pointer" : "not-allowed" }}
          >
            Senden</Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};
export default ReportPlatform;
