import React, { useState } from 'react'; // Importieren von React und useState-Hook
import { Button } from 'react-bootstrap'; // Importieren der Button-Komponente von React-Bootstrap

// Komponente für die Legende
const Legende = () => {
  // Definieren der Farben für jede Rangklasse
  const colors = ['#437c17', '#a4c02c', '#dce738', '#f8e53a', '#eeac2e', '#db4918'];
  const ticks = ['A', 'B', 'C', 'D', 'E', 'F']; // Ränge

  // Zustand zur Steuerung der Sichtbarkeit der Legende
  const [isVisible, setIsVisible] = useState(true);

  // Funktion zum Umschalten der Sichtbarkeit der Legende
  const toggleLegend = () => {
    setIsVisible(!isVisible); // Sichtbarkeit umkehren
  };

  // Wenn die Legende nicht sichtbar ist, wird null zurückgegeben
  if (!isVisible) {
    return (
      <div className="flexClass">
        <div className="ausblenden left" onClick={toggleLegend} data-tooltip="Legende ein-/ausblenden">
          <Button style={{
            fontSize: "2em" // Schriftgröße des Buttons
          }}>Legende</Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flexClass" style={{
      position: "absolute", // Positionierung der Legende
      bottom: "5%", // Abstand vom unteren Rand
      left: "5px", // Abstand vom linken Rand
    }}>
      <div className="ausblenden left" onClick={toggleLegend} data-tooltip="Legende ein-/ausblenden" style={{
        padding: '3% 3%', border: '1px solid white', borderRadius: '5px' // Stil für den Umschaltbereich
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
        </svg>
      </div>

      <div className="legendWrapper" style={{
        padding: '4% 4%', paddingTop: "4%", border: '1px solid white', borderRadius: '5px' // Stil für den Wrapper der Legende
      }}>
        <div className="legendRow" style={{
          display: "flex", flexWrap: "wrap" // Flexbox für die Anordnung der Legendenzeilen
        }}>
          <div className="titleRow" style={{
            paddingBottom: "2%" // Abstand nach unten für die Titelzeile
          }}>
            <p className="legendtitle" style={{
              paddingLeft: "4%" // Abstand nach links für den Titel
            }}> Rangklasse</p>
          </div>
          <div className="colorLegend">
            {ticks.map((tick, index) => ( // Iteration über die Ränge
              <div key={index} className="legendItem">
                <div
                  className="colorBox"
                  style={{
                    backgroundColor: colors[index], // Hintergrundfarbe basierend auf dem Rang
                    width: '45px', // Breite der Farbbox
                    height: '10px', // Höhe der Farbbox
                    display: 'inline-block', // Inline-Block-Anzeige
                    marginRight: '1px', // Abstand nach rechts
                  }}
                />
                <span className="label">{tick}</span> {/* Anzeige des Rangs */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legende; // Export der Legendenkomponente