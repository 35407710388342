import React from "react";
import checked from "../../../../images/square-check-regular.svg";
import unchecked from "../../../../images/square-regular.svg";
import {accumulationOptions} from "../../functions/FilterFunctions";

function FilterAccumulation({accumulationState, updateAccumulationState, updateLoadingSpin}) {

    return (
        <div>
            {accumulationOptions.map((option, index) => (
                <button key={index} className={`col-12 filterBtn ${accumulationState === option ? "selected" : ""}`}
                        onClick={() => { updateAccumulationState(option); updateLoadingSpin(true); }}>
                              <span className="icon-filter-container" >
                                <img src={accumulationState === option ? checked : unchecked}
                                     alt={accumulationState === option ? "checked Icon" : "unchecked Icon"}
                                     className="icon-filter"/>
                                  {option}
                              </span>
                </button>
            ))}
        </div>
    );
}

export default FilterAccumulation;