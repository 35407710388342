import React, { useEffect, useState } from "react";
import { Polyline, Tooltip } from "react-leaflet";
import ApiConnection from "../dashboard/api/ApiConnection";
import { getLegendColor } from "./GeometryColoring";
export const ApiConnect = new ApiConnection();

const RoadSegmentBq = ({
  roadSegment,
  setRoadSegments,
  selectedRSCategories,
  mapBounds,
  rangAktiv,
  csvData,
  selectedSegments,
  multiSelectMode,
  setSelectedSegmentId,
  setModalOpen,
  setSelectedSegments,
  selectedCombinations,
  selectedCategory,
  infrastrukturCategory
}) => {
  const categoryTranslations = {
    absolute_speed: "Geschwindigkeit (absolut)",
    normalized_speed: "Geschwindigkeit (normalisiert)",
    anomaly: "Schwere Erschütterungen",
    bremsrate: "Bremsrate",
    full_brakings: "Vollbremsungen (absolut)",
    durchfahrten: "Durchfahrten",
    time_loss: "Zeitverlust",
    waiting_time: "Wartezeit",
    erschuetterung_v1: "Erschütterung V1",
    erschuetterung_v2: "Erschütterung V2"
  };
  useEffect(() => {

    if (mapBounds) {
      const latMax = mapBounds.getNorthEast().lat;
      const lngMax = mapBounds.getNorthEast().lng;
      const latMin = mapBounds.getSouthWest().lat;
      const lngMin = mapBounds.getSouthWest().lng;

      Promise.all([
        ApiConnect.fetchRoadsDataCol("daily_traffic", latMax, lngMax, latMin, lngMin),
        ApiConnect.fetchRoadsDataCol("accidents", latMax, lngMax, latMin, lngMin)
      ])
        .then(([dailyTrafficData, accidentsData]) => {
          let data_adjusted = {};

          const mergeDataByGeometryId = (data, key) => {
            if (data !== null) {
              data.forEach(item => {
                const { geometry_id, geometry, ...rest } = item;

                // Check if geometry and coordinates exist, then swap coordinates
                if (geometry && geometry.coordinates) {
                  geometry.coordinates = geometry.coordinates.map(coord => [coord[1], coord[0]]);
                }

                // Initialize the entry in data_adjusted if it doesn't exist
                if (!data_adjusted[geometry_id]) {
                  data_adjusted[geometry_id] = { geometry_id, geometry, data: {} };
                }

                // Merge the rest of the data under the specified key
                data_adjusted[geometry_id].data[key] = rest;
              });
            } else {
              console.warn(`Data is null`);
            }
          };

          // Merge each dataset
          mergeDataByGeometryId(dailyTrafficData, 'dailyTraffic');

          mergeDataByGeometryId(accidentsData, 'accidents');

          //console.log(selectedCombinations)
          // Parse and merge CSV data
          parseAndMergeCSVData(csvData, data_adjusted);

          //console.log(data_adjusted);
          //setRoadSegments(data_adjusted); // Set the combined data
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Segmente:", error);
        });
    }
  }, [mapBounds, selectedRSCategories, setRoadSegments, csvData, selectedCombinations, selectedCategory, rangAktiv, infrastrukturCategory]);


  const parseAndMergeCSVData = (Infrastructure, data_adjusted) => {
    const csvData = Infrastructure;
    csvData.forEach(row => {
      const geometryId = row.geometry_id;
      if (data_adjusted[geometryId]) {
        data_adjusted[geometryId].data.csvData = row;
      }
    });
    console.log("Final:", data_adjusted);
    setRoadSegments(data_adjusted);
  };

  const formatValue = (value) => {
    value === null || value === undefined || value === '' ? 0 : value
    const number = parseFloat(value);
    return isNaN(number) ? value : number.toFixed(2);
  };

  const convertToGeoJSON = () => {
    const features = Object.entries(roadSegment).map(([geometryId, segmentData]) => {
      const road = segmentData;
      const csvData = road.data.csvData;

      if (road && road.geometry && road.geometry.coordinates) {
        return {
          type: "Feature",
          geometry: {
            type: "LineString",
            // Switch the coordinates from [lat, lng] to [lng, lat]
            coordinates: road.geometry.coordinates.map(coord => [coord[1], coord[0]])
          },
          properties: {
            id: geometryId,
            ...road.data.csvData // Include all CSV data as properties
          }
        };
      }
      return null;
    }).filter(feature => feature !== null);

    return {
      type: "FeatureCollection",
      features: features
    };
  };

  const handleExportGeoJSON = () => {
    const geojson = convertToGeoJSON();
    const blob = new Blob([JSON.stringify(geojson)], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "road_segments.geojson";
    a.click();
    URL.revokeObjectURL(url);
  };




  return (
    <>
      <div style={{ position: 'absolute', bottom: '150px', left: '5px', zIndex: 1000, width: '320px', height: 'auto', display: 'flex', flexDirection: 'column', gap: '10px', border: '1px solid white', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', padding: '10px', backgroundColor: '#6c9c34' }}>

        {/* Dropdown for selecting rangAktiv */}
        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: "left" }}>
          <button onClick={handleExportGeoJSON} style={{ backgroundColor: 'white', border: '1px solid grey', borderRadius: '10px' }}>
            GeoJSON exportieren
          </button>
        </div>
      </div>


      {Object.entries(roadSegment).map(([geometryId, segmentData]) => {
        const road = segmentData;
        if (!road.data.csvData) {
          return null;
        }
        const csvData = road.data.csvData;

        // Prüfen, ob mindestens eine der Bedingungen erfüllt ist
        const matchesOneCombination = Object.entries(selectedCombinations).some(([category, ranks]) => {
          const rankKey = `${category}_rank`;
          return csvData && ranks.includes(csvData[rankKey]);
        });

        if (!matchesOneCombination) {
          return null;
        }


        const isInfrastrukturMatch = infrastrukturCategory === "Alle" || csvData.infrastruktur == infrastrukturCategory;

        // If the infrastruktur value doesn't match, skip this segment
        if (!isInfrastrukturMatch) {
          return null;
        }

        if (road && road.geometry && road.geometry.coordinates) {
          return (
            <Polyline
              key={`${geometryId}-${rangAktiv}-${infrastrukturCategory}-${selectedCategory}`}
              positions={road.geometry.coordinates}
              className="hoversegment"
              color={csvData ? getLegendColor(csvData[selectedCategory]) : "grey"}
              weight={3}
              fill={false}
              opacity={1}
            >
              <Tooltip sticky={true} direction="right" className="fixed-tooltip">
                <table className="tooltipTable" border="1" cellPadding="3" cellSpacing="0" style={{ textAlign: 'center', marginBottom: '5px' }}>
                  <thead>
                    <tr>
                      <th>ID: {geometryId}</th>
                      <th>Straße: {road.data.csvData.osm_road_name}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {road.data.csvData?.infrastruktur !== undefined && (
                      <tr>
                        <td><strong>Infrastruktur/Ausprägung</strong></td>
                        <td>{`${rangAktiv}/${road.data.csvData.infrastruktur}`}</td>
                      </tr>
                    )}
                    {road.data.accidents && (
                      <tr>
                        <td><strong>Unfälle</strong></td>
                        <td>
                          {road.data.accidents.accidents}
                        </td>
                      </tr>
                    )}
                    {road.data.dailyTraffic && (
                      <tr>
                        <td><strong>Verkehrsaufkommen</strong></td>
                        <td>
                          {road.data.dailyTraffic.daily_traffic.toFixed(0)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <table className="tooltipTable" border="1" cellPadding="3" cellSpacing="0" style={{ textAlign: 'center' }}>
                  <thead>
                    <tr>
                      <th>Attribut</th>
                      <th>Wert</th>
                      <th>Rang</th>
                      <th>Mittelwert</th>
                      <th>Abweichung MW</th>

                    </tr>
                  </thead>
                  <tbody>
                    {road.data.csvData && Object.entries(road.data.csvData).map(([key, value]) => {
                      if (!key.endsWith("_avg") && !key.endsWith("_deviation") && !key.endsWith("_rank") && !["geometry_id", "infrastruktur", "segment_id", "count_id", "schwere_erschuetterung_grid1", "schwere_erschuetterung_grid2", "osm_road_name", "kreuzung", "lit", "spuranzahl", "maxspeed", "osm_geometrie"].includes(key)
                      ) {
                        const avgKey = `${key}_avg`;
                        const deviationKey = `${key}_deviation`;
                        const rankKey = `${key}_rank`;
                        return (
                          <tr key={key}>
                            <td><strong>{categoryTranslations[key]}</strong></td>
                            <td>{formatValue(value) || '-'}</td>
                            <td>{road.data.csvData[rankKey] || '-'}</td>
                            <td className="entered">{formatValue(road.data.csvData[avgKey]) || '-'}</td>
                            <td>{formatValue(road.data.csvData[deviationKey]) || '-'}</td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </Tooltip>
            </Polyline>
          );
        }
        return null;
      })}
    </>
  );
};

export default RoadSegmentBq;