import React, { useEffect } from "react"; // Importieren von React und useEffect-Hook
import { Polyline, Tooltip } from "react-leaflet"; // Importieren von Polyline und Tooltip von React-Leaflet
import ApiConnection from "../dashboard/api/ApiConnection"; // Importieren der ApiConnection-Klasse

// Instanz der ApiConnection erstellen
export const ApiConnect = new ApiConnection();

// Komponente für das Heatmap-Qualitätssegment
const HeatmapQualitySegment = ({
  heatmapSegment, // Segmentdaten für die Heatmap
  setHeatmapSegments, // Funktion zum Setzen der Heatmap-Segmente
  mapBounds // Kartenbegrenzungen
}) => {
  // Effekt, der ausgeführt wird, wenn sich die Kartenbegrenzungen ändern
  useEffect(() => {
    if (mapBounds) { // Überprüfen, ob Kartenbegrenzungen vorhanden sind
      const latMax = mapBounds.getNorthEast().lat; // Maximale Breite
      const lngMax = mapBounds.getNorthEast().lng; // Maximale Länge
      const latMin = mapBounds.getSouthWest().lat; // Minimale Breite
      const lngMin = mapBounds.getSouthWest().lng; // Minimale Länge

      // Abrufen der Qualitätsdaten für die Heatmap
      ApiConnect.fetchQualityDataHeatmap(latMax, lngMax, latMin, lngMin)
        .then((data) => {
          //console.log(data); // Debugging-Ausgabe der Daten
          // Anpassen der Koordinaten für die Heatmap-Daten
          const heatmapData_adjusted = data.map((element) => {
            const coordinates = element.geometry.coordinates[0].map((value) => [value[1], value[0]]); // Koordinaten umkehren
            element.geometry.coordinates = [coordinates]; // Angepasste Koordinaten setzen
            return element; // Element zurückgeben
          });
          console.log(heatmapData_adjusted); // Debugging-Ausgabe der angepassten Daten
          setHeatmapSegments(heatmapData_adjusted); // Setzen der Heatmap-Segmente
        })
        .catch((error) => {
          console.error("Fehler beim Laden der Segmente:", error); // Fehlerausgabe
        });
    }
  }, [mapBounds, setHeatmapSegments]); // Abhängigkeiten: mapBounds und setHeatmapSegments

  return (
    <>
      {heatmapSegment && Object.keys(heatmapSegment).map((i) => { // Überprüfen, ob Heatmap-Segmente vorhanden sind
        const road = heatmapSegment[i]; // Aktuelles Segment

        if (road && road.geometry && road.geometry.coordinates) { // Überprüfen, ob das Segment gültige Geometrie hat
          return (
            <Polyline
              key={road.geometry_id} // Eindeutiger Schlüssel für die Polyline
              positions={road.geometry.coordinates} // Koordinaten für die Polyline
              className="hoversegment" // CSS-Klasse für Hover-Effekte
              color={"orange"} // Farbe der Polyline
              weight={1} // Linienstärke
              fill={true} // Füllung aktivieren
              opacity={0.3}> {/* Transparenz der Polyline */}

              <Tooltip sticky={true} direction="right" offset={[0, 5]}> {/* Tooltip für zusätzliche Informationen */}
                <table className="tooltipTable" border="1" cellPadding="5" cellSpacing="0"> {/* Tabelle für Tooltip-Inhalt */}
                  <thead>
                    <tr>
                      <th>Attribut</th> {/* Spaltenüberschrift für Attribut */}
                      <th>Wert</th> {/* Spaltenüberschrift für Wert */}
                    </tr>
                  </thead>
                  <tbody>
                    {road.geometry_id && ( // Überprüfen, ob die Geometrie-ID vorhanden ist
                      <tr>
                        <td><strong>ID</strong></td> {/* ID-Attribut */}
                        <td>{road.geometry_id}</td> {/* Geometrie-ID anzeigen */}
                      </tr>
                    )}
                    {road.absolute_speed && ( // Überprüfen, ob die absolute Geschwindigkeit vorhanden ist
                      <tr>
                        <td><strong>Geschwindigkeit absolut</strong></td> {/* Attribut für absolute Geschwindigkeit */}
                        <td>{road.absolute_speed.toFixed(2)} km/h</td> {/* Geschwindigkeit anzeigen */}
                      </tr>
                    )}
                    {road.normalized_speed && ( // Überprüfen, ob die normalisierte Geschwindigkeit vorhanden ist
                      <tr>
                        <td><strong>Geschwindigkeit normalisiert</strong></td> {/* Attribut für normalisierte Geschwindigkeit */}
                        <td>{(road.normalized_speed * 100).toFixed(2)}%</td> {/* Normalisierte Geschwindigkeit anzeigen */}
                      </tr>
                    )}
                    {road.full_brakings && ( // Überprüfen, ob die Anzahl der Vollbremsungen vorhanden ist
                      <tr>
                        <td><strong>Vollbremsungen</strong></td> {/* Attribut für Vollbremsungen */}
                        <td>{road.full_brakings}</td> {/* Anzahl der Vollbremsungen anzeigen */}
                      </tr>
                    )}
                    {road.waiting_time && ( // Überprüfen, ob die Wartezeit vorhanden ist
                      <tr>
                        <td><strong>Wartezeit</strong></td> {/* Attribut für Wartezeit */}
                        <td>{road.waiting_time.toFixed(2)} Minuten</td> {/* Wartezeit anzeigen */}
                      </tr>
                    )}
                    {road.frequency && ( // Überprüfen, ob die Frequenz vorhanden ist
                      <tr>
                        <td><strong>Frequenz</strong></td> {/* Attribut für Frequenz */}
                        <td>{road.frequency}</td> {/* Frequenz anzeigen */}
                      </tr>
                    )}
                  </tbody>
                </table>
              </Tooltip>
            </Polyline>
          );
        }
        return null; // Wenn das Segment ungültig ist, null zurückgeben
      })}
    </>
  );
};

export default HeatmapQualitySegment; // Export der HeatmapQualitySegment-Komponente