import React, {useCallback, useEffect} from "react";
import {LayerGroup, LayersControl, Marker, Popup} from "react-leaflet";
import {stationIcon} from "../../functions/Icons";
import {fetchBusStopsData} from "../../functions/MapFunctions";

function BusStopsLayer({busStopChecked, busStops, mapBounds, loadingSpin, updateBusStops, updateLoadingSpin}) {

    const useBusStopsData = useCallback(() => {
        fetchBusStopsData(mapBounds, updateBusStops, updateLoadingSpin);
    }, [mapBounds]);

    useEffect(() => {
        if (busStopChecked) {
            useBusStopsData();
        } else {
            updateBusStops({});
        }
    }, [mapBounds, busStopChecked]);

    return (
        <LayersControl.Overlay name="6: Bushaltestellen" checked={busStopChecked}>
            <LayerGroup>
                {!loadingSpin && Object.keys(busStops).map((busStopId) => {
                    const busStop = busStops[busStopId];
                    if (busStop.geometry && busStop.geometry.coordinates && busStop.geometry.coordinates.length === 2) {
                        return (
                            <Marker key={busStopId}
                                    position={[busStop.geometry.coordinates[1], busStop.geometry.coordinates[0]]}
                                    icon={stationIcon}>
                                <Popup>
                                    <strong>ID:</strong> {busStopId} <br/>
                                    <strong>Name:</strong> {busStop.bus_stop_name} <br/>
                                </Popup>
                            </Marker>
                        );
                    }
                    return null;
                })}
            </LayerGroup>
        </LayersControl.Overlay>
    );
}

export default BusStopsLayer;