import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import { Modal, Button, Form, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import { translateTabTitle } from './Translations';
import {convert} from "./Convert";

const ExportLayerData = ({roadScoreData, nodesData, roadsData, heatmapData, busData, busStops, groupedData, carStations,
                           groupedAccData, groupedConcernsData, reportedImg, infrastructureData, accumulationData, loadingSpin}) => {

  const [exportModalOpen, setExportModalOpen] = useState(false); // Zustand für das Öffnen des Exportmodals
  const [choice, setChoice] = useState("geojson"); // Zustand für das gewählte Exportformat
  const translations = translateTabTitle(); // Übersetzungen für Tab-Titel

  const handleExport = (choice) => {
    if (loadingSpin) {
      console.warn("Daten werden geladen. Warten Sie kurz.");
      return;
    }
    if (choice === 'jpeg') {
      exportMapAsImage(); // Exportiere die Karte als Bild
    } else {
      exportFunction(choice); // Exportiere die Daten im gewählten Format
    }
  };

  const exportMapAsImage = () => {
    const mapElement = document.getElementById('map');
    
    if (mapElement) {
      const excludeElements = document.querySelectorAll('.exportExclude');
  
      excludeElements.forEach(element => {
        element.style.display = 'none';
      });
  
      setTimeout(() => {
        html2canvas(mapElement, {
          useCORS: true,
          allowTaint: true,
          logging: false, 
          backgroundColor: null, 
        }).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/jpeg');
          link.download = 'Radweg_Radar_Kartensicht.jpg';
          link.click();
        }).catch(error => {
          console.error("Fehler bei der Bilderzeugung der Map:", error);
        }).finally(() => {
          excludeElements.forEach(element => {
            element.style.display = '';
          });
        });
      }, 2000);
    } else {
      console.error("Map fehlerhaft.");
    }
  };

  const layerDataMap = {
    roadScore: roadScoreData,
    nodes: nodesData,
    roadSegments: roadsData,
    heatmaps: heatmapData,
    busLines: busData,
    busStops: busStops,
    bikeStations: groupedData,
    carStations: carStations,
    accidents: groupedAccData,
    concerns: groupedConcernsData,
    reports: reportedImg,
    infrastructure: infrastructureData,
    accumulations: accumulationData
  };

  // Filtere die aktivierten Layer
  const checkedLayers = Object.entries(layerDataMap)
      .filter(([, data]) => (data.length > 0 || Object.keys(data).length > 0))
      .map(([key, data]) => ({ key, data }));

  const mapDataDynamically = (data) => {
    // Filtere die Daten basierend auf den ausgewählten Objekten und Spalten
    return Object.entries(data)
        .map(([, item]) => {
          const mappedItem = {};
          for (const column in item) {
            // Speziell für Geometrie: Extrahiere die Geometrie als String
            if (column === 'geometry' && (item[column].coordinates || item[column].length > 0)) {
              mappedItem['geometry.coordinates'] = JSON.stringify(item[column]);
            } else if (column === 'accident_type_counts' || column === 'concern_type_counts') {
              for (const value in item[column]) {
                mappedItem[value] = item[column][value];
              }
            } else {
              mappedItem[column] = item[column];
            }
          }
          return mappedItem;
        });
  };

  const extractValues = (nestedData) => {
    // Extrahiere die Werte aus den verschachtelten Daten
    const values = [];
    for (const year in nestedData) {
      for (const month in nestedData[year]) {
        for (const id in nestedData[year][month]) {
          const item = nestedData[year][month][id];
          values.push(item);
        }
      }
    }
    return values;
  };

  const extractBusLines = (nestedData) => {
    // Extrahiere die Werte aus den verschachtelten Daten
    const values = [];
    for (const busLineName in nestedData) {
      for (const index in nestedData[busLineName]) {
        const item = nestedData[busLineName][index];
        item.busLineName = busLineName;
        values.push(item);
      }
    }
    return values;
  };

  const filterData = (data) => {
    // Filtere die Daten
    return data.map(item => {
      const filteredItem = {};
      for (const key in item) {
        if (key === 'coordinates') {
          filteredItem[key] = JSON.stringify(item[key]);
        } else {
          filteredItem[key] = item[key]
        }
      }
      return filteredItem;
    });
  };


  const exportFunction = (format = 'csv') => {
    const workbook = XLSX.utils.book_new(); // Erstelle ein neues Excel-Arbeitsbuch

    // Hole das heutige Datum im gewünschten Format
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Hole den Tag und füge eine Null hinzu, falls nötig
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Hole den Monat (0-indexiert) und füge eine Null hinzu
    const year = String(today.getFullYear()).slice(-2); // Hole die letzten zwei Ziffern des Jahres
    const formattedDate = `${day}_${month}_${year}_Radweg_Radar`;

    // Hole Übersetzungen für die Tab-Titel
    const translations = translateTabTitle();

    checkedLayers.forEach(({ key, data }) => {
      if (format === 'geojson') {
        data = convert(key, data);

        const jsonData = JSON.stringify(data);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${formattedDate}_${translations[key]}.${format}`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        let dataToExport;
        if (key === 'accidents' || key === 'concerns') {
          // Extrahiere und filtere die Daten für spezifische Layer
          dataToExport = extractValues(data);
          dataToExport = filterData(dataToExport);
        } else if (key === 'busLines') {
          // Extrahiere und filtere die Daten für spezifische Layer
          dataToExport = extractBusLines(data);
          dataToExport = filterData(dataToExport);
        } else {
          dataToExport = mapDataDynamically(data);
        }

        // Exportiere die Daten als Excel- oder CSV-Datei
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const translatedTitle = translations[key] || key; // Verwende den übersetzten Titel oder den Schlüssel als Fallback
        XLSX.utils.book_append_sheet(workbook, worksheet, translatedTitle); // Füge das Arbeitsblatt mit dem übersetzten Titel hinzu
      }
    });

    if (format === 'xlsx') {
      const fileName = `${formattedDate}.${format}`;

      // Exportiere als XLSX
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <>
      <div className="otherIcon" data-tooltip="Daten exportieren" onClick={() => setExportModalOpen(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512">
          <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
        </svg>
      </div>

      <Modal show={exportModalOpen} onHide={() => setExportModalOpen(false)} size="xl" scrollable animation
             autoFocus className="modal-report-form">
        <Modal.Header closeButton>
          <Modal.Title>Kartendaten exportieren</Modal.Title>
        </Modal.Header>
        <Modal.Body className="filterModal">
          <Form>
            <Row className="selectedFilter">
              <Form.Group controlId="exportFormat" className="filtertitle">
                <Form.Label className="filtertitleElement">Exportformat wählen:</Form.Label>
                <Form.Control as="select" value={choice} onChange={(e) => setChoice(e.target.value)}>
                  <option value="geojson">GEOJSON</option>
                  <option value="xlsx">XLSX</option>
                  <option value="jpeg">JPEG</option>
                </Form.Control>
              </Form.Group>
            </Row>
            {checkedLayers.length === 0 && (
                <p className="m-2">
                  <br/>
                  Bitte aktiviere zuerst mindestens ein Datenlayer, um die Daten zu exportieren! <br/>
                  Sollten Layer aktiv sein und keine Daten zum Export bereit stehen, wähle ein Kartenauschnitt,
                  in dem Daten vorhanden sind!
                </p>
            )}
            {checkedLayers.length > 0 && (
                <>
                  <p className="m-2">
                    <br/>
                    Zum Export ausgewählte und mit Daten verfügbare Layer:
                  </p>
                  {checkedLayers.map(({key}) => (
                      <Row className="w-100 m-2">
                        <Col className="d-flex justify-content-center">
                          <p>{translations[key]}</p>
                        </Col>
                      </Row>))}
                </>)}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="w-100">
            <Col lg={6} className="d-flex justify-content-center">
              <Button className="filterSegBtn" onClick={() => setExportModalOpen(false)}>
                Abbrechen
              </Button>
            </Col>
            <Col lg={6} className="d-flex justify-content-center">
              <Button className="filterSegBtn" onClick={() => { handleExport(choice); setExportModalOpen(false); }}>
                Exportieren
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportLayerData;